import { BaseGetConfigsRepository } from 'domain/BaseRepository'
import { ProductWeb } from '../Model/ProductWeb'
export class ProductWebService {
  constructor(private configData: BaseGetConfigsRepository<ProductWeb>) {}
  /**
   * Get all available from data config
   *
   * @returns {ProductWeb[]}
   */
  getAllFromConfigData(): ProductWeb[] {
    return this.configData.getAll()
  }
}
