import { Config } from 'application'
import { BaseGetConfigsRepository } from 'domain/BaseRepository'
import { ProductWeb } from '../Model/ProductWeb'
export class ProductWebRepository
  implements BaseGetConfigsRepository<ProductWeb>
{
  getAll(): ProductWeb[] {
    return Config.productLinks
  }
}
