import { CityParserFromLocal, StateParser } from 'domain/Location'
import { CookieSearchParser } from 'domain/Search/Repositories/CookieSearchParser'
import { SearchItemRepository } from 'domain/Search/Repositories/SearchItemRepository'
import { SearchService } from 'domain/Search/Services/SearchService'
import { axiosClient } from '../Adapters/AxiosClient'
import { cookieClient } from '../Adapters/CookieClient'
import { cookieService } from './CookieService'

const stateParser = new StateParser()

const cityParser = new CityParserFromLocal(stateParser)

const cookieParser = new CookieSearchParser(cityParser)

const searchItemRepository = new SearchItemRepository(
  axiosClient,
  cookieClient,
  cookieParser
)

export const searchService = new SearchService(
  cookieService,
  searchItemRepository,
  cookieClient
)
