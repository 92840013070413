import styled, { css } from 'styled-components'

export const Card = styled.a`
  width: 145px;
  height: 145px;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: #fff;
  border: 2px solid #d0cfda;

  @media (max-width: 899px) {
    width: 100%;
    height: 100%;
  }
`

type ImageProps = {
  showShimmer: boolean
}

const imageWrapperModifier = {
  shimmer: () => css`
    background-image: linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
    );

    background-size: 100% 100%;
  `,
  backgroundDefault: () => css`
    background-image: none;
  `
}

export const ImageBox = styled.div<ImageProps>`
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: 10s linear 0s infinite normal forwards running placeholderShimmer;

  ${({ showShimmer }) =>
    `${
      showShimmer
        ? imageWrapperModifier.shimmer()
        : imageWrapperModifier.backgroundDefault()
    }`}

  @keyframes placeholderShimmer {
    0% {
      background-position: -40rem 0;
    }

    100% {
      background-position: 40rem 0;
    }
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 80px;
  max-height: 80px;
`
