import {
  Container as DefaultContainer,
  Skeleton as DefaultSkeleton,
  Typography as DefaultTypography
} from 'presentation/components'
import styled from 'styled-components'

export const Skeleton = styled(DefaultSkeleton)`
  border-radius: 8px;
`

export const Typography = styled(DefaultTypography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-left: ${({ theme }) => theme.spacing(3)};
  }
`

export const Container = styled(DefaultContainer)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0;
  }
`
