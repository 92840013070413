import { QueryParams } from 'domain/BaseRepository'
import { CacheClient } from 'domain/CacheClient'
import sumBy from 'lodash/sumBy'
import { ISearchItemRepository } from '../Contracts/ISearchItemRepository'
import { SearchItem } from '../Models/SearchItem'
import { SearchService } from './SearchService'

export class AutoCompleteService {
  /**
   * In memory cache
   *
   */
  inMemoryCache: { [x: string]: SearchItem[] } = {}

  /**
   * ItemService constructor
   *
   * @param repository
   */
  constructor(
    private searchService: SearchService,
    private repository: ISearchItemRepository,
    private cache: CacheClient
  ) {}

  /**
   * Get total offers
   *
   * @param queryParams
   * @returns
   */
  async getTotalOffers(queryParams: QueryParams): Promise<number> {
    const items = await this.repository.getAllWithCount(queryParams)

    return items
  }

  async getTotalOffersByCity(
    queryParams: QueryParams,
    customParam: string
  ): Promise<number> {
    const items = await this.repository.getAllWithCityCount(
      queryParams,
      customParam
    )

    return sumBy(items, (item) => item.count)
  }

  /**
   * Get autocomplete items
   *
   * @returns
   */
  async getItems(): Promise<SearchItem[]> {
    const path = this.searchService.getSearchSegment()
    return (
      await this.cache.remember(`Autocomplete_${path}`, 30, () => {
        return this.repository.getAll({ path })
      })
    ).map((item) => new SearchItem(item))
  }

  /**
   * Clear all filter cache
   *
   */
  clearFilterCache() {
    this.inMemoryCache = {}
  }

  /**
   * Filter items from given array
   *
   * @param query
   * @param items
   * @returns
   */
  async filterItems(
    query: string,
    factory: () => Promise<SearchItem[]>
  ): Promise<SearchItem[]> {
    const cachedItems = this.inMemoryCache?.[query]
    if (cachedItems) {
      return cachedItems
    }

    const items = await factory()

    const foundItems = items.filter((item) =>
      item.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())
    )
    this.inMemoryCache[query] = [...foundItems]

    return foundItems
  }
}
