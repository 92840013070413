import slugify from 'slugify'

export class StringFormatService {
  /**
   * Converts to slug
   *
   */
  toSlug(input: string, opts = {}): string {
    return slugify(input, opts)
  }

  /**
   * Get abbr from string
   *
   * @param input
   * @returns
   */
  static getAbbr(input: string): string {
    const start = input.indexOf('(')

    const end = input.indexOf(')')

    if (start === undefined || end === undefined) {
      return input
    }

    return input.substring(start + 1, end)
  }
}
