import { CityParserFromLocal, StateParser } from 'domain/Location'
import { CookieSearchParser } from 'domain/Search/Repositories/CookieSearchParser'
import { SearchItemRepository } from 'domain/Search/Repositories/SearchItemRepository'
import { AutoCompleteService } from 'domain/Search/Services/AutoCompleteService'
import { axiosClient } from '../Adapters/AxiosClient'
import { cookieClient } from '../Adapters/CookieClient'
import { localCacheClient } from '../Adapters/LocalCacheClient'
import { searchService } from './SearchService'

const stateParser = new StateParser()

const cityParser = new CityParserFromLocal(stateParser)

const cookieParser = new CookieSearchParser(cityParser)

const searchItemRepository = new SearchItemRepository(
  axiosClient,
  cookieClient,
  cookieParser
)

export const autoCompleteService = new AutoCompleteService(
  searchService,
  searchItemRepository,
  localCacheClient
)
