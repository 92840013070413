import styled from 'styled-components'

export const Card = styled.a<any>`
  height: 216px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: 10px 20px;
  position: relative;
  overflow: hidden;
  display: block;

  &:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(
      72.55% 72.54% at 27.79% 100%,
      rgba(46, 45, 55, 0.8) 0%,
      rgba(46, 45, 55, 0) 100%
    );
  }

  .cover-image {
    position: absolute;
    height: 216px;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  }
`

export const TitleCategoryCard = styled.h3`
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2.2rem;
  word-break: break-word;
  white-space: break-spaces;
  bottom: 20px;
  width: 80%;
  position: absolute;
  z-index: 9;
  max-width: 72%;
`
