export function formatString(str: string | undefined | null) {
  if (!str) {
    return undefined
  }

  return str
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '-')
}
