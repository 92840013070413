import LibraryService, { getFairs } from '@webmotors/wm-portal-lib-api-fairs'
import {
  isBlackFair,
  isMegaFair
} from '@webmotors/wm-portal-lib-api-fairs/lib/helpers'
import { Config } from 'application'
import { loggerClient } from 'application/Services'
import { isClientSide } from 'presentation/hooks/use-is-client-side'
import Fair from '../Model/Fair'

const logger = loggerClient.create('FairsService')
export class FairsService {
  /**
   * Get All Fairs informations
   *
   * @returns {Promise<any>}
   */
  async getAll(): Promise<Fair[]> {
    this.setBaseUrlConfiguration()
    let fairs
    try {
      fairs = await getFairs({ type: 'all' })
    } catch (e) {
      fairs = []
    }
    logger.info('[getAll] returns all active Fairs ', fairs)
    return fairs
  }

  /**
   * Sets the dafault api url to search for Fairs
   *
   * @returns {void}
   */
  setBaseUrlConfiguration(): void {
    LibraryService.setConfigurationLib({
      baseURLFairs: `${Config.baseUrl}${Config.apiPrefix}`
    })
  }

  /**
   * Checks if a Black Fair is active
   *
   * @returns {Promise<boolean>}
   */
  async hasBlackFairActive(): Promise<boolean> {
    const fairs: any = await this.getAll()

    let checkBlackFairDate = true

    if (isClientSide() && typeof window.FairsDateChecker !== 'undefined') {
      checkBlackFairDate = window?.FairsDateChecker?.checkBlackFair()
    }

    return checkBlackFairDate && fairs?.some(isBlackFair)
  }

  /**
   * Checks if a Mega Fair is active
   *
   * @returns {Promise<boolean>}
   */
  async hasMegaFairActive(): Promise<boolean> {
    const fairs: any = await this.getAll()

    let checkMegaFairDate = true

    if (isClientSide() && typeof window.FairsDateChecker !== 'undefined') {
      checkMegaFairDate = window?.FairsDateChecker?.checkMegaFair()
    }

    return checkMegaFairDate && fairs?.some(isMegaFair)
  }

  /**
   * Checks if is allowed to use a Black Fair theme and if a Black Fair is active
   *
   * @returns {Promise<any>}
   */
  async canIUseBlackFairTheme(): Promise<boolean> {
    const hasBlackFairActive = await this.hasBlackFairActive()
    return hasBlackFairActive && Config.allowBlackFeiraoThemeActivationFromApi
  }
}
