import { StringFormatService } from 'application/Services/Format/StringFormatService'
import { BaseRepository } from 'domain/BaseRepository'
import { SearchService } from 'domain/Search/Services/SearchService'
import { News } from '../Model/News'

export class NewsService {
  constructor(
    private searchService: SearchService,
    private newsRepository: BaseRepository<News>,
    private stringFormatService: StringFormatService
  ) {}

  /**
   * Get all available categories
   *
   * @returns
   */
  async getAll(): Promise<News[]> {
    const { brand, model } = (await this.searchService.getLastSearch()) || {}

    const path: string[] = []
    const slugParams = {
      remove: '.',
      lower: true
    }

    if (brand) {
      path.push(this.stringFormatService.toSlug(brand, slugParams))
      if (model) path.push(this.stringFormatService.toSlug(model, slugParams))
    }

    return this.newsRepository.getAll({
      path: path.join('/'),
      querystring: {
        origin: 'portal'
      }
    })
  }
}
