import window from 'global/window'
import { useLocation, useMobile } from 'presentation/hooks'
import { useCallback, useEffect, useState } from 'react'
import { googleTagSettings } from './MediaBanner.config'

export function useMediaBannerController({ setSlides }) {
  const [pubadsReady, setPubadsReady] = useState(false)
  const [slotsLoaded, setSlotsLoaded] = useState(false)
  const [slots, setSlots] = useState<googletag.Slot[]>([])
  const { city } = useLocation()
  const {
    totalSlots,
    networkCode,
    unitCode,
    dimensions,
    idHtml,
    key,
    desktopSize,
    mobileSize
  } = googleTagSettings()
  const isMobile = useMobile()
  const { googletag: gpt } = window

  const handleSlotName = useCallback(
    (slotsArray) => {
      return Array.from(
        Array(slotsArray),
        (_, index) => `${idHtml}${index + 1}`
      )
    },
    [idHtml]
  )

  useEffect(() => {
    if (!pubadsReady || !slotsLoaded) return
    function refreshSlot() {
      gpt.pubads().refresh(slots)
    }
    gpt.cmd.push(refreshSlot)
  }, [isMobile, gpt, pubadsReady, slots, slotsLoaded])

  useEffect(() => {
    if (!city) return
    const setCity = () => {
      gpt.pubads().setTargeting('uf', city.state.abbr)
      gpt.pubads().setTargeting('city', city.toSlug('_'))
    }

    gpt.cmd.push(setCity)
  }, [city, gpt])

  useEffect(() => {
    if (slotsLoaded) return
    function handleBanners() {
      const items = handleSlotName(totalSlots)
      const sizeMapping = googletag
        .sizeMapping()
        .addSize([900, 0], desktopSize)
        .addSize([320, 0], mobileSize)
        .build()

      items.forEach((item, index) => {
        const slot = gpt.defineSlot(
          `${networkCode}/${unitCode}`,
          dimensions,
          item
        )

        if (slot) {
          slot
            .setTargeting(key, `${index + 1}`)
            .defineSizeMapping(sizeMapping)
            .addService(gpt.pubads())

          setSlots((prevSlots) => [...prevSlots, slot])
        }
      })

      gpt.pubads().addEventListener('slotResponseReceived', (event) => {
        const { slot } = event

        if (!slot.getResponseInformation()) {
          setSlides((slide: string[]) => [
            ...slide.filter((s) => s !== slot.getSlotElementId())
          ])
        }

        if (slot.getSlotElementId() === `${idHtml}${items.length}`) {
          setSlotsLoaded(true)
        }
      })

      gpt.pubads().enableSingleRequest()
      gpt.pubads().collapseEmptyDivs()
      gpt.pubads().disableInitialLoad()
      gpt.enableServices()
      setPubadsReady(true)
    }
    gpt.cmd.push(handleBanners)
  }, [
    desktopSize,
    networkCode,
    dimensions,
    gpt,
    handleSlotName,
    idHtml,
    key,
    mobileSize,
    setSlides,
    unitCode,
    slotsLoaded,
    totalSlots
  ])

  useEffect(() => {
    function refreshAllSlots() {
      gpt.pubads().refresh()
    }
    if (gpt.cmd && gpt.pubadsReady && pubadsReady) {
      gpt.cmd.push(refreshAllSlots)
    }
  }, [gpt, pubadsReady])

  return {
    totalSlots,
    idHtml,
    handleSlotName
  }
}
