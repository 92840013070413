import { Typography as DSTypography } from 'presentation/components'
import styled from 'styled-components'

export const Typography = styled(DSTypography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 32px 24px 24px;
  gap: 8px;
  position: absolute;
  width: 334px;
  height: 366px;
  left: calc(50% - 334px / 2);
  top: calc(50% - 366px / 2);
  background: #ffffff;
  box-shadow: 0px 16px 20px rgba(46, 45, 55, 0.12);
  border-radius: 8px;
  outline: none;

  .close_modal_login {
    display: inline-block;
    vertical-align: middle;
    margin: 0px 0.15em;
    font-size: 20px;
    width: 24px;
    height: 24px;
  }

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    width: 286px;
    height: 96px;

    li {
      display: flex;
      align-items: center;

      svg {
        path {
          fill: #27ae60;
        }
      }

      span {
        width: 254px;
        height: 18px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #2e2d37;
        margin-left: 9px;
      }
    }
  }

  h4 {
    width: 286px;
    height: 60px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #2e2d37;
  }

  .sign_in {
    display: flex;
    width: 100%;
    margin-top: 10px;

    p {
      font-size: 12px;
      font-weight: 500;
    }

    a {
      color: red;
      font-weight: 700;
    }
  }
`

export const Button = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 20px;
  width: 286px;
  height: 56px;
  background: #f3123c;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  border-radius: 8px;
  margin-top: 14px;

  &:visited,
  &:hover,
  &:active {
    color: #ffffff;
  }
`
