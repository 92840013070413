import { loggerClient } from 'application/Services'
import { BaseRepository } from 'domain/BaseRepository'
import { HttpGetClient } from 'domain/HttpClient'
import { BestSeller } from '../Model/BestSeller'

const logger = loggerClient.create('BestSellerRepository')
export class BestSellerRepository implements BaseRepository<BestSeller> {
  constructor(private httpClient: HttpGetClient) {}

  /**
   * Get all available best sellers
   *
   * @returns Promise<BestSeller[]
   */
  async getAll(queryParams): Promise<BestSeller[]> {
    const result = await this.httpClient.get<BestSeller[]>(
      `/bestseller/${queryParams.path}`
    )

    const mappedResults = result.map((item) => new BestSeller(item))

    logger.info('[getAll] mappedResults: ', mappedResults)

    return mappedResults
  }
}
