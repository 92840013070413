import { useContext, useEffect } from 'react'
import * as Styles from './GallerayItem.styles'
import { GalleryContext } from './Gallery.context'

export function GalleryItem({ children, ...props }) {
  const { setSlides } = useContext(GalleryContext)

  useEffect(() => {
    setSlides((slides) => [
      ...slides,
      {
        index: slides.length
      }
    ])
  }, [])

  return <Styles.Container {...props}>{children}</Styles.Container>
}
