import { locationService } from 'application'
import { City } from 'domain/Location'
import { useEffect } from 'react'
import { createGlobalState } from 'react-use'
import { useCookieConsents } from '../use-cookie-consents'

const useGlobalCity = createGlobalState<City | null>(null)

let fetching = false

export function useLocation() {
  const [city, setCity] = useGlobalCity()
  const cookieConsents = useCookieConsents()

  async function fetchCurrentCity() {
    if (fetching) {
      return
    }

    fetching = true
    const currentCity = await locationService.getCurrentCity()
    setCity(currentCity)
    fetching = false
  }

  useEffect(() => {
    fetchCurrentCity()
  }, [cookieConsents])

  return {
    city,
    setCity
  }
}
