import { useHomeDataLayer } from 'presentation/hooks/use-datalayer'
import { useInsiderTag } from 'presentation/hooks/use-insider'
import { useEffect } from 'react'
import { insiderEvent } from './HomeInsiderData'

export function useHomeController() {
  const { handleHomeDataLayer } = useHomeDataLayer('customPageView')
  const { handleInsiderEvent, insertInsiderTag } = useInsiderTag()

  useEffect(() => {
    handleHomeDataLayer()
    insertInsiderTag({
      callback: () => {
        handleInsiderEvent({ properties: insiderEvent })
      }
    })
  }, [])
}
