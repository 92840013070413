import { DataLayerHomeRepository } from 'domain/DataLayer/Repository/HomeDataLayerRepository'
import { DataLayerHomeService } from 'domain/DataLayer/Service/DataLayerHomeService'
import { cookieClient } from '../Adapters/CookieClient'
import { browserClient } from '../Adapters/EventClient'
import { globalClient } from '../Adapters/GlobalClient'
import { localCacheClient } from '../Adapters/LocalCacheClient'

export const dataLayerHomeService: DataLayerHomeService =
  new DataLayerHomeService(
    new DataLayerHomeRepository(browserClient, globalClient),
    cookieClient,
    localCacheClient
  )
