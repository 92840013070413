import {
  BaseFindOneRepository,
  BaseSaveRepository
} from 'domain/BaseRepository'
import { LocalClient } from 'domain/LocalClient'

import { CookieConfig } from '../Models/CookieConfig'

export class CookieService {
  constructor(
    private repository: BaseFindOneRepository<CookieConfig> &
      BaseSaveRepository<CookieConfig>,
    private cookieClient: LocalClient
  ) {}

  /**
   * Get cookie consents
   *
   * @returns
   */
  getCookieConsent() {
    return this.repository.findOne()
  }

  /**
   * Clear all cookies related to search
   *
   */
  clearSearchCookies() {
    const searchCookies = ['WMLastFilterSearch', 'WebMotorsLastSearches']

    searchCookies.forEach((cookieKey) =>
      this.cookieClient.removeItem(cookieKey)
    )
  }

  /**
   * Save cookie consents
   *
   * @param item
   * @returns
   */
  async saveCookieConsent(item: CookieConfig) {
    const config = await this.repository.save(item)

    if (!config.cookies.search) {
      this.clearSearchCookies()
    }

    document.dispatchEvent(
      new CustomEvent('@CookieService:saveCookieConsent', {
        detail: config
      })
    )

    return config
  }

  /**
   * Check if search is enable for the user
   *
   * @returns
   */
  async isSearchEnabled(): Promise<boolean> {
    const { cookies } = await this.getCookieConsent()
    return !!cookies?.search
  }
}
