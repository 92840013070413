import { COOKIE_NOTICE_TYPES } from 'constants/index'

export default [
  {
    title: 'Propostas',
    name: COOKIE_NOTICE_TYPES.PROPOSALS,
    text: 'São dados que coletamos conforme você envia propostas e mensagens e nos mostram, com a sua navegação, quais possíveis ofertas relacionadas poderemos apresentar.'
  },
  {
    title: 'Busca',
    name: COOKIE_NOTICE_TYPES.SEARCH,
    text: 'Esses Cookies guardam suas preferências na hora de buscar um carro ou uma moto aqui na Webmotors e salvam as suas preferências quando você volta por aqui.'
  },
  {
    title: 'Marketing',
    name: COOKIE_NOTICE_TYPES.MARKETING,
    text: 'Usamos dados de perfil e de navegação para mostrar publicidade que possa fazer sentido pra você. Eles podem (ou não!) ser compartilhados com parceiros comerciais e não revelam nenhum dado de pagamento ou de identificação.'
  },
  {
    title: 'Experiência',
    name: COOKIE_NOTICE_TYPES.EXPERIENCE,
    text: 'Coletamos seu nome, telefone e e-mail logo após responder nossa pesquisa de satisfação. Com esses dados podemos, se necessário, entrar em contato com você para entender como foi sua experiência usando nosso site.'
  }
]
