import {
  CategoryService,
  ConfigCategoryRepository,
  RemoteCategoryRepository
} from 'domain/Category'

import { axiosClient } from '../Adapters/AxiosClient'

export const categoryService: CategoryService = new CategoryService(
  new RemoteCategoryRepository(axiosClient),
  new ConfigCategoryRepository()
)
