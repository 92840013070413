import { blackFairCategoriesLinks } from '../Data/BlackFairCategories'
import { megaFairCategoriesLinks } from '../Data/MegaFairCategories'
import { productsLinks } from '../Data/ProductsWeb'

export class DefaultEnvironment {
  /**
   * Base url for offers photos
   *
   */
  baseImageUrl = 'https://image.webmotors.com.br/_fotos/AnuncioUsados/gigante/'

  /**
   * Prefix of default api
   *
   */
  apiPrefix = '/api'

  /**
   * Cookie notice name on staging environment
   *
   */
  cookieNoticeName = 'wmCookieNotice'

  /**
   * Cookie search segment key on default environment
   *
   */
  cookieSearchSegmentKey = 'WebMotorsVehicleType'

  /**
   * Logging status
   *
   */
  logging = false

  /**
   * ProductLinks config on default enviroment
   *
   */
  productLinks = productsLinks

  /**
   * BlackFairCategoriesLinks config on default enviroment
   *
   */
  blackFairCategoriesLinks = blackFairCategoriesLinks

  /**
   * BlackFairCategoriesLinks config on default enviroment
   *
   */
  megaFairCategoriesLinks = megaFairCategoriesLinks

  /**
   * Force BlackFair Theme activation
   *
   */
  blackFeiraoActive = false

  /**
   * Allows BlackFair infos from API to decide
   * if BlackFairTheme will be displayed
   */
  allowBlackFeiraoThemeActivationFromApi = true

  /**
   * Show variables from core web vitals
   */
  showCoreWebVitalsConsole = true

  /**
   * Indicates if application is running on server side
   *
   */
  isServerSide = false

  /**
   * Cookie secret
   *
   */
  cookieEncryptionSecret = 'twY2NMFSKMskM8NteG9bwBkx'

  /**
   * Cookie data form key
   *
   */
  cookieDataForm = 'WebMotorsDataForm'

  /**
   * Cookie location info
   *
   */
  cookieLocationInfo = 'WebMotorsLocation'

  /**
   * Modal login key
   *
   */
  hideModalLoginStorageKey = 'hideModalLogin'

  /**
   * Modal login key
   *
   */
  hideModalToAppStorageKey = 'hideModalToAPP'

  /**
   * Login notification key
   *
   */
  hideLoginNotificationStorageKey = 'hideLoginNotification'
}
