import { useQuery } from '@tanstack/react-query'
import { searchService } from 'application'
import { useCookieConsents } from 'presentation/hooks'
import { queryClient } from 'presentation/utils'
import { useEffect } from 'react'
import * as Styles from './LastSearch.styles'
import { useLastSearchController } from './LastSearchController'

export function LastSearch() {
  const lastSearchController = useLastSearchController()
  const cookieConsent = useCookieConsents()
  const { data } = useQuery({
    queryKey: ['last_search'],
    queryFn: () => {
      return searchService.getLastSearches()
    }
  })

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['last_search']
    })
  }, [cookieConsent])

  if (!data || data?.length === 0) {
    return null
  }

  return (
    <Styles.Container>
      <Styles.Title data-testid="LastSearchTitle">
        Suas últimas buscas
      </Styles.Title>
      <Styles.CardRow>
        {data.map(
          (item, index) =>
            item.location &&
            item.brand &&
            item.model && (
              <Styles.Card
                href={lastSearchController.mountLinkModel(item, index)}
                data-qa={`last_search_card_${index + 1}`}
                data-testid="LastSearchCard"
                key={item.id}
              >
                <Styles.CardHeader>
                  {item.brand} <b>{item.model}</b>
                </Styles.CardHeader>
                <Styles.CardBody>
                  <Styles.Label>{item.location}</Styles.Label>
                </Styles.CardBody>
              </Styles.Card>
            )
        )}
      </Styles.CardRow>
    </Styles.Container>
  )
}
