import { Environment } from '../Environment'
import { DefaultEnvironment } from './DefaultEnvironment'

export class BlueEnvironment extends DefaultEnvironment implements Environment {
  /**
   * BaseUrl of blue requests
   *
   */
  baseUrl = 'https://azulportal.webmotors.com.br'

  /**
   * PublicUrl of staging requests
   *
   */
  publicUrl = 'https://azulportal.webmotors.com.br/novo-portal/static'

  /**
   * BaseUrl used on server side rendering
   *
   */
  baseServerUrl = 'https://yur5i9svf7.execute-api.us-east-1.amazonaws.com/azl'

  /**
   * Auth Cookie Name on blue environment
   *
   */
  authCookieName = 'webmotors.jwt.token'

  /**
   * Cookie domain on blue environment
   *
   */
  cookieDomain = '.azulportal.webmotors.com.br'

  /**
   * Login route
   *
   */
  loginRoute = 'https://azulportal.webmotors.com.br/login'

  /**
   * Logging status
   *
   */
  logging = true

  /**
   * Search
   *
   */
  abTest = {
    searchHome: {
      key: 'azl_ab_test_search'
    }
  }
}
