import { Faker } from 'faker'

import { User } from 'domain/User/Model/User'
import { FakableClass, FakableField, fakeClass } from 'fake-class'

export enum VehicleType {
  CAR = 1,
  BIKE = 2
}

@FakableClass()
export class Favorite {
  /**
   * Favorite id
   *
   */
  @FakableField(() => Faker.uuid())
  id?: string

  /**
   * Favorite advertiseId
   *
   */
  @FakableField(() => Faker.number())
  advertiseId: number

  /**
   * Favorite site
   *
   */
  site = true

  /**
   * Favorite user
   *
   */
  @FakableField(() => fakeClass(User))
  user: User

  /**
   * Favorite vehicleType
   *
   */
  @FakableField(() => VehicleType.CAR)
  vehicleType: VehicleType

  /**
   * Favorite constructor
   *
   * @param params
   */
  constructor(params: Partial<Favorite>) {
    Object.assign(this, params)
  }
}
