import { useEffect, useState } from 'react'
import * as S from './MediaBanner.styles'
import { useMediaBannerController } from './MediaBannerController'

export function MediaBanner() {
  const [slides, setSlides] = useState<string[]>([])
  const { handleSlotName, totalSlots } = useMediaBannerController({ setSlides })

  useEffect(() => {
    setSlides(handleSlotName(totalSlots))
  }, [handleSlotName, totalSlots])

  return <S.Carousel slides={slides} />
}
