import { FakableClass, FakableField } from 'fake-class'
import { Faker } from 'faker'

/**
 * Principal class
 * Stores the user's contact information and opt-in preferences.
 */
@FakableClass()
export class Principal {
  /**
   * Full name
   * Stores the user's full name.
   */
  @FakableField(() => Faker.string(10))
  fullName: string

  /**
   * Email
   * Stores the user's email address.
   */
  @FakableField(() => Faker.string(10))
  email: string

  /**
   * Phone number
   * Stores the user's phone number.
   */
  @FakableField(() => Faker.string(10))
  numberPhone: string

  /**
   * Message
   * Stores the message typed by the user when submitting a lead.
   */
  @FakableField(() => Faker.string(30))
  message: string

  /**
   * Newsletter opt-in
   * Stores the user's opt-in preference for newsletter.
   */
  @FakableField(() => false)
  newsletter: boolean

  /**
   * Constructor
   * Initializes a new instance of the Principal class.
   *
   * @param {Partial<Principal>} params - The parameters to initialize the class with.
   */
  constructor(params: Partial<Principal>) {
    Object.assign(this, params)
  }
}
