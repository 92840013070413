import { Faker } from 'faker'

import { FakableClass, FakableField } from 'fake-class'

@FakableClass()
export class News {
  /**
   * News title
   *
   */
  @FakableField(() => Faker.string(10))
  title!: string

  /**
   * News category
   *
   */
  @FakableField(() => Faker.string(10))
  category!: string

  /**
   * News author
   *
   */
  @FakableField(() => Faker.string(10))
  author!: string

  /**
   * News url
   *
   */
  @FakableField(() => Faker.url())
  url!: string

  /**
   * News urlPrincipalImage
   *
   */
  @FakableField(() => Faker.url())
  urlImage!: string

  /**
   * News authorAvatar
   *
   */
  @FakableField(() => Faker.url())
  authorAvatar!: string

  /**
   * News constructor
   *
   * @param params
   */
  constructor(params: Partial<News>) {
    Object.assign(this, params)
  }
}

export default News
