import { MediaBannerCarousel } from 'presentation/components/generics/MediaBannerCarousel/MediaBannerCarousel'
import styled from 'styled-components'

export const Carousel = styled(MediaBannerCarousel)`
  margin-bottom: -40px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-bottom: 12px;
  }
`
