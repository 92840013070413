import { BaseFindOneRepository, QueryParams } from 'domain/BaseRepository'
import { HttpGetClient } from 'domain/HttpClient'
import { LocalClient } from 'domain/LocalClient'
import { City } from 'domain/Location/Model/City'
import {
  CityFromRemoteResponse,
  CityInputFromLocal,
  CityInputFromRemote,
  Coords,
  ICityParser
} from '../Contracts/ICityParser'

export class CityRepository implements BaseFindOneRepository<City | null> {
  constructor(
    private httpGetClient: HttpGetClient,
    private localClient: LocalClient,
    private cityParserFromRemote: ICityParser<CityInputFromRemote>,
    private cityParserFromLocal: ICityParser<CityInputFromLocal>
  ) {}

  /**
   * Find city from given coords
   *
   * @param coords
   * @returns
   */
  async findByCoords(coords: Coords): Promise<City | null> {
    try {
      const result = await this.httpGetClient.get<CityFromRemoteResponse>(
        '/location',
        {
          queryParams: {
            lat: coords.latitude,
            long: coords.longitude
          }
        }
      )
      if (!result) {
        return null
      }

      return this.cityParserFromRemote.parse({
        data: result,
        coords
      })
    } catch {
      return null
    }
  }

  /**
   * Find city from local data
   *
   * @returns
   */
  async findFromLocal(): Promise<City | null> {
    const cityRawData = this.localClient.getItem<CityInputFromLocal | null>(
      'WMLastFilterSearch'
    )
    if (!cityRawData || cityRawData?.estadocidade === 'estoque') {
      return null
    }
    return this.cityParserFromLocal.parse(cityRawData)
  }

  /**
   * Return state and city information according to user latitude and longitude
   *
   * @returns
   */

  async findOne(queryParams?: QueryParams): Promise<City | null> {
    if (queryParams?.querystring?.coords) {
      return await this.findByCoords(queryParams?.querystring?.coords)
    }

    return await this.findFromLocal()
  }
}
