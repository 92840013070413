import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { GalleryContext } from './Gallery.context'
import * as Styles from './Gallery.styles'
import { useGalleryController } from './GalleryController'

export function Gallery({ height = '172px', children }) {
  const galleryController = useGalleryController()

  return (
    <GalleryContext.Provider value={galleryController.contextValues}>
      <Styles.Container height={height}>
        <Styles.Control
          onClick={galleryController.handlePrevious}
          data-testid="Gallery_Control_Left"
          className="left-control"
        >
          <ArrowBackIosIcon sx={{ color: '#fff' }} />
        </Styles.Control>
        <Styles.Inner ref={galleryController.containerRef}>
          {children}
        </Styles.Inner>
        <Styles.Indicators>
          {galleryController.slides.map((s) => (
            <Styles.Indicator
              onClick={() => galleryController.setActivedIndex(s.index)}
              className={
                galleryController.activedIndex === s.index
                  ? 'actived'
                  : 'not-actived'
              }
              key={s.index}
            />
          ))}
        </Styles.Indicators>
        <Styles.Control
          onClick={galleryController.handleNext}
          data-testid="Gallery_Control_Right"
          className="right-control"
        >
          <ArrowForwardIosIcon sx={{ color: '#fff' }} />
        </Styles.Control>
      </Styles.Container>
    </GalleryContext.Provider>
  )
}
