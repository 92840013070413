import { loggerClient } from 'application/Services'
import { BaseRepository } from 'domain/BaseRepository'
import { BestSeller } from '../Model/BestSeller'

const logger = loggerClient.create('BestSellerService')

export class BestSellerService {
  constructor(private repository: BaseRepository<BestSeller>) {}

  /**
   * Get all available best sellers
   *
   * @returns
   */
  async getAll(abbr?: string): Promise<BestSeller[]> {
    const bestSellers = await this.repository.getAll({ path: abbr || 'br' })
    logger.info('[getAll] bestSellers: ', bestSellers)
    return bestSellers
  }
}
