import { Config } from 'application'
import { uriService } from 'application/Services'
import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import { BaseSaveRepository } from 'domain/BaseRepository'
import { CacheClient } from 'domain/CacheClient'
import { LocalClient } from 'domain/LocalClient'
import window from 'global/window'
import { cleanString } from 'presentation/utils'
import { Page, Site, TrackingCode, User } from '../Model'
import { HomeDataLayer } from '../Model/HomeDataLayer'
import { BrandOrigin, SitePlatform } from '../Model/Site'
import { LoginStatus, Pii } from '../Model/User'

export type HomeDataLayerDto = {
  eventType: string
  site: {
    platform: SitePlatform
  }
  page: {
    url: string
  }
  user: {
    loginStatus: LoginStatus
    pii?: Pii
  }
}

type ParamsQueryString = {
  origem: BrandOrigin
  idcmp: string
  idcmpint: string
  icid: string
  lkid: string
}

type LocationData = {
  state: string | undefined
  city: string | undefined
}

type FormLeadData = {
  email: string | undefined
  name: string | undefined
  phoneNumber: string | undefined
}

export class DataLayerHomeService {
  constructor(
    private repository: BaseSaveRepository<HomeDataLayer>,
    private cookieClient: LocalClient,
    private cache: CacheClient
  ) {}

  /**
   * Generic method to load data from localStorage
   */
  loadFromStorage<T>(storageKey: string, defaultValue: T): T {
    if (typeof window !== 'undefined') {
      const storedData = localStorage.getItem(storageKey)
      return storedData ? (JSON.parse(storedData) as T) : defaultValue
    }
    return defaultValue
  }

  /**
   * update Pii location data in objDataLayer
   */
  updateLocationDataLayer(locationData: LocationData) {
    if (window === undefined) return

    const optin = window?.objDataLayer?.user?.pii?.location?.optin

    if (!optin || optin.state || optin.city) return

    optin.state = locationData?.state
    optin.city = locationData?.city

    this.saveToStorage('userPiiLocation', {
      state: locationData?.state,
      city: locationData?.city
    })
  }

  /**
   * Save data to localStorage
   */
  saveToStorage<T>(storageKey: string, data: T): void {
    if (typeof window !== 'undefined') {
      localStorage.setItem(storageKey, JSON.stringify(data))
    }
  }

  /**
   * Get data form cookie
   * Gets the data form cookie from storage.
   *
   * @returns {LocationData} - Object to be used in the pii.lead
   */
  getDataLocation(): LocationData {
    const storageKey = 'userPiiLocation'

    let locationData = this.loadFromStorage<LocationData>(storageKey, {
      state: undefined,
      city: undefined
    })

    if (locationData.state || locationData.city) {
      return locationData
    }

    const geolocationCookie: any =
      this.cookieClient.getItem<string>(Config.cookieLocationInfo) ?? ''
    const clean = (str: string | undefined) =>
      cleanString(str ?? '', {
        toLowerCase: true,
        removeAccents: true,
        slugify: true
      })
    const cleanOrUndefined = (value: string | undefined) =>
      value !== undefined && value !== null ? clean(value) : undefined

    if (
      geolocationCookie?.estadocidade &&
      typeof geolocationCookie.estadocidade === 'string'
    ) {
      const [rawState, rawCity] = geolocationCookie.estadocidade.split(' - ')

      locationData = {
        state: cleanOrUndefined(rawState),
        city: cleanOrUndefined(rawCity)
      }

      this.saveToStorage(storageKey, locationData)
    } else {
      const locationInfo = this.cache.get<any>('userCurrentLocation')

      locationData = {
        state: cleanOrUndefined(locationInfo?.coords?.state),
        city: cleanOrUndefined(locationInfo?.coords?.city)
      }
    }
    return locationData
  }

  /**
   * Get data from cookie or localstorate
   *
   * @returns {FormLeadData} - Object to be used in the pii.location.optin
   */

  getDataFormLead(): FormLeadData {
    const storageKey = 'userPiiLead'

    let formLeadData = this.loadFromStorage<FormLeadData>(storageKey, {
      email: undefined,
      name: undefined,
      phoneNumber: undefined
    })

    if (formLeadData.email || formLeadData.name || formLeadData.phoneNumber) {
      return formLeadData
    }

    const cookieOriginalContent =
      this.cookieClient.getItem<string>(Config.cookieDataForm) ?? undefined
    const cleanPhone = (phone: string) =>
      phone.replace(/\D/g, '').replace(/^(\d{2})(\d+)$/, '0$1-$2')

    if (cookieOriginalContent) {
      try {
        const decryptedBrute = AES.decrypt(
          cookieOriginalContent,
          Config.cookieEncryptionSecret
        )
        const decryptedText = decryptedBrute.toString(Utf8)
        const parsedContent = JSON.parse(decryptedText)

        formLeadData = {
          email: parsedContent?.Principal?.email?.toLowerCase(),
          name: parsedContent?.Principal?.fullName?.toLowerCase(),
          phoneNumber:
            parsedContent?.Principal?.numberPhone &&
            cleanPhone(parsedContent.Principal.numberPhone)
        }

        return formLeadData
      } catch (e) {
        return {
          email: undefined,
          name: undefined,
          phoneNumber: undefined
        }
      }
    }
    return {
      email: undefined,
      name: undefined,
      phoneNumber: undefined
    }
  }

  sendDataLayer(item: HomeDataLayerDto) {
    const { eventType, site, page, user } = item
    const { platform } = site
    const { url } = page
    const { loginStatus } = user

    const pii = {
      lead: {
        ...this.getDataFormLead()
      },
      location: {
        optin: {
          ...this.getDataLocation()
        }
      }
    }

    const params = url.split('?')[1]
    const querStringParams =
      uriService.queryStringToObject<ParamsQueryString>(params)

    const objDataLayer = new HomeDataLayer({
      eventType,
      site: new Site({
        platform,
        brandOrigin: querStringParams?.origem || BrandOrigin.WEBMOTORS
      }),
      page: new Page({
        url,
        trackingCode: new TrackingCode({
          idcmp: querStringParams?.idcmp,
          idcmpint: querStringParams?.idcmpint,
          icid: querStringParams?.icid,
          lkid: querStringParams?.lkid
        })
      }),
      user: new User({
        loginStatus,
        pii
      })
    })

    this.repository.save(objDataLayer)
  }
}
