import { useEffect, useState } from 'react'

import { officialStoreService } from 'application'
import { OfficialStore } from 'domain/OfficialStore'

export function useOfficialStoresController() {
  const [customOfficialStores, setCustomOfficialStores] =
    useState<OfficialStore>({ position: '', stores: [] })

  useEffect(() => {
    const getCustomOfficialStores = async () => {
      setCustomOfficialStores(await officialStoreService.getFromRemote())
    }

    getCustomOfficialStores()
  }, [])

  return { customOfficialStores }
}
