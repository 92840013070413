import { useMediaQuery, useTheme } from '@mui/material'
import { productWebService } from 'application'
import { Box, ScrollItem, ScrollView } from 'presentation/components'
import { useMemo } from 'react'
import { ProductWebCard } from '../../Cards'
import * as S from './ProductsWeb.styles'

export function ProductsWeb() {
  const data = productWebService.getAllFromConfigData()
  const theme = useTheme()
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('lg'))

  const perPage = useMemo(() => {
    if (isExtraLarge) {
      return 6
    }
    return 5
  }, [isExtraLarge])

  return (
    <S.Container>
      <Box mt={4} mb={4}>
        <S.Typography variant="h2" color="textSecondary" mb={2}>
          Na Webmotors
        </S.Typography>
        {data && data?.length > 0 && (
          <ScrollView perPage={perPage}>
            {data?.map((product) => (
              <ScrollItem key={product.id} size="200px">
                <ProductWebCard
                  tag={product.tagType}
                  title={product.title}
                  url={product.url}
                  id={product.id}
                  data_qa={product.data_qa}
                  attributes={product.attributes}
                />
              </ScrollItem>
            ))}
          </ScrollView>
        )}
      </Box>
    </S.Container>
  )
}
