import useMediaQuery from '@mui/material/useMediaQuery'
import { useEffect, useRef } from 'react'
import { LIMIAR_TO_DESKTOP_WIDTH } from '../constants'

export function checkIsDesktopDevice() {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isDesktopDevice = useMediaQuery(
    `(min-width:${LIMIAR_TO_DESKTOP_WIDTH}px`
  )
  return isDesktopDevice
}

export function createLkidUrl(
  path: string,
  lkid: string,
  redirectTo?: string,
  inst?: string
) {
  const splitedUrl = path.split('/')
  const checkQuestionMark = splitedUrl[splitedUrl.length - 1].includes('?')
  const marker = checkQuestionMark ? '&' : '?'
  const redirect = redirectTo ? `&r=${redirectTo}` : ''
  const lkidQueryString = lkid ? `lkid=${lkid}` : ''
  const instQueryString = inst ? `&inst=${inst}` : ''
  return `${path}${marker}${lkidQueryString}${instQueryString}${redirect}`
}

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
