import styled from 'styled-components'

export const Container = styled.div`
  background-color: rgba(46, 46, 55, 0.698);
  justify-content: center;
  align-items: center;
  position: fixed;
  display: flex;
  z-index: 99;
  inset: 0px;
`

export const Card = styled.div`
  grid-template-rows: 68px auto auto 40px;
  position: relative;
  border-radius: 8px;
  background: #fff;
  display: grid;
  padding: 32px;
  height: 400px;
  width: 460px;
`

export const CloseButton = styled.button`
  position: absolute;
  right: 32px;
  top: 32px;
`

export const Title = styled.h2`
  color: rgb(46, 46, 55);
  font-weight: 700;
  font-size: 32px;
`

export const Content = styled.p`
  color: rgb(46, 46, 55);
  line-height: 1.5rem;
  font-size: 16px;
`

export const LoginButton = styled.a`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  justify-content: center;
  letter-spacing: unset;
  text-transform: none;
  align-items: center;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  padding: 0 8px;
  display: flex;
  width: 130px;
  height: 40px;

  &:hover {
    background: #c80a2e;
    cursor: pointer;
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`

export const Icon = styled.div``

export const Section = styled.div``
