export class Platform {
  /**
   * Check if is running on ios device
   *
   * @returns
   */
  iOS(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.platform)
  }
}
