import { Faker } from 'faker'

import { FakableClass, FakableField } from 'fake-class'
import { Page } from './Page'
import { Site } from './Site'
import { User } from './User'

@FakableClass()
export class HomeDataLayer {
  /**
   * HomeDataLayer Site
   */
  site = new Site({})

  /**
   * HomeDataLayer Page
   */
  page = new Page({})

  /**
   * HomeDataLayer User
   */
  user = new User({})

  /**
   * HomeDataLayer eventType
   */
  @FakableField(() => Faker.string(10))
  eventType!: string

  /**
   * HomeDataLayer constructor
   *
   * @param params
   */
  constructor(params: Partial<HomeDataLayer>) {
    Object.assign(this, params)
  }
}
