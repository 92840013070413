import { Props } from 'presentation/components'
import { useIsInViewport } from 'presentation/hooks/use-is-in-viewport'
import { createContext, useMemo, useRef, useState } from 'react'
import * as S from './ViewPortControl.styles'

export const ViewPortContext = createContext<any>({})

export function ViewPortControl({ children, minHeight }: Props) {
  const elementReference = useRef(null)
  const isInViewport = useIsInViewport(elementReference)
  const [show, setShow] = useState<boolean>(true)

  const showContent = useMemo(() => ({ show, setShow }), [show, setShow])

  return (
    <ViewPortContext.Provider value={showContent}>
      <S.Container
        className={show ? 'visible' : 'hidden'}
        data-testid="ViewPortControl"
        ref={elementReference}
        {...{
          minHeight
        }}
      >
        {isInViewport ? children : null}
      </S.Container>
    </ViewPortContext.Provider>
  )
}
