import { Switch } from 'presentation/components'
import img from 'presentation/assets/img/check.svg'
import styled from 'styled-components'

export const Switches = styled.div``

export const SwitchBox = styled.div`
  display: flex;
  align-items: center;

  & + div {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  & svg {
    margin: 0px ${({ theme }) => theme.spacing(1.25)};
  }
`

export const Info = styled.div`
  padding: 0 ${({ theme }) => theme.spacing(2.5)};
  width: 95%;
`

export const Title = styled.h4`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: ${({ theme }) => theme.typography.h2.fontSize};
  line-height: ${({ theme }) => theme.typography.body1.lineHeight};
  margin-bottom: ${({ theme }) => theme.spacing(0.625)};
`

export const Switcher = styled(Switch)`
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(0.5)}`};

  & .MuiButtonBase-root {
    bottom: 0;
    left: -${({ theme }) => theme.spacing(0.625)};
    color: ${({ theme }) => theme.palette.grey[600]};

    &.Mui-checked {
      transform: translateX(25px);
      color: ${({ theme }) => theme.palette.primary.main};

      & .MuiSwitch-thumb {
        background-image: url(${img});
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  & .MuiSwitch-thumb {
    width: ${({ theme }) => theme.spacing(3.25)};
    height: ${({ theme }) => theme.spacing(3.25)};
  }

  & .MuiSwitch-track {
    border-radius: ${({ theme }) => theme.spacing(6.25)};
    background-color: #d6d6dc;
    opacity: 1;
  }
`
