import {
  Container as DefaultContainer,
  Skeleton as DefaultSkeleton,
  Typography as DefaultTypography
} from 'presentation/components'
import styled from 'styled-components'

export const Skeleton = styled(DefaultSkeleton)`
  border-radius: 8px;
`

export const Container = styled(DefaultContainer)`
  min-height: 267px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    & a,
    span {
      min-width: 60%;
    }
  }
`

export const Typography = styled(DefaultTypography)`
  color: ${({ theme }) => theme.palette.text.secondary};

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-left: ${({ theme }) => theme.spacing(3)};
  }
`
