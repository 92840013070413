import {
  BaseDeleteRepository,
  BaseGetAllRepository,
  BaseSaveRepository,
  QueryParams
} from 'domain/BaseRepository'
import {
  HttpDeleteClient,
  HttpGetClient,
  HttpPostClient
} from 'domain/HttpClient'
import { Favorite, VehicleType } from '../Models/Favorites'

export type FavoritesResult = {
  favorites: Favorite[]
  count: number
}

export type SaveFavoriteRequestDto = {
  advertise_id: number
  site: boolean
  vehicle_type: VehicleType
}

export type SaveFavoriteResponseDto = {
  id: number
  excludeFavoriteKey: string
}

export type GetAllFavoriteResponseDto = {
  count: number
  results: {
    advertise_id: number
    guid: string
  }[]
}

export class FavoriteRepository
  implements
    BaseSaveRepository<Favorite>,
    BaseGetAllRepository<Favorite, { favorites: Favorite[]; count: number }>,
    BaseDeleteRepository<Favorite>
{
  /**
   * FavoriteRepository constructor
   *
   * @param httpClient
   */
  constructor(
    private httpClient: HttpPostClient & HttpGetClient & HttpDeleteClient
  ) {}

  /**
   * Get all favorites from api
   *
   * @param queryParams
   * @returns
   */
  async getAll(
    queryParams?: QueryParams | undefined
  ): Promise<FavoritesResult> {
    if (!queryParams?.user) {
      return { favorites: [], count: 0 }
    }

    const response = await this.httpClient.get<GetAllFavoriteResponseDto>(
      '/extras/showroom',
      {
        queryParams: {
          vehicle_type: queryParams?.path,
          actual_page: 1,
          display_per_page: 9999,
          sort: 1,
          site: true
        },
        headers: {
          authorization: queryParams?.user?.authenticationToken
        }
      }
    )

    const favorites = response.results.map(
      (resultItem) =>
        new Favorite({
          advertiseId: resultItem?.advertise_id,
          id: resultItem.guid
        })
    )
    return { favorites, count: response.count }
  }

  /**
   * Save favorite into api
   *
   * @param favoriteItem
   * @returns
   */
  async save(favoriteItem: Favorite): Promise<Favorite> {
    const response = await this.httpClient.post<
      SaveFavoriteRequestDto,
      SaveFavoriteResponseDto
    >(
      '/extras/showroom',
      {
        advertise_id: favoriteItem.advertiseId,
        site: favoriteItem.site,
        vehicle_type: favoriteItem.vehicleType
      },
      {
        headers: {
          authorization: favoriteItem.user.authenticationToken
        }
      }
    )

    favoriteItem.id = response?.excludeFavoriteKey

    return favoriteItem
  }

  /**
   * Delete item from favorite
   *
   * @param favoriteItem
   * @returns
   */
  async delete(favoriteItem: Favorite): Promise<Favorite> {
    await this.httpClient.delete('/extras/showroom', {
      data: {
        guid: favoriteItem.id
      },
      headers: {
        authorization: favoriteItem.user.authenticationToken
      }
    })

    return favoriteItem
  }
}
