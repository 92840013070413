import megaFairImg from 'presentation/assets/img/abaixo-fipe-logo-mega.webp'
import megaFairFipeImg from 'presentation/assets/img/abaixo-fipe-mega.webp'
import * as S from './MegaFairContent.styles'

export function MegaFairContent({ appUrl, handleClose, handleCTAClick }) {
  return (
    <S.Container className="modal-mega-fair">
      <S.Content>
        <S.Box className="close_modal_login" onClick={handleClose}>
          <S.Icon icon="SLIM_CLOSE" />
        </S.Box>
        <img
          src={megaFairFipeImg}
          alt="Abaixo da tabela - MegaFeirão"
          width={201}
          height={66}
        />
        <img
          src={megaFairImg}
          alt="Logo Tema - MegaFeirão"
          width={127}
          height={60}
        />
        <S.Typography variant="h4">
          Baixe o App para encontrar as ofertas relâmpago abaixo da Tabela.
        </S.Typography>
        <S.Typography variant="body2">
          Consulte condições em webmotors.com.br/ofertas/feiroes/megafeirao.
          <br />
          DIRIGINDO COM SEGURANÇA, TODOS CHEGAM BEM.
        </S.Typography>
        <S.Button
          href={appUrl}
          formTarget="_blank"
          id="btn_to_app"
          variant="primary"
          onClick={handleCTAClick}
        >
          Quero aproveitar!
        </S.Button>
      </S.Content>
    </S.Container>
  )
}
