import '@webmotors/buyer-design-system/dist/index.css'
import { Config } from 'application'
import App from 'presentation/App'
import { createRoot } from 'react-dom/client'
import { createBroswerContext } from 'use-sse'

const BroswerDataContext = createBroswerContext()

const root = createRoot(document.getElementById('root')!)

root.render(
  <BroswerDataContext>
    <App />
  </BroswerDataContext>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (Config.showCoreWebVitalsConsole) {
  import('./reportWebVitals').then((module) => module.default(console.log))
}
