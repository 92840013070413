import { BrowserClient } from 'application/Adapters/Event/EventClient'
import { ROUTES } from 'constants/index'
import { useInsiderTag } from 'presentation/hooks/use-insider'
import { useModal } from 'presentation/hooks/use-modal'
import { useEffect } from 'react'
import * as S from './CookieWarn.styles'
import { useCookieWarn } from './CookieWarnController'

export function CookieWarn() {
  const { handleCookieConsentClick, cookieConsent } = useCookieWarn()
  const { handleInsiderObject } = useInsiderTag()
  const { open } = useModal('CookieControlModal')
  const openCookieSettingsModal = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    open()
  }
  const dispatchConsent = new BrowserClient()

  useEffect(() => {
    if (!cookieConsent) return
    handleInsiderObject({
      properties: { page: { type: 'Home' } },
      cookieConfig: cookieConsent
    })
  }, [cookieConsent])

  useEffect(() => {
    if (!cookieConsent?.showCookieNotice) {
      dispatchConsent.send('acceptedConsent', !cookieConsent?.showCookieNotice)
    }
  }, [cookieConsent?.showCookieNotice, dispatchConsent])

  if (!cookieConsent?.showCookieNotice) {
    return null
  }

  return (
    <S.Container id="cookieWarn">
      <S.Main>
        <p data-testid="Cookie_Title">
          A Webmotors usa Cookies, pequenos arquivos para aprimorar e proteger a
          sua experiência :)
        </p>
        <p>
          <button
            type="button"
            id="btn_cookieWarnSettings"
            data-qa="btn_cookieWarnSettings"
            onClick={openCookieSettingsModal}
          >
            Clique aqui
          </button>{' '}
          para mudar suas preferências ou saiba mais na nossa{' '}
          <S.Link
            id="btn_cookieWarnPrivacy"
            data-qa="btn_cookieWarnPrivacy"
            target="_blank"
            rel="noopener"
            href={ROUTES.HELP.PRIVACY_POLICY}
          >
            Termos de Uso e Política de Privacidade
          </S.Link>{' '}
          e{' '}
          <S.Link
            id="btn_cookieWarnCookiesPrivacy"
            data-qa="btn_cookieWarnCookiesPrivacy"
            target="_blank"
            rel="noopener"
            href={ROUTES.HELP.COOKIES_POLICY}
          >
            Política de Cookies
          </S.Link>
          .
        </p>
      </S.Main>
      <S.BtnUderstood
        id="btn_understoodCookieWarn"
        data-qa="btn_understoodCookieWarn"
        onClick={handleCookieConsentClick}
      >
        Entendi
      </S.BtnUderstood>
    </S.Container>
  )
}
