import { loggerClient } from 'application/Services'
import { EventSend } from 'domain/EventClient'

const logger = loggerClient.create('EventSend')

export class BrowserClient implements EventSend {
  send<T>(key: string, value: T): T {
    logger.info(`eventType: ${key}`, value)
    document.dispatchEvent(new CustomEvent(key, { detail: { value } }))

    return value
  }
}
