import {
  CityParserFromLocal,
  CityParserFromRemote,
  CityRepository,
  LocationService,
  StateParser,
  StateRepository
} from 'domain/Location'
import { axiosClient } from '../Adapters/AxiosClient'
import { cookieClient } from '../Adapters/CookieClient'
import { localCacheClient } from '../Adapters/LocalCacheClient'
import { cookieService } from './CookieService'

export const stateParser = new StateParser()

export const cityParserFromLocal = new CityParserFromLocal(stateParser)
export const cityParserFromRemote = new CityParserFromRemote(stateParser)

export const locationService: LocationService = new LocationService(
  new StateRepository(axiosClient, stateParser),
  new CityRepository(
    axiosClient,
    cookieClient,
    cityParserFromRemote,
    cityParserFromLocal
  ),
  cookieService,
  localCacheClient
)
