import { InsiderTagRepository } from 'domain/Insider/Repository/InsiderTagRepository'
import { InsiderTagService } from 'domain/Insider/Service/InsiderTagService'
import { globalClient } from '../Adapters/GlobalClient'

/**
 * insiderTagRepository
 * The InsiderTagRepository instance to use with the InsiderTagService.
 */
const insiderTagRepository = new InsiderTagRepository(globalClient)

/**
 * insiderTagService
 * The InsiderTagService instance to use in the application.
 */
export const insiderTagService = new InsiderTagService(insiderTagRepository)
