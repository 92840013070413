import {
  Navbar,
  getNavbarLinks,
  linkBuilder
} from '@webmotors/buyer-design-system'
import { Config } from 'application'
import window from 'global/window'
import set from 'lodash/set'
import {
  useAuth,
  useFavoritesCount,
  useHomeDataLayer
} from 'presentation/hooks'
import { isClientSide } from 'presentation/hooks/use-is-client-side'
import { isLocalStorageTimestampExpired } from 'presentation/utils'
import { useMemo, useState } from 'react'
import { Drawer } from '../Drawer/Drawer'

export function Header() {
  const { user, logout } = useAuth()
  const favoritesCount = useFavoritesCount()
  const onClientSide = isClientSide()
  const hasModalLoginStorage = !isLocalStorageTimestampExpired({
    key: Config.hideModalLoginStorageKey
  })
  const hasLoginNotificationStorage =
    onClientSide &&
    window.localStorage.getItem(Config.hideLoginNotificationStorageKey)
  const [showNotification, setShowNotification] = useState(
    hasModalLoginStorage && !hasLoginNotificationStorage && !user
  )
  const { handleHomeDataLayer } = useHomeDataLayer('autoComparaMenuServicos')

  function handleLogoutClick() {
    window.dispatchEvent(new CustomEvent('logout'))
    logout()
  }

  const navbarLinks = useMemo(() => {
    return linkBuilder(getNavbarLinks)
      .user(user)
      .url(`${Config.baseUrl}/`)
      .edit('header_logIn_logOut', (link) => {
        set(link, 'props.onClick', handleLogoutClick)

        return link
      })
      .edit('header_btn_favorite', (link) => {
        return {
          ...link,
          badge: favoritesCount || undefined
        }
      })
      .edit('header_services_insurance', (link) => {
        set(link, 'props.onClick', handleHomeDataLayer)
        return link
      })
      .build()
  }, [user, logout, favoritesCount])

  return (
    <>
      <Navbar showNotification={showNotification} {...navbarLinks} />
      <Drawer
        showNotification={showNotification}
        setShowNotification={setShowNotification}
      />
    </>
  )
}
