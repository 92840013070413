import styled from 'styled-components'
import { Props } from './NewsCard.types'

export const Card = styled.a<Props>`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.secondary.main};
  word-break: break-word;
  display: block;
`

export const NewsImage = styled.div<Props>`
  border-radius: 8px 8px 0 0;
  background: url(${(props) => props.urlImage}) center center / cover no-repeat;
  height: 153px;
`

export const NewsBody = styled.div`
  padding: 10px 8px;
  min-height: 135px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`
export const NewsCategory = styled.div`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${({ theme }) => theme.palette.grey[800]};
  font-size: 0.625rem;
  line-height: 0.9375rem;

  &:before {
    content: '/';
    margin-right: 2px;
    color: ${({ theme }) => theme.palette.brand[600]};
  }
`

export const NewsTitle = styled.h3`
  padding-top: 2px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  line-height: 1.125rem;
  min-height: 60px;
  word-break: break-word;
  white-space: break-spaces;
`

export const NewsAutor = styled.div`
  padding-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const NewsAutorPhoto = styled.div<Props>`
  background-image: url(${(props) => props.authorAvatar});
  min-width: 30px;
  height: 30px;
  border-radius: 100%;
  background-size: cover;
  margin-right: 8px;
`

export const NewsAutorName = styled.span`
  white-space: normal;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 0.625rem;
  line-height: 0.938rem;
  padding-right: 10px;
`
