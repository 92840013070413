import styled from 'styled-components'

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 1110;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
`

export const Input = styled.input`
  border-radius: 0px;
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
  color: #2e2d37;
  outline: none;
  padding: 24px;
  height: 90px;
  border: 0px;
  width: 100%;
`

export const List = styled.div`
  overflow-y: scroll;
  background: white;

  &.is-android {
    max-height: min(350px, calc(100% - 90px));
  }
`

export const Header = styled.div`
  background: #696977;
  padding: 8px 16px;
  line-height: 18px;
  font-weight: 700;
  font-size: 12px;
  color: white;
`

export const Item = styled.a`
  border-bottom: 1px solid #ecedf2;
  text-decoration: none;
  padding: 16px 24px;
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
  color: #696977;
  display: block;

  b {
    color: #2e2d37;
  }

  &:hover {
    cursor: pointer;
  }
`

export const Empty = styled.div`
  border-top: 1px solid #ecedf2;
  padding: 4px 16px;
  background: white;
  font-weight: 500;
  font-size: 14px;
  color: #8b8c99;
`
