import {
  ResultState,
  useLocationsFilter,
  useMobile,
  useModal
} from 'presentation/hooks'

import { City } from 'domain/Location'
import { useEffect, useMemo, useState } from 'react'
import { createGlobalState } from 'react-use'
import { useSearchSectionController } from '../SearchSection/SearchSectionController'

export type LocationsResult = {
  states: ResultState[]
  cities: City[]
}

export const defaultLocationshResult: LocationsResult = {
  states: [],
  cities: []
}

const useInputeValue = createGlobalState<string>('')
const useSearchLocation = createGlobalState<string>('')

export function useSearchLocationController() {
  const searchController = useSearchSectionController()
  const [locationsResult, setLocationshResult] = useState<LocationsResult>(
    defaultLocationshResult
  )
  const [inputValue, setInputValue] = useInputeValue()
  const [searchTerm, setSearchTerm] = useSearchLocation()
  const { open, isOpen: isModalOpen, close } = useModal('AutoCompleteModal')
  const { filteredLocations } = useLocationsFilter(
    searchTerm,
    searchController.searchType
  )
  const isMobile = useMobile()

  /**
   * A/B test Gets has locations results
   *
   */
  const hasLocationsResults = useMemo(
    () =>
      locationsResult.states.length > 0 || locationsResult.cities.length > 0,
    [locationsResult]
  )

  /**
   * Indicates if there is any locations
   *
   */
  const hasLocation = useMemo(() => searchTerm.length >= 2, [searchTerm])

  /**
   * A/B test display empty locations
   *
   */
  const displayEmptyLocations = useMemo(
    () => hasLocation && !hasLocationsResults,
    [hasLocation, hasLocationsResults]
  )

  /**
   * Handle input focus
   *
   * @returns
   */
  function handleFocus() {
    if (!isMobile) {
      return
    }

    open()
  }

  /**
   * A/B test Handle filter locations
   *
   * @param query
   */
  function handleFilterLocations(query) {
    if (!query || query.length < 2) {
      return setLocationshResult({ states: [], cities: [] })
    }
    const states = filteredLocations.statesMatchTerm.slice(0, 5)
    const cities = filteredLocations.citiesMatchTerm.slice(0, 5 - states.length)

    setLocationshResult({ states, cities })
  }

  /**
   * A/B test Clear search
   *
   * @returns
   */
  function clearSearch() {
    setSearchTerm('')
    return setLocationshResult({ states: [], cities: [] })
  }

  /**
   * Print search results with hightlights
   *
   * @param word
   * @returns
   */
  function printWithHighlights(word) {
    const regex = new RegExp(searchTerm, 'gi')

    return word.replace(regex, (s) => `<b>${s}</b>`)
  }

  /**
   * A/B test locations update on every input change
   *
   */
  useEffect(() => {
    handleFilterLocations(searchTerm)
  }, [searchTerm])

  /**
   * Apply offers count on tab change
   *
   */
  useEffect(() => {
    setInputValue('')
    searchController.setSearchTerm('')
    clearSearch()
  }, [searchController.searchType])

  return {
    displayEmptyLocations,
    printWithHighlights,
    hasLocationsResults,
    locationsResult,
    setInputValue,
    setSearchTerm,
    clearSearch,
    isModalOpen,
    handleFocus,
    hasLocation,
    inputValue,
    searchTerm,
    close
  }
}
