import * as S from './Icon.styles'

import { COLORS } from 'constants/index'
import { ICONS } from './Icon.data'
import { IconProps } from './Icon.types'

export function Icon({
  icon,
  title,
  width = 24,
  height = 24,
  colorHover,
  color = [COLORS.GRAY[900]],
  viewBox = '0 0 24 24'
}: IconProps) {
  const { path } = ICONS[icon]

  //const elementViewBox = props.viewbox ? props.viewbox : viewBox

  return (
    <S.Svg
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      colorHover={colorHover}
    >
      {title && <title>{title}</title>}
      {path.map((svgPath: string, idx: number) => {
        const id = `Icon_${idx}`
        return (
          <S.Path
            key={id}
            data-testid={id}
            d={svgPath}
            fill={color[idx] ?? color[0]}
          />
        )
      })}
    </S.Svg>
  )
}
