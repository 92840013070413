import {
  LocalOfficialStoreRepository,
  OfficialStoreService
} from 'domain/OfficialStore'

import { RemoteOfficialStoreRepository } from 'domain/OfficialStore/Repository/RemoteOfficialStoreRepository'
import { axiosClient } from '../Adapters/AxiosClient'
import { globalClient } from '../Adapters/GlobalClient'

export const officialStoreService: OfficialStoreService =
  new OfficialStoreService(
    new LocalOfficialStoreRepository(globalClient),
    new RemoteOfficialStoreRepository(axiosClient)
  )
