import { Store } from 'domain/OfficialStore'
import { SearchItem } from 'domain/Search/Models/SearchItem'

function generateRandomNumber() {
  const randomBytes = new Uint8Array(4)
  window.crypto.getRandomValues(randomBytes)

  const randomFraction = randomBytes.reduce(
    (acc, value, index) => acc + value / Math.pow(256, index + 1),
    0
  )

  return randomFraction - 0.5
}

export function reorderStoresByLastSearch(
  stores: Store[],
  searchType: string,
  lastSearches?: SearchItem[]
) {
  const filteredStores = new Map<string, Store>()

  if (!lastSearches?.length) {
    stores.sort(generateRandomNumber).forEach((store) => {
      filteredStores.set(store.title?.toUpperCase() || '', store)
    })

    return Array.from(filteredStores.values())
  }

  const lastSearchesBySegment = lastSearches?.filter((lastSearch) => {
    return lastSearch.segment === searchType
  })

  const lastSearchesBrands = lastSearchesBySegment?.map((item) => item.brand)

  const unsearchedStores = stores.filter(
    (item) => !lastSearchesBrands?.includes(item.title?.toUpperCase())
  )

  lastSearchesBySegment?.map((last) => {
    const storeItem = stores.find(
      (store) => store.title?.toUpperCase() === last.brand
    ) as Store

    if (!storeItem) return

    filteredStores.set(storeItem.title?.toUpperCase() || '', storeItem)
  })

  unsearchedStores.sort(generateRandomNumber).forEach((store) => {
    filteredStores.set(store.title?.toUpperCase() || '', store)
  })

  return Array.from(filteredStores.values())
}
