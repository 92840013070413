import { BaseGetConfigsRepository, BaseRepository } from 'domain/BaseRepository'
import { Category } from '../Model/Category'

export class CategoryService {
  constructor(
    private remoteRepository: BaseRepository<Category>,
    private configsRepository: BaseGetConfigsRepository<Category>
  ) {}

  /**

   * Get all available from remote
   *
   * @returns {Promise<Category[]>}
   */
  getAllFromRemote(): Promise<Category[]> {
    return this.remoteRepository.getAll()
  }

  /**
   * Get all available from config
   *
   * @returns {Category[]}
   */
  getAllFromConfig(): Category[] {
    return this.configsRepository.getAll()
  }
}
