import { FakableClass, FakableField } from 'fake-class'
import { Faker } from 'faker'

export enum LoginStatus {
  LOGGED = 1,
  LOGGEDOUT = 2
}

@FakableClass()
export class Pii {
  lead?: {
    email?: string
    name?: string
    phoneNumber?: string
  }

  location?: {
    optin?: {
      state?: string
      city?: string
    }
  }

  constructor(params: Partial<Pii> = {}) {
    this.lead = {
      email: params.lead?.email,
      name: params.lead?.name,
      phoneNumber: params.lead?.phoneNumber
    }

    this.location = {
      optin: {
        state: params.location?.optin?.state,
        city: params.location?.optin?.city
      }
    }
  }
}

@FakableClass()
export class User {
  /**
   * User loginStatus
   * Armazena "1" caso o usuário esteja logado, "2" caso não.
   */
  @FakableField(() => Faker.string(10))
  loginStatus!: LoginStatus

  @FakableField(() => new Pii())
  pii: Pii

  constructor(params: Partial<User> = {}) {
    Object.assign(this, params)
    this.pii = params.pii ? new Pii(params.pii) : new Pii()
  }
}
