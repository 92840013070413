import { Config } from 'application'
import { Box, Icon, Modal } from 'presentation/components/'
import * as S from './LoginMobileModal.styles'
import { useLoginMobileModalController } from './LoginMobileModalController'

export function LoginMobileModal() {
  const {
    isMobile,
    acceptedConsent,
    isBlackFairActive,
    isMegaFairActive,
    user,
    isOpen,
    classes,
    handleModalClose,
    isDrawerOpen
  } = useLoginMobileModalController()

  if (isBlackFairActive !== false || isMegaFairActive !== false) {
    return null
  }

  return isMobile && !!acceptedConsent && !user && !isDrawerOpen ? (
    <Modal
      data-testid="LoginMobileModal"
      open={isOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={`${classes.root}`}
    >
      <S.Container>
        <Box className="close_modal_login" onClick={handleModalClose}>
          <Icon icon="SLIM_CLOSE" />
        </Box>
        <S.Typography variant="h4">
          Faça login para uma experiência ainda melhor ;)
        </S.Typography>
        <ul>
          <li>
            <Icon icon="CHECK" /> <span>Salve seus anúncios favoritos</span>
          </li>
          <li>
            <Icon icon="CHECK" />
            <span>Acesse o chat de suas conversas</span>
          </li>
          <li>
            <Icon icon="CHECK" /> <span>Gerencie seus anúncios</span>
          </li>
        </ul>
        <S.Button
          href={`${Config.loginRoute}?lkid=1364&r=${Config.baseUrl}`}
          id="btn_sign_in"
          data-qa="btn_sign_in"
        >
          Fazer login
        </S.Button>
        <Box
          className="sign_in"
          id="btn_create_account"
          data-qa="btn_create_account"
        >
          <S.Typography>
            Não tem uma conta?{' '}
            <a
              href={`${Config.loginRoute}/register?lkid=1957&r=${Config.baseUrl}`}
            >
              Criar conta
            </a>
          </S.Typography>
        </Box>
      </S.Container>
    </Modal>
  ) : null
}
