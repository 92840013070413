import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { numberFormatService } from 'application/Services'
import { Offer } from 'domain/Search/Models/Offer'
import carDeliveryStamp from 'presentation/assets/stamps/car-delivery.svg'
import megaSaleStamp from 'presentation/assets/stamps/mega-feirao.png'
import superPriceStamp from 'presentation/assets/stamps/super-preco.svg'
import changeBackStamp from 'presentation/assets/stamps/troca-com-troco.svg'
import { Gallery, GalleryItem } from 'presentation/components'
import { useMobile, useQueryParams } from 'presentation/hooks'
import { useMemo } from 'react'
import * as Styles from './VehicleCard.styles'
import { useVehicleCardController } from './VehicleCardController'

export type VehicleCardProps = {
  offer: Offer
  [x: string]: any
}

export function VehicleCard({
  offer: initialOffer,
  ...props
}: VehicleCardProps) {
  const offerId = useQueryParams<string>('offer_id')
  const vehicleCardController = useVehicleCardController(
    initialOffer,
    offerId as string
  )
  const { offer } = vehicleCardController
  const isMobile = useMobile()

  const fontSize = useMemo(() => {
    return isMobile ? 12 : 18
  }, [isMobile])

  return (
    <Styles.Container target="_blank" href={offer.getUrl('2217')} {...props}>
      <Styles.Header>
        {isMobile && <Styles.Image source={offer.photos?.[0]} />}
        {!isMobile && (
          <Gallery>
            {offer.photos.map((photo, index) => (
              <GalleryItem key={`Gallery-${index + 1}`}>
                <Styles.Image source={photo} />
              </GalleryItem>
            ))}
          </Gallery>
        )}
        {isMobile && offer.stamps?.megaSale && (
          <Styles.Banner backgroundImage={megaSaleStamp} />
        )}
      </Styles.Header>
      <Styles.Body>
        <Styles.TagList>
          {offer.stamps?.carDelivery && (
            <Styles.TagItem backgroundImage={carDeliveryStamp} />
          )}
          {offer.stamps?.changeBack && (
            <Styles.TagItem backgroundImage={changeBackStamp} />
          )}
          {offer.stamps?.superPrice && (
            <Styles.TagItem backgroundImage={superPriceStamp} />
          )}
        </Styles.TagList>
        <Styles.BodySection>
          {!isMobile && offer.stamps?.megaSale && (
            <Styles.Banner backgroundImage={megaSaleStamp} />
          )}
          <Styles.Title>
            {offer.make} {offer.model}
          </Styles.Title>
          <Styles.Version data-testid="Vehicle_Version">
            {offer.version}
          </Styles.Version>
        </Styles.BodySection>

        <Styles.BodySection>
          <Styles.Price data-testid="Vehicle_Pricing">
            {offer.price}
          </Styles.Price>
          <Styles.DataGroup>
            <Styles.DataLabel data-testid="Vehicle_Year">
              {offer.year}
            </Styles.DataLabel>
            <Styles.DataLabel data-testid="Vehicle_Travelled_Distance">
              {numberFormatService.humanReadableOutput(
                numberFormatService.extractNumber(offer.travelledDistance)
              )}{' '}
              km
            </Styles.DataLabel>
          </Styles.DataGroup>
        </Styles.BodySection>
      </Styles.Body>
      <Styles.Footer>
        <Styles.DataGroup>
          <Styles.DataLabel className="full-line">
            <LocationOnIcon sx={{ fontSize }} />
            {offer.location}
          </Styles.DataLabel>
          <Styles.DataLabel>
            <Styles.ButtonFavorite
              className="was-favorited"
              onClick={vehicleCardController.handleSaveFavorite(false)}
            >
              {vehicleCardController.wasFavorited && (
                <FavoriteIcon sx={{ fontSize, color: '#e11138' }} />
              )}
              {!vehicleCardController.wasFavorited && (
                <FavoriteBorderIcon sx={{ fontSize }} />
              )}
            </Styles.ButtonFavorite>
          </Styles.DataLabel>
        </Styles.DataGroup>
      </Styles.Footer>
    </Styles.Container>
  )
}
