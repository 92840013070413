import { BaseFindOneRepository } from 'domain/BaseRepository'
import { OfficialStore } from '../Model/OfficialStore'

export class OfficialStoreService {
  constructor(
    private localRepository: BaseFindOneRepository<OfficialStore>,
    private remoteRepository: BaseFindOneRepository<OfficialStore>
  ) {}

  /**
   * Get all available from local
   *
   * @returns {Promise<OfficialStore>}
   */
  getFromLocal(): Promise<OfficialStore> {
    return this.localRepository.findOne()
  }

  getFromRemote(): Promise<OfficialStore> {
    return this.remoteRepository.findOne()
  }
}
