import { Config } from 'application'
import { loggerClient } from 'application/Services'
import { LocalRemoveItem } from 'domain/LocalClient'

export type RequestBody = {
  Username: string
}

export type ResponseBody = {
  Success: boolean
  Message: null
  Data: null
}

const logger = loggerClient.create('AuthService')

export class AuthService {
  /**
   * Constructor method
   *
   * @param httpClient
   */
  constructor(private localRemoveItem: LocalRemoveItem) {}

  /**
   * Logs current logged user off
   *
   * @param userId
   * @returns
   */
  async logout(): Promise<boolean> {
    logger.info('[logout] login user out')

    this.localRemoveItem.removeItem(Config.authCookieName)

    logger.info('[logout] user logged out')
    return true
  }
}
