import { FairCategory } from 'domain/Category/Model/FairCategory'

export const blackFairCategoriesLinks: FairCategory[] = [
  {
    backgroundMobile: '/assets/img/FireImageMobile.webp',
    backgroundDesktop: '/assets/img/FireImageDesktop2024.webp',
    title: 'Carros abaixo da Tabela',
    url: 'ofertas/feiroes/blackfeirao/carros/estoque?tipoveiculo=carros&Oportunidades=Super%20Preco&lkid=2131'
  },
  {
    backgroundMobile: '/assets/img/FireImageMobile.webp',
    backgroundDesktop: '/assets/img/FireImageDesktop2024.webp',
    title: 'Motos abaixo da Tabela',
    url: 'ofertas/feiroes/blackfeirao/carros/estoque?tipoveiculo=carros&Oportunidades=Super%20Preco&lkid=2132'
  },
  {
    backgroundMobile: '/assets/img/MoneyMobile2024.webp',
    backgroundDesktop: '/assets/img/MoneyDesktop2024.webp',
    title: 'Ofertas até R$30 mil',
    url: 'ofertas/feiroes/blackfeirao/carros/estoque?tipoveiculo=carros&precoate=30000&lkid=1412'
  },
  {
    backgroundMobile: '/assets/img/MoneyMobile2024.webp',
    backgroundDesktop: '/assets/img/MoneyDesktop2024.webp',
    title: 'Ofertas de R$30 mil a R$40 mil',
    url: 'ofertas/feiroes/blackfeirao/carros/estoque?tipoveiculo=carros&precoate=40000&precode=30000&lkid=1413'
  },
  {
    backgroundMobile: '/assets/img/MoneyMobile2024.webp',
    backgroundDesktop: '/assets/img/MoneyDesktop2024.webp',
    title: 'Ofertas de R$40 mil a R$50 mil',
    url: 'ofertas/feiroes/blackfeirao/carros/estoque?tipoveiculo=carros&precoate=50000&precode=40000&lkid=1414'
  },
  {
    backgroundMobile: '/assets/img/MoneyMobile2024.webp',
    backgroundDesktop: '/assets/img/MoneyDesktop2024.webp',
    title: 'Ofertas de R$50 mil a R$60 mil',
    url: 'ofertas/feiroes/blackfeirao/carros/estoque?tipoveiculo=carros&precoate=60000&precode=50000&lkid=1415'
  },
  {
    backgroundMobile: '/assets/img/MoneyMobile2024.webp',
    backgroundDesktop: '/assets/img/MoneyDesktop2024.webp',
    title: 'Ofertas de R$60 mil ou mais',
    url: 'ofertas/feiroes/blackfeirao/carros/estoque?tipoveiculo=carros&precode=60000&lkid=1416'
  }
]
