import { ButtonProps, Theme } from '@mui/material'
import { Interpolation } from '@mui/styled-engine'

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true
    secondary: true
    'outline-1': true
    'outline-2': true
    ghost: true
    cancel: true
  }
}

type Variant = {
  props: Partial<ButtonProps<'button'>>
  style: Interpolation<{
    theme: Theme
  }>
}

type CssWithTheme = Interpolation<{
  theme: Theme
}>

const buttonBaseStyle: CssWithTheme = {
  fontFamily: 'Poppins',
  fontWeight: 600,
  padding: '15px 16px',
  textTransform: 'none',
  borderRadius: '8px',
  fontSize: '1rem',
  lineHeight: 1.5
}

const generateButtonVariant = (
  variant: ButtonProps<'button'>['variant'],
  buttonStyle: Interpolation<{ theme: Theme }>
): Variant[] => {
  return [
    {
      props: { variant },
      style: buttonStyle
    },
    {
      props: { variant, size: 'large' },
      style: buttonStyle
    },
    {
      props: { variant, size: 'small' },
      style: {
        ...(buttonStyle as Record<string, string | number>),
        fontSize: '12px',
        padding: '11px 16px',
        fontWeight: 700
      }
    }
  ]
}

const buttonPrimaryStyle: CssWithTheme = {
  ...buttonBaseStyle,
  backgroundColor: '#e11138',
  color: '#fff'
}

const variantsButtonPrimary: Variant[] = generateButtonVariant(
  'primary',
  buttonPrimaryStyle
)

const buttonSecondaryStyle: CssWithTheme = {
  ...buttonBaseStyle,
  backgroundColor: '#2E2D37',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#2E2D37'
  }
}

const variantsButtonSecondary: Variant[] = generateButtonVariant(
  'secondary',
  buttonSecondaryStyle
)

const buttonOutlinePrimaryStyle: CssWithTheme = {
  ...buttonBaseStyle,
  border: '1px solid #e11138',
  backgroundColor: '#fff',
  color: '#e11138'
}

const variantsButtonOutlinePrimary: Variant[] = generateButtonVariant(
  'outline-1',
  buttonOutlinePrimaryStyle
)

const buttonOutlineSecondaryStyle: CssWithTheme = {
  ...buttonBaseStyle,
  border: '1px solid #2E2D37',
  backgroundColor: '#fff',
  color: '#2E2D37',
  '&:hover': {
    color: '#fff',
    backgroundColor: '#2E2D37'
  }
}

const variantsButtonOutlineSecondary: Variant[] = generateButtonVariant(
  'outline-2',
  buttonOutlineSecondaryStyle
)

const buttonGhostStyle: CssWithTheme = {
  ...buttonBaseStyle,
  color: '#e11138',
  backgroundColor: '#fff',
  '&:hover': {
    color: '#e11138',
    backgroundColor: '#fff'
  }
}

const variantsButtonGhost: Variant[] = generateButtonVariant(
  'ghost',
  buttonGhostStyle
)

const buttonCancelStyle: CssWithTheme = {
  ...buttonBaseStyle,
  color: '#696977',
  backgroundColor: '#fff',
  border: '1px solid #696977',
  '&:hover': {
    color: '#696977',
    backgroundColor: '#fff'
  }
}

const variantsButtonCancel: Variant[] = generateButtonVariant(
  'cancel',
  buttonCancelStyle
)

export const buttonVariants: Variant[] = [
  ...variantsButtonPrimary,
  ...variantsButtonSecondary,
  ...variantsButtonOutlinePrimary,
  ...variantsButtonOutlineSecondary,
  ...variantsButtonGhost,
  ...variantsButtonCancel
]
