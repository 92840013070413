import { useMediaQuery } from '@mui/material'
import {
  Footer as FooterUI,
  getFooterLinks
} from '@webmotors/buyer-design-system'
import { Config } from 'application'
import { useHomeDataLayer, useModal } from 'presentation/hooks'
import { useEffect } from 'react'
import { useTheme } from 'styled-components'
import * as Styles from './Footer.styles'

export function Footer() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const footerLinks = getFooterLinks({ baseUrl: `${Config.baseUrl}/` })
  const { open } = useModal('CookieControlModal')
  const openCookieSettingsModal = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    open()
  }
  const { handleHomeDataLayer } = useHomeDataLayer('autoComparaFooter')

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const element = document.getElementById('footer_services_insurance')
      if (element) {
        element.addEventListener('click', handleHomeDataLayer)

        return () => {
          element.removeEventListener('click', handleHomeDataLayer)
        }
      }
    }
  }, [])

  return (
    <Styles.Container>
      <FooterUI
        links={footerLinks}
        isMobile={isMobile}
        callCookies={(event) => openCookieSettingsModal(event)}
      />
    </Styles.Container>
  )
}
