import { Config } from 'application'

export type CreateUrlConfig = {
  params?: {
    [x: string]: any
  }
  baseUrl?: string
}

export function objectToQueryString(obj) {
  const str: string[] = []
  for (const p in obj) {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`)
  }

  return str.join('&')
}

export function createUrl(path: string, config?: CreateUrlConfig): string {
  const baseUrl = config?.baseUrl || Config.baseUrl

  let searchParams = ''
  if (config?.params) {
    searchParams = `?${objectToQueryString(config.params)}`
  }

  return `${baseUrl}${path}${searchParams}`
}
