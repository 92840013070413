import { FakableClass, FakableField } from 'fake-class'
import { Faker } from 'faker'

@FakableClass()
export class BestSeller {
  /**
   * BestSeller Id
   */
  @FakableField(() => Faker.number())
  id!: number

  /**
   * BestSeller make
   */
  @FakableField(() => Faker.string(10))
  make!: string

  /**
   * BestSeller model
   */
  @FakableField(() => Faker.string(10))
  model!: string

  /**
   * BestSeller order
   */
  @FakableField(() => Faker.number().toString())
  order!: string

  /**
   * BestSeller constructor method
   *
   * @param params
   */
  constructor(params: Partial<BestSeller>) {
    Object.assign(this, params)
  }
}
