export class CookieConsents {
  /**
   * CookieConsents to use proposals
   *
   */
  proposals!: boolean

  /**
   * CookieConsents to search
   *
   */
  search!: boolean

  /**
   * CookieConsents to marketing
   *
   */
  marketing!: boolean

  /**
   * CookieConsents to experience
   *
   */
  experience!: boolean

  /**
   * CookiesConsents contructor
   *
   * @param params
   */
  constructor(params: Partial<CookieConsents>) {
    Object.assign(this, params)
  }
}
