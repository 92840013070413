const ENV = process.env.REACT_APP_ENVIRONMENT || 'hml'

const baseWMRoute = {
  test: 'http://localhost:3000/',
  development: 'http://localhost:3000/',
  hml: 'https://hportal.webmotors.com.br/',
  blue: 'https://azulportal.webmotors.com.br/',
  production: 'https://www.webmotors.com.br/'
}

const baseWMLogin = {
  test: 'https://hportal.webmotors.com.br/login',
  development: 'https://hportal.webmotors.com.br/login',
  hml: 'https://hportal.webmotors.com.br/login',
  blue: 'https://www.webmotors.com.br/login',
  production: 'https://www.webmotors.com.br/login'
}

const WMBaseUrl = baseWMRoute[ENV]
const WMBaseLoginUrl = baseWMLogin[ENV]

const defaultRoutes = {
  BASE: WMBaseUrl,
  LOGIN: `${WMBaseLoginUrl}`,
  REGISTER: `${WMBaseLoginUrl}/register`,
  WM_APP_DOWNLOAD: 'https://app.adjust.com/rfifsh8',
  GARAGE: {
    BASE: `${WMBaseUrl}garagem`,
    PERFIL: `${WMBaseUrl}garagem/perfil`,
    PRODUCTS: `${WMBaseUrl}garagem/produtos`,
    FAVORITES: `${WMBaseUrl}garagem/favoritos`,
    PROPOSALS: `${WMBaseUrl}garagem/propostas`
  },
  POLICY: {
    ALO_ETICA:
      'https://aloetica.com.br/otrs/canal-de-etica.pl?CustomerID=webmotors.com.br',
    CONDUCT_CODE:
      'https://www.webmotors.com.br/seguranca/assets/files/codigo-de-conduta.pdf',
    CONSUMER:
      'https://cdn.webmotors.com.br/webmotors/content/pdf/cdc.pdf?v:32673',
    PRIVACY: 'https://www.webmotors.com.br/seguranca/politica-de-privacidade',
    LGPD: 'https://www.webmotors.com.br/seguranca/lgpd'
  },
  SERVICES: {
    FIPE: `${WMBaseUrl}tabela-fipe/`,
    FINANCING: `${WMBaseUrl}financiamento`,
    DEALER_PLATFORM: 'https://cockpit.com.br/',
    INSURANCE: 'https://www.autocompara.com.br/',
    CAR10: 'https://www.webmotors.com.br/car10',
    AUTOPAGO: 'https://autopago.webmotors.com.br/',
    AUTOINSIGHTS: 'https://publicidade.webmotors.com.br/'
  },
  HELP: {
    YOU: 'https://ajuda.webmotors.com.br/hc/pt-br',
    HOW_TO_BUY: 'https://ajuda.cockpit.com.br/hc/pt-br/',
    HOW_TO_SELL:
      'https://ajuda.webmotors.com.br/hc/pt-br/articles/360038764251-como-anunciar-/',
    STORE: 'https://ajuda.cockpit.com.br/hc/pt-br',
    SECURITY: 'https://www.webmotors.com.br/seguranca/',
    PRIVACY_POLICY:
      'https://www.webmotors.com.br/seguranca/politica-de-privacidade/',
    COOKIES_POLICY:
      'https://www.webmotors.com.br/assets/politica-de-cookies.pdf',
    INSTITUCIONAL: 'https://www.webmotors.com.br/institucional/',
    WORK_WITH_US: 'https://webmotors.gupy.io/',
    SITEMAP: `${WMBaseUrl}mapasite`
  },
  WM1: {
    PORTAL: `https://www.webmotors.com.br/wm1`,
    LASTNEWS: `${WMBaseUrl}wm1/noticias`,
    TESTS: `${WMBaseUrl}wm1/testes`,
    COMPARATIVES: `${WMBaseUrl}wm1/comparativos`,
    VIDEOS: `${WMBaseUrl}wm1/videos-de-carros-e-motos`,
    BIKES: `${WMBaseUrl}wm1/motos`,
    SECRETS: `${WMBaseUrl}wm1/segredos-auto`,
    TIPS: `${WMBaseUrl}wm1/dicas`,
    POCKET: `${WMBaseUrl}wm1/dinheiro-e-economia`
  },
  CAR: {
    NEW: `${WMBaseUrl}carros-novos/estoque`,
    USED: `${WMBaseUrl}carros-usados/estoque`,
    BY_STATE: `${WMBaseUrl}mapasite/carros`,
    ZERO: `${WMBaseUrl}catalogo`,
    SELL: `${WMBaseUrl}vender`
  },
  BIKE: {
    NEW: `${WMBaseUrl}motos-novas/estoque`,
    USED: `${WMBaseUrl}motos-usadas/estoque`,
    BY_STATE: `${WMBaseUrl}mapasite/motos`,
    SELL: `${WMBaseUrl}vender/moto`
  }
}

const developmentRoutes = {
  ...defaultRoutes
}

const homologationRoutes = {
  ...defaultRoutes
}

const blueRoutes = {
  ...defaultRoutes
}

const productionRoutes = {
  ...defaultRoutes
}

const enviromentRoutesSetup = {
  test: developmentRoutes,
  development: developmentRoutes,
  hml: homologationRoutes,
  blue: blueRoutes,
  production: productionRoutes
}

export const ROUTES = enviromentRoutesSetup[ENV]

export const COLORS = {
  GRAY: {
    900: '#2E2D37',
    800: '#696977',
    300: '#AEAEBA'
  },
  GREEN: {
    200: '#4ACE00'
  },
  RED: {
    200: '#e11138'
  }
}

export const TOOLTIP = {
  BADGE_PRICE: 'Alerta para grandes negócios: este pode ser um ótimo negócio',
  BADGE_CHANGE: 'Troca com troco',
  BADGE_DELIVERY: 'Car Delivery'
}

export const COOKIE_NOTICE_TYPES = {
  ESSENTIALS: 'essentials',
  PROPOSALS: 'proposals',
  SEARCH: 'search',
  MARKETING: 'marketing',
  EXPERIENCE: 'experience'
}

export const CURRENT_YEAR = '2022'
export const LIMIAR_TO_DESKTOP_WIDTH = '901'

export enum portugueseVehicleType {
  CAR = 'Carro',
  MOTORCYCLE = 'Moto'
}
