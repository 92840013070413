import { Faker } from 'faker'

import { FakableClass, FakableField } from 'fake-class'
import { cleanString } from 'presentation/utils'
import slugify from 'slugify'
import { State } from './State'

@FakableClass()
export class City {
  /**
   * City latitude
   *
   */
  @FakableField(() => Number.parseFloat(Faker.latitude()))
  latitude!: number

  /**
   * City longitude
   *
   */
  @FakableField(() => Number.parseFloat(Faker.longitude()))
  longitude!: number

  /**
   * City name
   *
   */
  @FakableField(() => Faker.cityName())
  name!: string

  /**
   * City range
   *
   */
  @FakableField(() => Faker.number())
  range!: number

  /**
   * City count
   *
   */
  @FakableField(() => Faker.number())
  count = 0

  /**
   * City state
   *
   */
  state!: State

  /**
   * City constructor
   *
   * @param params
   */
  constructor(params: Partial<City>) {
    Object.assign(this, params)
  }

  /**
   * Parse city name as slug
   *
   * @returns
   */
  toSlug(replacement = '-'): string {
    if (!this.name) {
      return ''
    }

    return slugify(this.name, {
      lower: true,
      replacement
    })
  }

  /**
   * Parse city as string
   *
   * @returns
   */
  toUrlString(): string {
    if (this.name) {
      return `${this.state.abbr.toLowerCase()}-${cleanString(this.name, {
        removeAccents: true,
        toLowerCase: true,
        replaceSpace: '-'
      })}`
    }

    return this.state.abbr.toLowerCase()
  }

  /**
   * Parse city and state to estadocidade format
   *
   * @returns
   */
  toReadableString(): string {
    if (!this.name) {
      return this.state.name
    }

    return `${this.state.name} - ${this.name}`
  }

  /**
   * Parse city as string
   *
   * @returns
   */
  toString(): string {
    if (this.name) {
      return `${this.name} - ${this.state.abbr}`
    }

    return this.state.name
  }

  /**
   * Check if given city is the same
   *
   * @param city
   * @returns
   */
  isEqual(city: City): boolean {
    const checkCity = this.name
    const sameCity =
      cleanString(checkCity, { removeAccents: true, toLowerCase: true }) ===
      cleanString(city?.name, { removeAccents: true, toLowerCase: true })
    const sameState =
      this.state?.name?.toLowerCase() === city.state?.name?.toLowerCase()
    return sameCity && sameState
  }
}
