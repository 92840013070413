import { Button as DefaultButton } from 'presentation/components'

import styled from 'styled-components'
import { IDefaultTextAtributes } from './CookieControlModal.types'

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: ${({ theme }) => theme.spacing(2.5)};
  color: ${({ theme }) => theme.palette.grey[900]};
  background: ${({ theme }) => theme.palette.common.white};
  z-index: ${({ theme }) => theme.zIndex.modal};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  width: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 800px;
`
export const Title = styled.h3`
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: 1.25rem;
  line-height: ${({ theme }) => theme.typography.body1.lineHeight};
`

export const Text = styled.p<IDefaultTextAtributes>`
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  line-height: ${({ theme }) => theme.typography.body1.lineHeight};
  margin-top: ${({ marginTop }) => `${marginTop}px`};
`

export const SwitchArea = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1.25)};
  margin-bottom: ${({ theme }) => theme.spacing(2.5)};
  overflow-y: auto;
  &:-webkit-scrollbar {
    width: ${({ theme }) => theme.spacing(1.25)};
  }
`

export const Link = styled.a`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${({ theme }) => theme.palette.brand[600]};
`

export const CloseButton = styled.button`
  cursor: pointer;
  background: transparent;
  position: absolute;
  right: ${({ theme }) => theme.spacing(2.5)};
  top: ${({ theme }) => theme.spacing(2.5)};
  & svg {
    width: ${({ theme }) => theme.spacing(2.5)};
    height: ${({ theme }) => theme.spacing(2.5)};
  }
`

export const Button = styled(DefaultButton)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing(2.5)};
`
