import { Button as MUIButton, ButtonProps, useMediaQuery } from '@mui/material'
import { useTheme } from 'styled-components'

type Props = ButtonProps & { responsive?: boolean }

export function Button<P extends ButtonProps>({ responsive, ...props }: Props) {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const responsiveActive = responsive && isMobile

  return (
    <MUIButton
      data-testid="button"
      {...(props as P)}
      size={`${responsiveActive ? 'small' : props.size}`}
    />
  )
}
