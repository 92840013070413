import * as S from './NewsCard.styles'
import { Props } from './NewsCard.types'

export function NewsCard({ news, dataQA }: { news: Props; dataQA: string }) {
  const { url, urlImage, category, title, author, authorAvatar } = news
  return (
    <S.Card
      href={url}
      target="_blank"
      rel="noreferrer"
      data-testid="NewsWebCard"
      data-qa={dataQA}
    >
      <S.NewsImage
        {...{
          urlImage
        }}
      />
      <S.NewsBody>
        <div>
          <S.NewsCategory>{category}</S.NewsCategory>
          <S.NewsTitle>{title}</S.NewsTitle>
        </div>
        <S.NewsAutor>
          <S.NewsAutorPhoto
            title={`foto do autor ${author}`}
            {...{ authorAvatar }}
          />
          <S.NewsAutorName>por {author}</S.NewsAutorName>
        </S.NewsAutor>
      </S.NewsBody>
    </S.Card>
  )
}
