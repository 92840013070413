import { searchService } from 'application'
import { useEffect } from 'react'
import { createGlobalState } from 'react-use'

const useVehicleState = createGlobalState(searchService.getSearchSegment())

const EVENT_NAME = 'handleVehicleTypeChange'

export function useVehicleType() {
  const [vehicleType, setVehicleType] = useVehicleState()

  /**
   * Handle vehicle type change event
   *
   */
  useEffect(() => {
    const handleVehicleTypeChange = (e) => {
      setVehicleType(e.detail)
    }

    document.addEventListener(EVENT_NAME, handleVehicleTypeChange)

    return () =>
      document.removeEventListener(EVENT_NAME, handleVehicleTypeChange)
  }, [])

  return {
    setVehicleType,
    vehicleType
  }
}
