import { cookieService } from 'application'
import { CookieConfig } from 'domain/Cookie/Models/CookieConfig'
import { useModal } from 'presentation/hooks'
import { useInsiderTag } from 'presentation/hooks/use-insider'
import { useEffect, useState } from 'react'

export function useCookieControl() {
  const [cookieConfig, setCookieConfig] = useState<CookieConfig | null>(null)
  const { isOpen, close } = useModal('CookieControlModal')
  const { handleInsiderObject } = useInsiderTag()

  /**
   * Save user cookie config
   *
   * @returns
   */
  async function save() {
    if (!cookieConfig) {
      return
    }
    await cookieService.saveCookieConsent(cookieConfig)
    close()
    handleInsiderObject({
      properties: { page: { type: 'Home' } },
      cookieConfig
    })
  }

  /**
   * Get current cookie configuration
   *
   */
  useEffect(() => {
    if (!isOpen) return
    cookieService.getCookieConsent().then((cookieConfigResult) => {
      setCookieConfig(cookieConfigResult)
    })
  }, [isOpen])

  return {
    setCookieConfig,
    cookieConfig,
    isOpen,
    close,
    save
  }
}
