import { CloseOutlined, PlaceOutlined, Search } from '@mui/icons-material'
import { Config } from 'application'
import { numberFormatService } from 'application/Services'
import { SearchItemSegment } from 'domain/Search/Models/SearchItem'
import { useHomeDataLayer, useLocation, useMobile } from 'presentation/hooks'
import { isClientSide } from 'presentation/hooks/use-is-client-side'
import { formatVehicleCategoryDataLayer } from 'presentation/utils/dataLayer'
import { transformToTitleCase } from 'presentation/utils/strings/transformToTitleCase'
import { useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { ButtonGroup } from '../ButtonGroup/ButtonGroup'
import { useSearchLocationController } from '../SearchLocation/SearchLocationController'
import * as Styles from './SearchSection.styles'
import {
  initialSearchItemState,
  useSearchSectionController
} from './SearchSectionController'

export function SearchSection() {
  const searchController = useSearchSectionController()
  const searchLocationController = useSearchLocationController()
  const inputGroupRef = useRef(null)
  const inputRef = useRef(null)
  const inputLocationRef = useRef(null)
  const isMobile = useMobile()
  const { setCity } = useLocation()
  const [isABTestActive, setIsABTestActive] = useState(false)
  const vehicleSearchDataLayer = useHomeDataLayer('BuscaRealizadaHome')
  const locationSearchDataLayer = useHomeDataLayer('BuscaLocalizacaoHome')
  const vehicleCategoryDataLayer = useHomeDataLayer(
    formatVehicleCategoryDataLayer(searchController.vehicleCategory)
  )
  const [fetching, setFeching] = useState(false)
  const [searchTermDispatched, setSearchTermDispatched] = useState(false)
  const [inputValueDispatched, setInputValueDispatched] = useState(false)

  function handleInputFocus() {
    if (!isClientSide()) {
      return
    }
    let focusedElement: string | undefined

    if (document.activeElement === inputRef.current) {
      focusedElement = 'inputRef'
    } else if (document.activeElement === inputLocationRef.current) {
      focusedElement = 'inputLocationRef'
    }

    if (focusedElement) {
      focusedElement == 'inputRef'
        ? searchController.handleFocus()
        : searchLocationController.handleFocus()
      document.dispatchEvent(
        new CustomEvent('inputFocus', { detail: { focusedElement } })
      )
    }
  }

  function clearLocationSearch() {
    setCity(null)
    searchController.setOffersCount(0)
    setInputValueDispatched(false)
    searchLocationController.setInputValue('')
  }

  function clearVehicleSearch() {
    searchController.setSearchTerm('')
    searchController.setOffersCount(0)
    searchController.clearSearch()
    setSearchTermDispatched(false)
    searchController.setSearchItem({
      ...initialSearchItemState
    })
  }

  useClickAway(inputGroupRef, () => {
    const isMobileABTestActive = isABTestActive && isMobile

    if (isMobileABTestActive && !searchLocationController.searchTerm) {
      searchLocationController.clearSearch()
    }

    if (isMobileABTestActive && !searchController.inputValue) {
      searchController.clearSearch()
    }

    if (!isMobile && searchController.inputValue) {
      searchController.clearSearch()
    }
  })

  useEffect(() => {
    if (isClientSide()) {
      const hasKey =
        window.localStorage.getItem(Config.abTest.searchHome.key) === 'true' ||
        false

      setIsABTestActive(hasKey)
    }
  }, [])

  useEffect(() => {
    if (!searchTermDispatched && searchController.searchTerm.trim() !== '') {
      vehicleSearchDataLayer.handleHomeDataLayer()
      setSearchTermDispatched(true)
    }

    if (
      !inputValueDispatched &&
      searchLocationController.inputValue.trim() !== ''
    ) {
      locationSearchDataLayer.handleHomeDataLayer()
      setInputValueDispatched(true)
    }
  }, [searchController.searchTerm, searchLocationController.inputValue])

  useEffect(() => {
    if (
      searchController.searchTerm.trim() !== '' ||
      searchLocationController.inputValue.trim() !== ''
    ) {
      const getTotalWithCountInCity = async () => {
        await searchController.handleCityCount(
          searchController.searchItem,
          searchController.type
        )
      }

      getTotalWithCountInCity()
    }
  }, [searchController.searchTerm, searchLocationController.inputValue])

  useEffect(() => {
    if (fetching) return

    if (
      searchLocationController.inputValue.trim() === '' &&
      searchController.searchTerm.trim() === ''
    ) {
      setFeching(true)
      searchController.setFilteredOfferLink('')

      const getTotalWithCount = async () => {
        await searchController.handleLoadingCount()
        setFeching(false)
      }

      getTotalWithCount()
    }
  }, [
    searchController.searchTerm,
    searchLocationController.inputValue,
    searchController.searchType,
    searchController.vehicleCategory
  ])

  return (
    <Styles.Container>
      <Styles.Card>
        <Styles.CardHeader>
          <Styles.NavTab
            className={`${searchController.pickingCar && 'actived'}`}
            onClick={searchController.changeSearchSegment(
              SearchItemSegment.CAR
            )}
            data-testid="Tabs_Cars"
            data-qa="Tabs_Cars"
          >
            <h1>
              Comprar <span>carros</span>
            </h1>
          </Styles.NavTab>
          <Styles.NavTab
            className={`${searchController.pickingBike && 'actived'}`}
            onClick={searchController.changeSearchSegment(
              SearchItemSegment.BIKE
            )}
            data-testid="Tabs_Bikes"
            data-qa="Tabs_Bikes"
          >
            <h2>
              Comprar <span>motos</span>
            </h2>
          </Styles.NavTab>

          <Styles.NavLink
            data-qa="Sell_Link"
            target="_blank"
            href={searchController.sellLink}
          >
            <h2>Quero vender</h2>
          </Styles.NavLink>
          <Styles.NavLink
            data-qa="Financing_Link"
            target="_blank"
            href={searchController.financingLink}
          >
            <h2>Quero financiar</h2>
          </Styles.NavLink>
        </Styles.CardHeader>

        <ButtonGroup
          setVehicleCategory={searchController.setVehicleCategory}
          vehicleCategory={searchController.vehicleCategory}
          fetching={fetching}
          buttons={[
            { label: 'Todos', value: 'all' },
            { label: 'Novos', value: 'new' },
            { label: 'Usados', value: 'used' }
          ]}
        />

        <Styles.CardBody>
          {(isABTestActive || !searchController.isModalOpen) && (
            <>
              <Styles.InputGroup ref={inputGroupRef}>
                <Styles.IconPrefix>
                  <Search />
                </Styles.IconPrefix>

                <Styles.Input
                  ref={inputRef}
                  onChange={(e) =>
                    searchController.setInputValue(e.target.value)
                  }
                  placeholder={searchController.inputPlaceholder}
                  onFocus={handleInputFocus}
                  value={
                    isABTestActive && isMobile
                      ? transformToTitleCase(searchController.searchTerm)
                      : searchController.inputValue
                  }
                  data-testid="Input_Autocomplete"
                  data-qa="Input_Autocomplete"
                />
                {isABTestActive && isMobile && searchController.searchTerm && (
                  <Styles.IconClear onClick={clearVehicleSearch}>
                    <CloseOutlined />
                  </Styles.IconClear>
                )}

                {searchController.displayEmptyState && !isMobile && (
                  <Styles.Empty>
                    Não encontramos este termo, verifique a ortografia ou clique
                    em Ver ofertas
                  </Styles.Empty>
                )}

                {/* Start of results droplist */}
                {searchController.hasResults && !isMobile && (
                  <Styles.DropList>
                    {searchController.searchResult.brands.length > 0 && (
                      <Styles.DropHeader
                        data-testid="Autocomplete_DropHeader_Brand"
                        data-qa="Autocomplete_DropHeader_Brand"
                      >
                        Marcas
                      </Styles.DropHeader>
                    )}

                    {searchController.searchResult.brands.map((item) => (
                      <Styles.DropItem
                        href={searchController.mountLinkBrand(item)}
                        data-testid="Autocomplete_DropItem_Brand"
                        data-qa="Autocomplete_DropItem_Brand"
                        key={item.id}
                        dangerouslySetInnerHTML={{
                          __html: searchController.printWithHighlights(
                            item.name
                          )
                        }}
                      />
                    ))}

                    {searchController.searchResult.models.length > 0 && (
                      <Styles.DropHeader
                        data-testid="Autocomplete_DropHeader_Model"
                        data-qa="Autocomplete_DropHeader_Model"
                      >
                        Modelos
                      </Styles.DropHeader>
                    )}

                    {searchController.searchResult.models.map((item) => (
                      <Styles.DropItem
                        href={searchController.mountLinkModel(item)}
                        data-testid="Autocomplete_DropItem_Model"
                        data-qa="Autocomplete_DropItem_Model"
                        key={item.id}
                        dangerouslySetInnerHTML={{
                          __html: searchController.printWithHighlights(
                            item.name
                          )
                        }}
                      />
                    ))}
                  </Styles.DropList>
                )}
                {/* End of results droplist */}
              </Styles.InputGroup>

              {/* ***  A/B test  *** */}
              {isABTestActive && isMobile && (
                <>
                  <Styles.LocationInputGroup ref={inputGroupRef}>
                    <>
                      <Styles.IconPrefix>
                        <PlaceOutlined />
                      </Styles.IconPrefix>

                      <Styles.Input
                        ref={inputLocationRef}
                        onChange={(e) =>
                          searchLocationController.setInputValue(e.target.value)
                        }
                        placeholder="Digite seu estado ou cidade"
                        onFocus={handleInputFocus}
                        value={searchLocationController.inputValue}
                        data-testid="Input_Autocomplete"
                        data-qa="Input_Autocomplete"
                      />
                      {searchLocationController.inputValue && (
                        <Styles.IconClear onClick={clearLocationSearch}>
                          <CloseOutlined />
                        </Styles.IconClear>
                      )}
                    </>
                  </Styles.LocationInputGroup>
                </>
              )}
              {/**** End A/B test  ****/}
            </>
          )}
          <Styles.Button
            data-qa="btn_see_offers"
            href={
              searchController.filteredOfferLink
                ? searchController.filteredOfferLink
                : searchController.findOffersLink
            }
            onClick={() => vehicleCategoryDataLayer.handleHomeDataLayer()}
          >
            Ver ofertas{' '}
            {!(
              (isABTestActive &&
                isMobile &&
                searchLocationController.inputValue !== '') ||
              searchController.searchTerm !== ''
            ) &&
              searchController.offersCount > 0 &&
              `(${numberFormatService.humanReadableOutput(
                searchController.offersCount
              )})`}
          </Styles.Button>
        </Styles.CardBody>
      </Styles.Card>
    </Styles.Container>
  )
}
