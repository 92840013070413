import styled from 'styled-components'

export const Control = styled.button`
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 50%);
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  margin-top: -25px;
  background: #fff;
  display: none;
  opacity: 0.7;
  height: 30px;
  font-size: 0;
  width: 30px;
  z-index: 11;
  top: 50%;

  &.right-control {
    transform: rotate(180deg);
    right: 3px;
  }

  &.left-control {
    left: 3px;
  }

  &:hover {
    opacity: 1;
  }
`

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;

  &:hover ${Control} {
    display: flex;

    ${({ theme }) => theme.breakpoints.down('md')} {
      display: none;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-left: ${({ theme }) => theme.spacing(1)};
  }
`

export const Inner = styled.div`
  white-space: nowrap;
  overflow: hidden;
  padding: 0px;
  height: auto;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    overflow-x: scroll;
  }
`
