import { Environment } from '../Environment'
import { DefaultEnvironment } from './DefaultEnvironment'

export class LocalEnvironment
  extends DefaultEnvironment
  implements Environment
{
  /**
   * BaseUrl of Local requests
   *
   */
  baseUrl = 'https://hportal.webmotors.com.br'

  /**
   * PublicUrl of staging requests
   *
   */
  publicUrl = ''

  /**
   * BaseUrl used on server side rendering
   *
   */
  baseServerUrl = 'https://yur5i9svf7.execute-api.us-east-1.amazonaws.com/hml'

  /**
   * Auth Cookie Name on Local environment
   *
   */
  authCookieName = 'webmotors.jwt.token.hk'

  /**
   * Logging status
   *
   */
  logging = true

  /**
   * Cookie domain on hml environment
   *
   */
  cookieDomain = 'localhost'

  /**
   * Login route
   *
   */
  loginRoute = 'https://hportal.webmotors.com.br/login'

  /**
   *  Search
   *
   */
  abTest = {
    searchHome: {
      key: 'hml_ab_test_search'
    }
  }
}
