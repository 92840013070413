import { Button as DefaultButton } from 'presentation/components'
import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  background-color: #2e2d37f2;
  color: ${({ theme }) => theme.palette.common.white};
  z-index: ${({ theme }) => theme.zIndex.appBar};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(2.5)}`};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  line-height: 1.5;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
  }
`

export const Main = styled.main`
  min-width: auto;
  & p {
    margin-bottom: 0;
  }
  & p:first-child {
    margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  }

  & a,
  button {
    font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  }
`

export const Link = styled.a`
  color: #ff859c;
`

export const BtnUderstood = styled(DefaultButton)`
  margin-left: 80px;
  padding: ${({ theme }) => `${theme.spacing(1.25)} ${theme.spacing(4)}`};
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 25px;
    margin-left: 0;
    width: 100%;
  }
`
