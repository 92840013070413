import { authService, userService } from 'application'
import { User } from 'domain/User'
import { useCallback } from 'react'
import { createGlobalState } from 'react-use'

const useGlobalUser = createGlobalState<User | null>(
  userService.getCurrentUser()
)

export function useAuth() {
  const [user, setUser] = useGlobalUser()

  /**
   * Log current user out
   *
   */
  const logout = useCallback(async () => {
    if (!user) return

    await authService.logout()
    setUser(null)
  }, [user])

  return {
    logout,
    user
  }
}
