import { useEffect, useMemo, useState } from 'react'
import { isClientSide } from '../use-is-client-side'

function useIsInViewportServerSide() {
  return true
}

function useIsInViewportClientSide(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        setIsIntersecting((isIntersecting) => {
          if (isIntersecting || !isClientSide()) {
            return true
          }
          return entry.isIntersecting
        })
      }),
    []
  )

  useEffect(() => {
    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref, observer])

  return isIntersecting
}

export const useIsInViewport = isClientSide()
  ? useIsInViewportClientSide
  : useIsInViewportServerSide
