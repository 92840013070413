import styled from 'styled-components'
import { Props } from './ProductWebCard.types'

export const ProductWebCard = styled.a<Props>`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background: ${({ theme }) => theme.palette.brand[600]};
  position: relative;
  height: 86px;
  display: flex;

  h3,
  p {
    white-space: normal;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.palette.common.white};
    padding: 16px;
    font-size: ${({ theme }) => theme.typography.subtitle1.fontSize};
    font-weight: 400;
    line-height: 1.5rem;
    margin-bottom: 0;
    @media screen and (min-width: 360px) {
      font-weight: 500;
    }
  }
`
