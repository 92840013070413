import { Config } from 'application'
import { LocalClient } from 'domain/LocalClient'

/**
 * DataFormService class
 * Provides methods for managing data forms.
 */
export class DataFormService {
  /**
   * Constructor
   * Initializes a new instance of the DataFormService class.
   *
   * @param {LocalClient} localClient - The local client to use for storing data form cookies.
   */
  constructor(private localClient: LocalClient) {}

  /**
   * Get data form cookie
   * Gets the data form cookie from local storage.
   *
   * @returns {string} - The encrypted data from cookie, or empty string if it doesn't exist.
   */
  getDataFormCookie(): string {
    return this.localClient.getItem<string>(Config.cookieDataForm) ?? ''
  }
}
