import { useMediaQuery } from '@mui/material'
import range from 'lodash/range'
import { Box, ScrollItem, ScrollView } from 'presentation/components'
import { useMemo } from 'react'
import { useTheme } from 'styled-components'
import { BestSellerCard } from '../../Cards'
import { useBestSellerController } from './BestSellerController'
import * as S from './BestSellers.styles'

export function BestSellers() {
  const { data, loading, mountLink, isBike } = useBestSellerController()
  const theme = useTheme()
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('lg'))

  const perPage = useMemo(() => {
    if (isExtraLarge) {
      return 6
    }
    return 5
  }, [isExtraLarge])

  if (isBike) {
    return null
  }

  return (
    <S.Container>
      <Box mt={4} mb={4}>
        <S.Typography variant="h2" color="textSecondary" mb={2}>
          Carros mais buscados
        </S.Typography>
        {loading && (
          <ScrollView perPage={perPage}>
            {range(0, 6).map((index) => (
              <ScrollItem key={index} data-testid="Card_Skeleton" size="290px">
                <S.Skeleton variant="rectangular" height={224} />
              </ScrollItem>
            ))}
          </ScrollView>
        )}

        <ScrollView perPage={perPage}>
          {data?.map((bestsellerItem, index) => (
            <ScrollItem key={bestsellerItem.order} size="190px">
              <BestSellerCard
                make={bestsellerItem.make}
                model={bestsellerItem.model}
                order={bestsellerItem.order}
                url={mountLink(bestsellerItem, index)}
              />
            </ScrollItem>
          ))}
        </ScrollView>
      </Box>
    </S.Container>
  )
}
