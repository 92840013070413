import { Config } from 'application'
import { Picture } from 'presentation/components'
import * as S from './BestSellerCard.styles'

export function BestSellerCard({ make, model, order, url }) {
  const lowerCaseMake = make.toLowerCase().replace('-', '_')
  const lowerCaseModel = model.toLowerCase().replace('-', '_')
  const imageIndex = `${lowerCaseMake}-${lowerCaseModel}`

  const imageSrcs = {
    mainSrcSet: `${Config.publicUrl}/assets/bestsellers/jpg/${imageIndex}.jpg`,
    sources: [
      {
        srcSet: `${Config.publicUrl}/assets/bestsellers/webp/${imageIndex}.webp`,
        type: 'image/webp'
      },
      {
        srcSet: `${Config.publicUrl}/assets/bestsellers/jpg/${imageIndex}.jpg`,
        type: 'image/jpeg'
      }
    ]
  }
  return (
    <S.BestsellerCard
      id={`CardBestSeller-${order}`}
      href={url}
      draggable="false"
      data-qa={`icon_bestSellers_card${order}`}
      data-testid="BestSellerCard"
    >
      <S.BestsellerTitle>
        {lowerCaseMake}
        <strong>{lowerCaseModel}</strong>
      </S.BestsellerTitle>
      <Picture
        data={imageSrcs}
        loading="lazy"
        height="121"
        width="202"
        alt={`imagem de ${lowerCaseMake} ${lowerCaseModel}`}
      />
    </S.BestsellerCard>
  )
}
