import { loggerClient } from 'application/Services'
import React, { ReactNode } from 'react'

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
}

const logger = loggerClient.create('ErrorBoundary')

export class ErrorBoundary extends React.Component<Props, State> {
  /**
   * ErrorBoundary constructor
   *
   * @param props
   */
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  /**
   * getDerivedStateFromError
   *
   * @returns
   */
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  /**
   * componentDidCatch
   *
   * @param error
   * @param errorInfo
   */
  componentDidCatch(error, errorInfo) {
    console.log(logger.error)

    logger.error(error, errorInfo)
  }

  /**
   * render
   *
   * @returns
   */
  render() {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}
