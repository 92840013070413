import { useEffect, useState } from 'react'
import * as S from './ButtonGroup.styles'

export function ButtonGroup({
  buttons,
  vehicleCategory,
  setVehicleCategory,
  fetching
}) {
  const [activeButton, setActiveButton] = useState('all')

  const handleButtonClick = (buttonValue) => {
    setActiveButton(buttonValue)
    setVehicleCategory(buttonValue)
  }

  useEffect(() => {
    setActiveButton(vehicleCategory)
  }, [vehicleCategory])

  return (
    <S.ButtonGroupContainer>
      {buttons.map((button) => (
        <S.Button
          disabled={fetching}
          data-qa={button.value}
          key={button.value}
          active={activeButton === button.value}
          onClick={() => handleButtonClick(button.value)}
        >
          {button.label}
        </S.Button>
      ))}
    </S.ButtonGroupContainer>
  )
}
