import window from 'global/window'
import { useContext, useEffect, useMemo, useRef } from 'react'
import * as Styles from './ScrollItem.styles'
import { ScrollViewContext } from './ScrollViewContext'

export function ScrollItem({
  children,
  dynamic = false,
  display = true,
  size = '',
  ...props
}) {
  const { perPage, maxHeight, setSlides } = useContext(ScrollViewContext)
  const ref = useRef<HTMLDivElement>(null)
  const index = useRef<number>(0)

  /**
   * Current slide height
   *
   */
  const height = useMemo(() => {
    if (maxHeight) {
      return maxHeight
    }

    return ref.current?.clientHeight
  }, [maxHeight])

  /**
   * Get current slide width
   *
   */
  const width = useMemo(() => {
    return (100 / perPage).toFixed(2)
  }, [perPage])

  /**
   * Register new slide into context
   *
   */
  useEffect(() => {
    function handleResize() {
      setSlides((slides) => {
        slides[index.current].height = ref.current?.clientHeight || 0

        return [...slides]
      })
    }

    setSlides((slides) => {
      index.current = slides.length + 1

      return [
        ...slides,
        {
          index: slides.length + 1,
          height: ref.current?.clientHeight || 0,
          display
        }
      ]
    })

    window.addEventListener('listener', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
      setSlides((slides) => [
        ...slides.filter((slide) => slide.index !== index.current)
      ])
    }
  }, [])

  useEffect(() => {
    if (!dynamic) {
      return
    }

    setSlides((slides) => {
      const slide = { ...slides[index.current] }

      slide.display = display

      slides[index.current] = slide

      return [...slides]
    })
  }, [display])

  return (
    <Styles.Container
      className={display ? 'visible' : 'hidden'}
      data-testid={`Card_${index.current}`}
      size={size ? size : `${width}%`}
      height={height}
      width={width}
      ref={ref}
      {...props}
    >
      {children}
    </Styles.Container>
  )
}
