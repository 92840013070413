import { stringFormatService, uriService } from 'application/Services'
import { Favorite } from 'domain/Favorite'
import { FakableClass, FakableField } from 'fake-class'
import { Faker } from 'faker'
import { SearchItemSegment } from './SearchItem'
import { Stamps } from './Stamps'

@FakableClass()
export class Offer {
  /**
   * Offer id
   *
   */
  @FakableField(() => Faker.number())
  id: number

  /**
   * Offer media
   *
   */
  @FakableField(() => Faker.string(10))
  make: string

  /**
   * Offer model
   *
   */
  @FakableField(() => Faker.string(10))
  model: string

  /**
   * Offer specification
   *
   */
  @FakableField(() => Faker.string(10))
  version?: string

  /**
   * Offer price
   *
   */
  @FakableField(() => Faker.string(10))
  price: string

  /**
   * Offer seller
   *
   */
  @FakableField(() => '2022')
  year: string

  /**
   * Offer travelledDistance
   *
   */
  @FakableField(() => `10.000km`)
  travelledDistance: string

  /**
   * Offer location
   *
   */
  @FakableField(() => `${Faker.cityName()} - ${Faker.stateAbbr()}`)
  location: string

  /**
   * Offer wasSold
   *
   */
  @FakableField(() => false)
  wasSold: boolean

  /**
   * Offer attributes
   *
   */
  attributes: string[] = []

  /**
   * Offer seller
   *
   */
  seller: string

  /**
   * Offer photos
   *
   */
  photos: string[] = []

  /**
   * Offer wasFavorited
   *
   */
  @FakableField(() => true)
  wasFavorited: boolean

  /**
   * Offer type
   *
   */
  @FakableField(() => SearchItemSegment.CAR)
  type: string

  /**
   * Offer exceededLimit
   *
   */
  exceededLimit: boolean

  /**
   * Offer CubicCentimeter
   *
   */
  @FakableField(() => Faker.number())
  cubicCentimeter: number

  /**
   * Offer stamps
   *
   */
  stamps: Stamps

  /**
   * Offer segment
   *
   */
  @FakableField(() => SearchItemSegment.CAR)
  segment: SearchItemSegment

  /**
   * Offer favorite
   *
   */
  favorite: Favorite

  /**
   * Offer constructor
   *
   */
  constructor(params: Partial<Offer>) {
    Object.assign(this, params)
  }

  /**
   * Get offer url
   
   * @returns 
   */
  getUrl(lkid?: string): string {
    const slugParams = {
      remove: '.',
      lower: true
    }

    const paths: string[] = ['comprar']

    const make = stringFormatService.toSlug(this.make, slugParams)
    paths.push(make)

    const model = stringFormatService.toSlug(this.model, slugParams)
    paths.push(model)

    if (this.cubicCentimeter) {
      paths.push(`${this.cubicCentimeter}cc`)
    }

    if (this.version) {
      const version = stringFormatService.toSlug(this.version, slugParams)
      const ports = this.version.includes('4P') ? '4-portas' : '2-portas'

      paths.push(version, ports)
    }

    const year = this.year.replace('/', '-')
    paths.push(year, this.id.toString())

    const path = paths.join('/')

    const params = lkid ? `?lkid=${lkid}` : ''

    return uriService.createUrl(`/${path}${params}`)
  }
}
