import {
  Container as DefaultContainer,
  Typography as DefaultTypography
} from 'presentation/components'
import styled from 'styled-components'

export const Typography = styled(DefaultTypography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 90%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-left: ${({ theme }) => theme.spacing(3)};
  }
`

export const Container = styled(DefaultContainer)`
  margin-bottom: 32px;
  min-height: 260px;

  &.is-empty {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    min-height: 0px;
    padding: 0;
  }
`
export const Item = styled.div`
  border-radius: 8px;
  position: relative;
  background: white;
  overflow: hidden;
  height: 200px;
  width: 100%;

  &.is-empty {
    display: none;
  }

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 150px;

    iframe {
      position: absolute;
    }
  }
`
