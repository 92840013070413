import { Config } from 'application'
import { platform } from 'application/Services'
import { City, State } from 'domain/Location'
import { SearchItem } from 'domain/Search/Models/SearchItem'
import { useLocation } from 'presentation/hooks'
import { isClientSide } from 'presentation/hooks/use-is-client-side'
import { useEffect, useRef, useState } from 'react'
import { useSearchLocationController } from '../SearchLocation/SearchLocationController'
import { useSearchSectionController } from './SearchSectionController'
import * as Styles from './SearchSectionMobile.styles'

export function SearchSectionMobile({ isOpen = false }) {
  const { setCity } = useLocation()
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const searchController = useSearchSectionController()
  const [isFocused, setIsFocused] = useState<string>('')
  const inputLocationRef = useRef<HTMLInputElement>(null)
  const searchLocationController = useSearchLocationController()
  const [isABTestActive, setIsABTestActive] = useState<boolean>(true)

  function handleController(controller) {
    controller.clearSearch()
    controller.close()
  }

  function handleBlur() {
    if (!searchController.hasSearch) {
      searchController.clearSearch()
      searchController.close()
    }
  }

  function handleSelectState(item) {
    setCity(
      () =>
        new City({
          name: '',
          latitude: undefined,
          longitude: undefined,
          range: undefined,
          state: new State({
            name: item.name,
            abbr: item.abbr
          })
        })
    )
    searchLocationController.setInputValue(item.name)
    handleController(searchLocationController)
  }

  function handleSelectCity(item) {
    setCity(item)
    searchLocationController.setInputValue(`${item.name} - ${item.state.abbr}`)
    handleController(searchLocationController)
  }

  function handleSelectVehicle(item: SearchItem, type: string) {
    searchController.setType(type)
    searchController.setSearchItem({ ...item })
    searchController.setSearchTerm(item.name)
    handleController(searchController)
  }

  function handleClickOutside(e) {
    if (e.target !== containerRef.current) {
      return
    }

    if (isABTestActive && isFocused == 'inputLocationRef') {
      handleController(searchLocationController)
    } else if (isFocused == 'inputRef') {
      handleController(searchController)
    }
  }

  useEffect(() => {
    if (searchController.isModalOpen) {
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
      document.body.style.top = '0px'
      document.body.style.bottom = '0px'
      document.body.style.right = '0px'
      document.body.style.left = '0px'

      isABTestActive && isFocused === 'inputLocationRef'
        ? inputLocationRef.current?.focus()
        : inputRef.current?.focus()
    } else {
      document.body.style.overflow = ''
      document.body.style.position = ''
    }
  }, [searchController.isModalOpen, searchLocationController.isModalOpen])

  useEffect(() => {
    if (!isClientSide()) {
      return
    }
    const handleInputAbTestFocus = (event: Event) => {
      const customEvent = event as CustomEvent
      setIsFocused(customEvent.detail.focusedElement)
    }
    document.addEventListener('inputFocus', handleInputAbTestFocus)

    return () => {
      document.removeEventListener('inputFocus', handleInputAbTestFocus)
    }
  }, [])

  useEffect(() => {
    if (isClientSide()) {
      const hasKey =
        window.localStorage.getItem(Config.abTest.searchHome.key) === 'true' ||
        false
      setIsABTestActive(hasKey)
    }
  }, [])

  if (!searchController.isModalOpen && !isOpen) {
    return null
  }

  return (
    <Styles.Container ref={containerRef} onClick={handleClickOutside}>
      <>
        {isFocused == 'inputRef' && (
          <Styles.Input
            onChange={(e) => searchController.setInputValue(e.target.value)}
            placeholder={searchController.inputPlaceholder}
            value={searchController.inputValue}
            data-testid="Input_Autocomplete"
            data-qa="Input_Autocomplete"
            onBlur={() => isABTestActive && handleBlur}
            ref={inputRef}
          />
        )}

        {/* ***  A/B test  *** */}
        {isABTestActive && isFocused == 'inputLocationRef' && (
          <Styles.Input
            onChange={(e) =>
              searchLocationController.setSearchTerm(e.target.value)
            }
            placeholder="Digite o estado ou cidade"
            value={searchLocationController.searchTerm}
            data-testid="Input_Autocomplete"
            data-qa="Input_Autocomplete"
            ref={inputLocationRef}
          />
        )}
        {/**** End A/B test  ****/}
      </>

      <>
        {searchController.displayEmptyState && (
          <Styles.Empty>
            Não encontramos este termo, verifique a ortografia ou clique em Ver
            ofertas
          </Styles.Empty>
        )}

        {/****  A/B test  ****/}
        {searchLocationController.displayEmptyLocations && (
          <Styles.Empty>
            Não encontramos este termo, verifique a ortografia ou clique em Ver
            ofertas
          </Styles.Empty>
        )}
      </>
      {/* *** End A/B test ****/}

      {/* Start of results droplist */}
      {searchController.hasResults && (
        <Styles.List className={platform.iOS() ? 'is-ios' : 'is-android'}>
          {searchController.searchResult.brands.length > 0 && (
            <Styles.Header
              data-testid="Autocomplete_DropHeader_Brand"
              data-qa="Autocomplete_DropHeader_Brand"
            >
              Marcas
            </Styles.Header>
          )}

          {searchController.searchResult.brands.map((item) => (
            <Styles.Item
              href={
                !isABTestActive
                  ? searchController.mountLinkBrand(item)
                  : undefined
              }
              onClick={
                isABTestActive
                  ? () => handleSelectVehicle(item, 'brand')
                  : undefined
              }
              data-testid="Autocomplete_DropItem_Brand"
              data-qa="Autocomplete_DropItem_Brand"
              key={item.id}
              dangerouslySetInnerHTML={{
                __html: searchController.printWithHighlights(item.name)
              }}
            />
          ))}

          {searchController.searchResult.models.length > 0 && (
            <Styles.Header
              data-testid="Autocomplete_DropHeader_Model"
              data-qa="Autocomplete_DropHeader_Model"
            >
              Modelos
            </Styles.Header>
          )}

          {searchController.searchResult.models.map((item) => (
            <Styles.Item
              href={
                !isABTestActive
                  ? searchController.mountLinkModel(item)
                  : undefined
              }
              onClick={
                isABTestActive
                  ? () => handleSelectVehicle(item, 'model')
                  : undefined
              }
              data-testid="Autocomplete_DropItem_Model"
              data-qa="Autocomplete_DropItem_Model"
              key={item.id}
              dangerouslySetInnerHTML={{
                __html: searchController.printWithHighlights(item.name)
              }}
            />
          ))}
        </Styles.List>
      )}
      {/* End of results droplist */}

      {/* ****  A/B test  ****/}
      {searchLocationController.hasLocationsResults && (
        <Styles.List className={platform.iOS() ? 'is-ios' : 'is-android'}>
          {searchLocationController.locationsResult.states.length > 0 && (
            <Styles.Header
              data-testid="Autocomplete_DropHeader_State"
              data-qa="Autocomplete_DropHeader_State"
            >
              Estados
            </Styles.Header>
          )}

          {searchLocationController.locationsResult.states.map((item) => (
            <Styles.Item
              onClick={() => handleSelectState(item)}
              data-testid="Autocomplete_DropItem_State"
              data-qa="Autocomplete_DropItem_State"
              key={item.name}
              dangerouslySetInnerHTML={{
                __html: searchLocationController.printWithHighlights(item.name)
              }}
            />
          ))}

          {searchLocationController.locationsResult.cities.length > 0 && (
            <Styles.Header
              data-testid="Autocomplete_DropHeader_City"
              data-qa="Autocomplete_DropHeader_City"
            >
              Cidades
            </Styles.Header>
          )}

          {searchLocationController.locationsResult.cities.map((item) => (
            <Styles.Item
              onClick={() => handleSelectCity(item)}
              data-testid="Autocomplete_DropItem_City"
              data-qa="Autocomplete_DropItem_City"
              key={item.name}
              dangerouslySetInnerHTML={{
                __html: searchLocationController.printWithHighlights(
                  `${item.name} - ${item.state.abbr}`
                )
              }}
            />
          ))}
        </Styles.List>
      )}
      {/* *** End A/B test ****/}
    </Styles.Container>
  )
}
