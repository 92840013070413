import blackFairImg from 'presentation/assets/img/abaixo-da-tabela.webp'
import * as S from './BlackFairContent.styles'

export function BlackFairContent({ appUrl, handleClose, handleCTAClick }) {
  return (
    <S.Container className="modal_black_fair">
      <S.Content>
        <S.Box className="close_modal_login" onClick={handleClose}>
          <S.Icon icon="SLIM_CLOSE" />
        </S.Box>
        <img
          src={blackFairImg}
          alt="Ofertas relâmpago abaixo da FIPE - Black Feirão"
          width={230}
          height={104}
        />
        <S.Typography variant="h4">
          Aproveite ofertas imperdíveis de carros e motos! Baixe já o App.
        </S.Typography>
        <S.Typography className="conditions" variant="body2">
          Condições válidas até 30/11/2024. Crédito sujeito a análise e<br />{' '}
          condições vigentes no momento da contratação. Taxa especial para
          financiamento exclusivo com o Santander Financiamentos. Consulte
          termos e condições em webmotors.com.br/feiroes
        </S.Typography>
        <S.Button
          href={appUrl}
          formTarget="_blank"
          id="btn_to_app"
          variant="secondary"
          onClick={handleCTAClick}
        >
          Quero aproveitar!
        </S.Button>
      </S.Content>
    </S.Container>
  )
}
