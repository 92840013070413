import { FakableClass, FakableField } from 'fake-class'
import { Faker } from 'faker'

/**
 * Represents a user in the Insider system.
 */
@FakableClass()
export class InsiderUser {
  /**
   * The name of the user.
   */
  @FakableField(() => Faker.string(10))
  name?: string

  /**
   * The email address of the user.
   */
  @FakableField(() => Faker.email())
  email?: string

  /**
   * The phone number of the user.
   */
  @FakableField(() => Faker.phoneNumber())
  phoneNumber?: string

  /**
   * Whether the user has opted into GDPR compliance.
   */
  @FakableField(() => null)
  gdprOptin: boolean | null

  /**
   * Additional custom properties for the user.
   */
  custom?: {
    [key: string]: any
  }

  /**
   * Initializes a new instance of the InsiderUser class.
   *
   * @param {Partial<InsiderUser>} params - The parameters to assign to this instance.
   */
  constructor(params: Partial<InsiderUser>) {
    this.name = params?.name ?? ''
    this.email = params?.email ?? ''
    this.phoneNumber = params?.phoneNumber ?? ''
    this.gdprOptin = params?.gdprOptin ?? null
    this.custom = params?.custom ?? {}
  }
}
