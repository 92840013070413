import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { hydrate, QueryClientProvider } from '@tanstack/react-query'
import { Home } from 'presentation'
import { useIsBlackFairActive } from './hooks'
import { queryClient } from './utils'

function App({ pageData = {} }) {
  const { currentTheme } = useIsBlackFairActive()

  hydrate(queryClient, pageData)

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <Home />
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
