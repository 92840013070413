import { loggerClient } from 'application/Services'

const logger = loggerClient.create('SlotService')

export class Slot {
  /**
   * Slot ad unit
   *
   */
  adUnit: string

  /**
   * Slot html id
   *
   */
  htmlId: string

  /**
   * Slot target
   *
   */
  target: googletag.Slot

  /**
   * Slot targets
   *
   */
  targets?: [string, string]

  /**
   * Slot constructor method
   *
   * @param params
   */
  constructor(params: Partial<Slot>) {
    Object.assign(this, params)

    const targetSlot = googletag.defineOutOfPageSlot
      ? googletag.defineOutOfPageSlot(this.adUnit, this.htmlId)
      : null
    if (targetSlot) {
      if (this.targets) {
        logger.info(
          `[createSlot] add targets to slog ${this.htmlId}:`,
          this.targets
        )
        targetSlot.setTargeting(...this.targets)
      }
      targetSlot.addService(googletag.pubads())

      this.target = targetSlot
    }
  }
}
