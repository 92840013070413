import useMediaQuery from '@material-ui/core/useMediaQuery'
import { offerService } from 'application'
import { Box, Container, ScrollItem, ScrollView } from 'presentation/components'
import { useMobile } from 'presentation/hooks'
import { useTheme } from 'styled-components'

import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { VehicleCard } from '../../Cards'
import { Typography } from './Recommended.styles'

export function Recommended() {
  const { data } = useQuery({
    queryKey: ['recommended'],
    queryFn: () => offerService.getAllRecommended()
  })
  const theme = useTheme()
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const isMobile = useMobile()

  const offers = useMemo(() => {
    if (!data || data?.length === 0) {
      return null
    }

    if (isMobile) {
      return data.slice(0, 3)
    }

    return data
  }, [isMobile, data])

  const perPage = useMemo(() => {
    if (isExtraLarge) {
      return 5
    }
    return 4
  }, [isExtraLarge])

  if (!offers) {
    return null
  }

  return (
    <Container data-qa="Recommended_Container">
      <Box mt={4} mb={4}>
        <Typography
          data-testid="Recommended_Title"
          variant="h2"
          color="textSecondary"
          mb={2}
        >
          Recomendados para você
        </Typography>
        {isMobile &&
          offers.map((offer, index) => (
            <VehicleCard
              data-testid="Recommended_Vehicle_Card"
              key={offer.id}
              offer={offer}
              data-qa={`recommended_vehicle_card_${index + 1}`}
            />
          ))}

        {!isMobile && (
          <ScrollView perPage={perPage}>
            {offers.map((offer, index) => (
              <ScrollItem key={offer.id} size="250px">
                <VehicleCard
                  data-testid="Recommended_Vehicle_Card"
                  offer={offer}
                  data-qa={`recommended_vehicle_card_${index + 1}`}
                />
              </ScrollItem>
            ))}
          </ScrollView>
        )}
      </Box>
    </Container>
  )
}
