import styled from 'styled-components'

export const Container = styled.a`
  grid-template-rows: 172px auto 50px;
  border-radius: 8px;
  min-height: 385px;
  background: #fff;
  overflow: hidden;
  display: grid;
  height: 100%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: 45% 55%;
    grid-template-rows: auto 25px;
    min-height: 134px;
    margin-top: 12px;
    width: 100%;
  }
`

export const Image = styled.div<{ source: string }>`
  background-image: url(${({ source }) => source});
  background-position: center;
  background-color: #aeaeba;
  background-size: cover;
  height: 100%;
  width: 100%;
`

export const Header = styled.div`
  position: relative;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-row: span 2;
  }
`

export const Banner = styled.div<{ backgroundImage: string }>`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: center;
  background-size: contain;
  margin-top: 3px;
  height: 17px;
  width: 96px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    position: absolute;
    margin-left: -45px;
    height: 16px;
    width: 90px;
    bottom: 0px;
    left: 50%;
  }
`

export const Body = styled.div`
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  padding: 4px 15px;
  display: flex;
`

export const BodySection = styled.div``

export const Footer = styled.div`
  border-top: 1px solid #ecedf2;
  padding: 14px 18px;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.down('md')} {
    line-height: 13px;
    padding: 5px 12px;
    font-size: 9px;
  }
`

export const Price = styled.div`
  line-height: 150%;
  font-weight: 500;
  font-size: 20px;
  color: #696977;

  ${({ theme }) => theme.breakpoints.down('md')} {
    line-height: 25px;
    font-size: 14px;
    color: #2e2d37;
  }
`

export const Title = styled.h3`
  max-width: calc(100% - 44px);
  text-transform: uppercase;
  white-space: break-spaces;
  line-height: 18px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 12px;
  color: #2e2d37;

  ${({ theme }) => theme.breakpoints.down('md')} {
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 12px;
    overflow: hidden;
    font-size: 10px;
  }
`

export const Version = styled.h3`
  text-transform: uppercase;
  white-space: break-spaces;
  line-height: 18px;
  font-weight: 500;
  font-size: 12px;
  color: #aeaeba;

  ${({ theme }) => theme.breakpoints.down('md')} {
    line-height: 12px;
    font-size: 10px;
  }
`

export const DataGroup = styled.div`
  justify-content: space-between;
  max-width: 100%;
  display: flex;
`

export const DataLabel = styled.div`
  text-overflow: ellipsis;
  align-items: center;
  white-space: nowrap;
  line-height: 18px;
  font-weight: 500;
  overflow: hidden;
  font-size: 12px;
  color: #aeaeba;
  display: flex;

  &.full-line {
    max-width: calc(100% - 25px);
    display: block;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    line-height: 12px;
    font-size: 10px;
  }
`

export const TagList = styled.ul`
  position: absolute;
  right: 5px;
  top: 0;
`

export const TagItem = styled.li<{ backgroundImage: string }>`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: center;
  background-size: cover;
  display: inline-block;
  margin: 0px 2px;
  height: 25px;
  width: 14px;
`

export const ButtonFavorite = styled.button``
