import { Config } from 'application/Config'
import { loggerClient } from 'application/Services'
import {
  HttpDeleteClient,
  HttpDeleteConfig,
  HttpGetClient,
  HttpGetConfig,
  HttpPostClient,
  HttpPostConfig
} from 'domain/HttpClient'
import { NoResponseError } from './Errors/NoResponseError'
import { ServerError } from './Errors/ServerError'

const logger = loggerClient.create('FetchClient')

export class AxiosClient
  implements HttpGetClient, HttpPostClient, HttpDeleteClient
{
  private baseURL: string

  constructor() {
    this.baseURL = `${Config.baseUrl}${Config.apiPrefix}`

    if (Config.isServerSide) {
      this.baseURL = `${Config.baseServerUrl}${Config.apiPrefix}`
    }

    if (logger) {
      logger.info(`[constructor] using base url: ${this.baseURL}`)
    }
  }

  private async fetchRequest<T>(url: string, options: RequestInit): Promise<T> {
    logger.info('[fetchRequest]', url, options)

    try {
      const response = await fetch(url, options)
      if (!response.ok) {
        const errorData = await response.json()
        throw new ServerError(errorData)
      }
      return response.json()
    } catch (error: any) {
      if (error instanceof ServerError) {
        throw error
      }
      if (!error?.response) {
        throw new NoResponseError('No response received')
      }
      throw error
    }
  }

  async get<T>(url: string, config?: HttpGetConfig): Promise<T> {
    const headers = new Headers(config?.headers)
    const params = new URLSearchParams(config?.queryParams as any)
    const fullUrl = `${this.baseURL}${url}?${params}`

    const options: RequestInit = {
      method: 'GET',
      headers,
      ...config
    }

    return this.fetchRequest<T>(fullUrl, options)
  }

  async post<Request, Response>(
    url: string,
    data: Request,
    config?: HttpPostConfig
  ): Promise<Response> {
    const headers = new Headers({
      'Content-Type': 'application/json',
      ...config?.headers
    })
    const fullUrl = `${this.baseURL}${url}`

    const options: RequestInit = {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
      ...config
    }

    return this.fetchRequest<Response>(fullUrl, options)
  }

  async delete<Request, Response>(
    url: string,
    config?: HttpDeleteConfig<Request>
  ): Promise<Response> {
    const headers = new Headers(config?.headers)
    const fullUrl = `${this.baseURL}${url}`

    const options: RequestInit = {
      method: 'DELETE',
      headers,
      body: JSON.stringify(config?.data),
      ...config
    }

    return this.fetchRequest<Response>(fullUrl, options)
  }
}
