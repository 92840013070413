import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React, {
  Dispatch,
  ReactFragment,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo
} from 'react'

type AccordionContextProps = {
  activeItem: string | boolean
  setActiveItem: Dispatch<SetStateAction<string | false>>
}

export const AccordionContext = createContext({} as AccordionContextProps)

export const AccordionGroup = ({
  activeAccordion = false,
  children
}: {
  activeAccordion?: string | false
  children: ReactNode
}) => {
  const [activeItem, setActiveItem] = React.useState<string | false>(
    activeAccordion
  )

  const isActive = useMemo(
    () => ({ activeItem, setActiveItem }),
    [activeItem, setActiveItem]
  )

  return (
    <AccordionContext.Provider value={isActive}>
      {children}
    </AccordionContext.Provider>
  )
}

AccordionGroup.Item = function Item({
  children,
  id
}: {
  children: ReactFragment
  id: string
}) {
  const { setActiveItem } = useContext(AccordionContext)
  const { activeItem } = useContext(AccordionContext)
  const currentActiveItem = activeItem === `accordion-panel-${id}`
  return (
    <Accordion
      expanded={currentActiveItem}
      onChange={() =>
        setActiveItem(currentActiveItem ? false : `accordion-panel-${id}`)
      }
      id={id}
    >
      {children}
    </Accordion>
  )
}

AccordionGroup.Header = function Header({
  children,
  expandIcon,
  id
}: {
  children: ReactNode
  expandIcon: ReactNode
  id: string
}) {
  return (
    <AccordionSummary expandIcon={expandIcon} id={id}>
      {children}
    </AccordionSummary>
  )
}

AccordionGroup.Content = function Content({
  children
}: {
  children: ReactNode
}) {
  return <AccordionDetails>{children}</AccordionDetails>
}
