import { useQuery } from '@tanstack/react-query'
import { bestSellerService } from 'application'
import { BestSeller } from 'domain/BestSeller'
import { SearchItemSegment } from 'domain/Search/Models/SearchItem'
import { ViewPortContext } from 'presentation/components'
import { useLocation, useVehicleType } from 'presentation/hooks'
import { cleanString, createUrl, queryClient } from 'presentation/utils'
import { useContext, useEffect, useMemo } from 'react'

export function useBestSellerController() {
  const { vehicleType } = useVehicleType()
  const { city } = useLocation()
  const { data, isLoading } = useQuery({
    queryKey: ['best_sellers', city],
    queryFn: () => {
      return bestSellerService.getAll(city?.state?.abbr?.toLowerCase())
    }
  })
  const { setShow } = useContext(ViewPortContext)

  /**
   * Indicates if vehicle type is bike
   *
   */
  const isBike = useMemo(() => {
    return vehicleType === SearchItemSegment.BIKE
  }, [vehicleType])

  /**
   * Mount location withoutAccentuation
   *
   */
  const locationWithoutAccentuation = useMemo(() => {
    return city ? city.toUrlString() : 'estoque'
  }, [city])

  /**
   * Mount location withAccentuation
   *
   */
  const locationWithAccentuation = useMemo(() => {
    return city ? city.toReadableString() : 'estoque'
  }, [city])

  function mountLink(item: BestSeller, index: number): string {
    const _make = cleanString(item.make, {
      removeAccents: true,
      removeSpecialChars: true,
      toLowerCase: true,
      replaceSpace: '-'
    })
    const _model = cleanString(item.model, {
      removeAccents: true,
      removeSpecialChars: true,
      toLowerCase: true,
      replaceSpace: '-'
    })

    const params = {
      estadocidade: locationWithAccentuation,
      marca1: _make.toUpperCase(),
      modelo1: _model.toUpperCase(),
      lkid: index + 1038
    }

    if (city?.longitude && city?.latitude) {
      params['localizacao'] = `${city.latitude},${city.longitude}x100km`
    }

    return createUrl(
      `/carros/${locationWithoutAccentuation}/${_make}/${_model}`,
      {
        params
      }
    )
  }

  /**
   * Handle query invalidation on city change
   *
   */
  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['best_sellers', city]
    })
  }, [city])

  /**
   * Handle view port control when is bike
   *
   */
  useEffect(() => {
    if (setShow) {
      setShow(!isBike)
    }
  }, [isBike])

  return { data, loading: isLoading, vehicleType, mountLink, isBike }
}
