import { ROUTES } from 'constants/index'
import { Icon } from 'presentation/components'
import { Modal } from 'presentation/components/'
import { useCookieControl } from './CookieControlController'
import * as S from './CookieControlModal.styles'
import { Switches } from './Switches/Switches'

export function CookieControlModal() {
  const cookieControlController = useCookieControl()

  return (
    <Modal
      keepMounted
      disablePortal
      open={cookieControlController.isOpen}
      onClose={cookieControlController.close}
      aria-labelledby="cookie-modal-title"
      aria-describedby="cookie-modal-description"
      data-testid="Cookie_Control_Modal"
      data-qa="Cookie_Control_Modal"
    >
      <S.Container>
        <div>
          <S.CloseButton
            data-qa="btn_closeCookieControlModal"
            onClick={cookieControlController.close}
            aria-label="Fechar"
          >
            <Icon icon="SLIM_CLOSE" />
          </S.CloseButton>
          <S.Title id="cookie-modal-title">Uso de Cookies</S.Title>
          <S.Text marginTop={5} id="cookie-modal-description">
            Os Cookies são pequenos arquivos que ajustam a sua navegação pra que
            você tenha a melhor experiência dentro da Webmotors, assim como os
            ajustes que você faz no carro pra dirigir com conforto e segurança.
            ;{')'} Saiba mais na nossa{' '}
            <S.Link
              id="btn_cookieControlModalPrivacy"
              data-qa="btn_cookieControlModalPrivacy"
              target="_blank"
              rel="noopener"
              href={ROUTES.HELP.PRIVACY_POLICY}
            >
              Política de privacidade
            </S.Link>{' '}
            e{' '}
            <S.Link
              id="btn_cookieControlModalCookiesPolicy"
              data-qa="btn_cookieControlModalCookiesPolicy"
              target="_blank"
              rel="noopener"
              href={ROUTES.HELP.COOKIES_POLICY}
            >
              Política de Cookies
            </S.Link>
            .
          </S.Text>
          <S.Text marginTop={15}>
            Defina os tipos de Cookies que podemos usar:
          </S.Text>
        </div>
        <S.SwitchArea>
          {cookieControlController.cookieConfig && (
            <Switches
              cookieConfig={cookieControlController.cookieConfig}
              setCookieConfig={cookieControlController.setCookieConfig}
            />
          )}
        </S.SwitchArea>
        <S.Button
          data-qa="btn_saveCookieControlModal"
          onClick={cookieControlController.save}
        >
          Salvar preferências
        </S.Button>
      </S.Container>
    </Modal>
  )
}
