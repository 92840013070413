import window from 'global/window'

export const insiderObject = {
  page: {
    type: 'Home'
  }
}

export const insiderEvent = [
  {
    event_name: 'comprar_homepage',
    event_params: {
      custom: {
        page_url: window?.location?.href || ''
      }
    }
  }
]
