import { Config } from 'application/Config'
import { loggerClient } from 'application/Services'
import { LocalClient } from 'domain/LocalClient'
import Cookies from 'js-cookie'

const logger = loggerClient.create('CookieClient')

export class CookieClient implements LocalClient {
  /**
   * Get item from cookie
   *
   * @param key
   * @returns
   */
  getItem<T>(key: string): T | null {
    const cookieValue: any = Cookies.get(key)
    logger.info(`[getItem] ${key} raw cookie value`, cookieValue)

    if (!cookieValue) {
      return null
    }

    try {
      return JSON.parse(decodeURI(cookieValue))
    } catch {
      return cookieValue
    }
  }

  /**
   * Save item into cookies
   *
   * @param key
   * @param value
   * @returns
   */
  saveItem<T>(key: string, value: T): T {
    if (typeof value !== 'string') {
      Cookies.set(key, JSON.stringify(value), { path: '/' })

      return value
    }

    Cookies.set(key, value as any, { path: '/' })

    return value
  }

  /**
   * Remove item from cookie
   *
   * @param key
   * @returns
   */
  removeItem(key: string): boolean {
    logger.info('[removeItem] removing cookie: ', key)

    if (Array.isArray(Config.cookieDomain)) {
      logger.info(
        '[removeItem] cookie domain is an array: ',
        Config.cookieDomain
      )

      for (const domain of Config.cookieDomain) {
        Cookies.remove(key, { path: '/', domain })
        Cookies.remove(key, { path: '*', domain })
        Cookies.remove(key, { path: '', domain })

        logger.info('[removeItem] removing cookie from: ', domain)
      }

      logger.info('[removeItem] item removed from all domains: ', key)
    }

    if (typeof Config.cookieDomain === 'string') {
      logger.info(
        '[removeItem] cookie domain is an string: ',
        Config.cookieDomain
      )

      Cookies.remove(key, { path: '/', domain: Config.cookieDomain })

      logger.info('[removeItem] item removed from all domains: ', key)
    }

    return true
  }
}
