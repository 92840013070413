import { v4 as uuid } from 'uuid'

const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
const charactersLength = characters.length

export class Faker {
  /**
   * Faker generate number
   *
   * @returns
   */
  static number() {
    return 12
  }

  /**
   * Faker generate string
   *
   * @returns
   */
  static string(size: number): string {
    let result = ''
    for (let i = 0; i < size; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  /**
   * Faker generate url
   *
   * @returns
   */
  static url(): string {
    return 'https://valid-url.com'
  }

  /**
   * Faker generate color
   *
   * @returns
   */
  static color(): string {
    return '#FF0000'
  }

  /**
   * Faker generate pastDate
   *
   * @returns
   */
  static pastDate(): Date {
    return new Date()
  }

  /**
   * Faker generate uuid
   *
   * @returns
   */
  static uuid(): string {
    return uuid()
  }

  /**
   * Faker generate latitude
   *
   * @returns
   */
  static latitude(): string {
    return '23.00001'
  }

  /**
   * Faker generate longitude
   *
   * @returns
   */
  static longitude(): string {
    return '23.00001'
  }

  /**
   * Faker generate cityName
   *
   * @returns
   */
  static cityName(): string {
    return 'Sorocaba'
  }

  /**
   * Faker generate stateName
   *
   * @returns
   */
  static stateName(): string {
    return 'São Paulo'
  }

  /**
   * Faker generate stateAbbr
   *
   * @returns
   */
  static stateAbbr(): string {
    return 'SP'
  }

  /**
   * Faker generate firstName
   *
   * @returns
   */
  static firstName(): string {
    return 'João'
  }

  /**
   * Faker generate email
   *
   * @returns
   */
  static email(): string {
    return 'joe@doe.com'
  }

  /**
   * Faker generate phoneNumber
   *
   * @returns
   */
  static phoneNumber(): string {
    return '(51) 99882-7766'
  }
}
