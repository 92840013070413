import { useCallback, useEffect, useState } from 'react'

export function useModal(name: string, initialData?) {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [data, setData] = useState(initialData)

  /**
   * Dispatch and event to the current modal
   *
   */
  const dispatchModalEvent = useCallback(
    (detail) => {
      document.dispatchEvent(
        new CustomEvent(`@modal:event:${name}`, {
          detail
        })
      )
    },
    [name]
  )

  /**
   * Open modal by name
   *
   */
  const open = useCallback(
    (data?) => {
      dispatchModalEvent({
        isOpen: true,
        data
      })
    },
    [dispatchModalEvent]
  )

  /**
   * Close modal by name
   *
   */
  const close = useCallback(
    (data?) => {
      dispatchModalEvent({
        isOpen: false,
        data
      })
    },
    [dispatchModalEvent]
  )

  /**
   * Add event listeners of modal
   *
   */
  useEffect(() => {
    function handleModalEvent(e) {
      setData(e.detail?.data || undefined)
      setIsOpen(e.detail.isOpen)
    }
    document.addEventListener(`@modal:event:${name}`, handleModalEvent)

    return () =>
      document.removeEventListener(`@modal:event:${name}`, handleModalEvent)
  }, [setIsOpen, name])

  return {
    isOpen,
    data,
    open,
    close
  }
}
