import { FakableClass, FakableField } from 'fake-class'
import { Faker } from 'faker'

@FakableClass()
export class Category {
  /**
   * Category id
   *
   */
  @FakableField(() => Faker.number())
  id!: number

  /**
   * Category title
   *
   */
  @FakableField(() => Faker.string(10))
  title!: string

  /**
   * Category desktopBanner
   *
   */
  @FakableField(() => Faker.url())
  desktopBanner!: string

  /**
   * Category mobileBanner
   *
   */
  @FakableField(() => Faker.url())
  mobileBanner!: string

  /**
   * Category mobileCard
   *
   */
  @FakableField(() => Faker.url())
  mobileCard!: string

  /**
   * Category desktopCard
   *
   */
  @FakableField(() => Faker.string(10))
  desktopCard!: string

  /**
   * Category primaryColor
   *
   */
  @FakableField(() => Faker.color())
  primaryColor!: string

  /**
   * Category secondaryColor
   *
   */
  @FakableField(() => Faker.color())
  secondaryColor!: string

  /**
   * Category createdAt
   *
   */
  @FakableField(() => Faker.pastDate())
  createdAt!: Date

  /**
   * Category description
   *
   */
  @FakableField(() => Faker.string(10))
  description!: string

  /**
   * Category url
   *
   */
  @FakableField(() => Faker.url())
  url!: string

  /**
   * Category constructor
   *
   * @param params
   */

  constructor(params: Partial<Category>) {
    Object.assign(this, params)
  }
}
