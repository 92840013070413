import styled from 'styled-components'

export const Main = styled.main`
  background-color: ${({ theme }) => theme.palette.background.default};
  margin-top: 64px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 0;
  }
`
