import { FakableClass } from 'fake-class'
import { InsiderUser } from './InsiderUser'

/**
 * Represents an InsiderObject.
 */
@FakableClass()
export class InsiderObject {
  /**
   * Represents the user associated with the InsiderObject.
   */
  user: {
    name?: string
    email?: string
    phone_number?: string
    gdpr_optin?: boolean | null
    custom?: {
      [key: string]: any
    }
  }

  /**
   * Constructs a new InsiderObject.
   * @constructor
   * @param {Object} params - The parameters for creating an InsiderObject.
   * @param {InsiderUser} [params.user] - The user associated with the object.
   */
  constructor({ user, ...rest }: { user?: InsiderUser }) {
    Object.assign(this, rest)

    const { name, email, phoneNumber, gdprOptin, custom } = user ?? {}

    this.user = {
      ...(name && { name }),
      ...(email && { email }),
      ...(phoneNumber && { phone_number: phoneNumber }),
      ...(gdprOptin !== null && { gdpr_optin: gdprOptin }),
      ...(custom && Object.keys(custom).length !== 0 && { custom })
    }
  }
}
