import { ROUTES } from 'constants/index'
import { ProductWeb } from 'domain/ProductWeb'

interface IDefaultAnchorAttributes {
  rel: 'noreferrer' | 'nofollow' | 'noopener'
  target: '_blank' | '_self'
}

const defaultAttributes: IDefaultAnchorAttributes = {
  rel: 'noreferrer',
  target: '_blank'
}

export const productsLinks: ProductWeb[] = [
  {
    id: 'ProductsWeb_Financing',
    title: 'Financiamento',
    tagType: 'h3',
    data_qa: 'icon_productsweb_financing',
    url: `${ROUTES.SERVICES.FINANCING}/?lkid=1068`,
    attributes: {
      ...defaultAttributes
    }
  },
  {
    id: 'ProductsWeb_VehicleSale',
    title: 'Vender carro',
    tagType: 'p',
    data_qa: 'icon_productsweb_vehiclesale',
    url: `${ROUTES.CAR.SELL}/?lkid=1069`,
    attributes: {
      ...defaultAttributes
    }
  },
  {
    id: 'ProductsWeb_Catalog',
    title: 'Catálogo 0km',
    tagType: 'p',
    data_qa: 'icon_productsweb_catalog',
    url: `${ROUTES.CAR.ZERO}/?lkid=1070`,
    attributes: {
      ...defaultAttributes
    }
  },
  {
    id: 'ProductsWeb_Insurance',
    title: 'Seguro veículo',
    tagType: 'h3',
    data_qa: 'icon_productsweb_insurance',
    url: `${ROUTES.SERVICES.INSURANCE}?lkid=1071&utm_source=webmotors&utm_medium=site&utm_campaign=prox_footer_seguro_veiculo`,
    attributes: {
      ...defaultAttributes,
      onClick: () => {
        const eventKey = 'autoComparaProxFooterHome'
        console.info(`[EventSend] eventType: ${eventKey}`)
        window.dispatchEvent(new CustomEvent(eventKey))
      }
    }
  },
  {
    id: 'ProductsWeb_TableFipe',
    title: 'Tabela FIPE',
    tagType: 'h3',
    data_qa: 'icon_productsweb_tablefipe',
    url: `${ROUTES.SERVICES.FIPE}?lkid=1072`,
    attributes: {
      ...defaultAttributes
    }
  },
  {
    id: 'ProductsWeb_WM1News',
    title: 'Notícias WM1',
    tagType: 'h3',
    data_qa: 'icon_productsweb_wm1news',
    url: `${ROUTES.WM1.PORTAL}?lkid=1897`,
    attributes: {
      ...defaultAttributes
    }
  }
]
