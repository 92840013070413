import window from 'global/window'
import { useEffect, useMemo, useRef, useState } from 'react'

export type Slide = {
  index: number
}

export function useGalleryController() {
  const [containerWidth, setContainerWidth] = useState(0)
  const [activedIndex, setActivedIndex] = useState(0)
  const [slides, setSlides] = useState<Slide[]>([])
  const containerRef = useRef<HTMLDivElement>(null)

  /**
   * Current scroll offset
   *
   */
  const scrollOffset = useMemo(() => {
    return activedIndex * containerWidth
  }, [activedIndex, containerWidth])

  /**
   * Gallery context values
   *
   */
  const contextValues = useMemo(() => {
    return {
      slides,
      setSlides
    }
  }, [slides, setSlides])

  /**
   * Handle next slide
   *
   * @returns
   */
  function handleNext(e) {
    e.preventDefault()

    if (activedIndex === slides.length - 1) {
      return
    }

    setActivedIndex((s) => s + 1)
  }

  /**
   * Handle previous slide
   *
   * @returns
   */
  function handlePrevious(e) {
    e.preventDefault()

    if (activedIndex === 0) {
      return
    }

    setActivedIndex((s) => s - 1)
  }

  /**
   * Listen to scroll effects
   *
   */
  useEffect(() => {
    if (
      !containerRef.current ||
      typeof containerRef.current?.scrollTo !== 'function'
    ) {
      return
    }

    containerRef.current?.scrollTo({
      top: 0,
      left: scrollOffset,
      behavior: 'smooth'
    })
  }, [scrollOffset])

  /**
   * Set initial container width
   *
   */

  useEffect(() => {
    function listener() {
      setContainerWidth(containerRef.current?.clientWidth || 0)
    }
    setContainerWidth(containerRef.current?.clientWidth || 0)

    window.addEventListener('resize', listener)

    return () => window.removeEventListener('resize', listener)
  }, [])

  return {
    setSlides,
    slides,
    handleNext,
    handlePrevious,
    containerRef,
    activedIndex,
    contextValues,
    setActivedIndex
  }
}
