import { Theme } from '@mui/material'
import { getTheme } from 'presentation/themes'
import defaultTheme from 'presentation/themes/defaultTheme'
import { useEffect, useState } from 'react'

export function useIsBlackFairActive() {
  const [currentTheme, setCurrentTheme] = useState<Theme>(defaultTheme)

  useEffect(() => {
    getTheme().then((response) => setCurrentTheme(response))
  }, [])

  return {
    currentTheme
  }
}
