import { BaseSaveRepository } from 'domain/BaseRepository'
import { LocalSaveItem } from 'domain/LocalClient'
import { InsiderObject } from '../Model/InsiderObject'

/**
 * InsiderTagRepository class
 * Provides a repository for saving InsiderObject instances.
 */
export class InsiderTagRepository implements BaseSaveRepository<InsiderObject> {
  /**
   * Initializes a new instance of the InsiderTagRepository class.
   *
   * @param {LocalSaveItem} local - The LocalSaveItem instance to use for saving items.
   */
  constructor(private local: LocalSaveItem) {}

  /**
   * Saves the given InsiderObject instance.
   *
   * @param {InsiderObject} insiderObject - The InsiderObject instance to save.
   * @returns {Promise<InsiderObject>} - A Promise that resolves with the saved InsiderObject instance.
   */
  async save(insiderObject: InsiderObject): Promise<InsiderObject> {
    const savedObject: InsiderObject = { ...insiderObject }
    this.local.saveItem('insider_object', savedObject)
    return savedObject
  }
}
