import {
  DrawerMenu,
  getDrawerLinks,
  linkBuilder,
  linksDatasetFactory
} from '@webmotors/buyer-design-system'

import { Config } from 'application'
import { useAuth, useHomeDataLayer } from 'presentation/hooks'
import { isClientSide } from 'presentation/hooks/use-is-client-side'
import { useEffect, useMemo } from 'react'

export function Drawer({ showNotification, setShowNotification }) {
  const { user, logout } = useAuth()
  const { handleHomeDataLayer } = useHomeDataLayer('autoComparaMenuServicos')

  const routes = linksDatasetFactory({
    baseUrl: `${Config.baseUrl}/`
  })

  const drawerLinks = useMemo(() => {
    return linkBuilder(getDrawerLinks)
      .user(user)
      .url(`${Config.baseUrl}/`)
      .build()
  }, [user])

  const { user: userProp, ...props } = drawerLinks
  const onClientSide = isClientSide()

  function handleCloseNotification() {
    if (onClientSide) {
      window.localStorage.setItem(
        Config.hideLoginNotificationStorageKey,
        'true'
      )
    }
    setShowNotification(false)
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const element = document.getElementById(
        'drawer_header_services_insurance'
      )
      if (element) {
        element.addEventListener('click', handleHomeDataLayer)

        return () => {
          element.removeEventListener('click', handleHomeDataLayer)
        }
      }
    }
  }, [])

  return (
    <DrawerMenu
      routes={routes}
      onLogOut={logout}
      user={userProp ?? undefined}
      showNotification={showNotification}
      onNotificationClose={handleCloseNotification}
      {...props}
    />
  )
}
