import { Config } from 'application'
import { loggerClient, uriService } from 'application/Services'
import { BaseFindOneRepository } from 'domain/BaseRepository'
import {
  FeatureFlag,
  LocalFeatureFlag
} from '../Repository/FeatureFlagRepository'

const logger = loggerClient.create('FeatureFlagService')
type ParamsQueryString = {
  [FeatureFlag.QUERYSTRINGKEY]: string
}

export class FeatureFlagService {
  constructor(
    private localRepository: BaseFindOneRepository<LocalFeatureFlag>
  ) {}

  /**
   * Check if FeatureFlag is activated in LocalStorage
   *
   * @returns {Promise<boolean>}
   */
  async isFeatureFlagActiveInLocalStorage(): Promise<boolean> {
    const resolve = await this.localRepository.findOne()
    logger.info(
      '[isFeatureFlagActiveInLocalStorage] returns the localStorage property FF_AutomaticFairs in FF key: ',
      resolve
    )
    return resolve.isActive
  }

  /**
   * Check if FeatureFlag is activated in QueryString
   *
   * @returns {boolean}
   */
  isFeatureFlagActiveInQueryString(): boolean {
    const params = window.location.href.split('?')[1]
    const queryStringParams =
      uriService.queryStringToObject<ParamsQueryString>(params)
    const isFeatureFlagActive =
      queryStringParams?.[FeatureFlag.QUERYSTRINGKEY] ===
      FeatureFlag.QUERYSTRINGVALUE
    return isFeatureFlagActive
  }

  /**
   * Check if FeatureFlag is activated in Config file
   *
   * @returns {boolean}
   */
  isFeatureFlagActiveInConfigs(): boolean {
    return Config.blackFeiraoActive
  }

  /**
   * Check if FeatureFlag was activated in the system
   *
   * @returns {Promise<boolean>}
   */
  async isFeatureFlagActive(): Promise<boolean> {
    return (
      this.isFeatureFlagActiveInConfigs() ||
      this.isFeatureFlagActiveInQueryString() ||
      (await this.isFeatureFlagActiveInLocalStorage())
    )
  }
}
