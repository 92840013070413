export class NumberFormatService {
  /**
   * Extract number from string
   *
   * @param input
   * @returns
   */
  extractNumber(input: string): number {
    const num = Number(input.replace(/\D/g, ''))
    return isNaN(num) ? 0 : num
  }

  /**
   * Formats a number to a human-readable format
   *
   * @param input
   * @returns
   */
  humanReadableOutput(input: number): string {
    if (typeof input !== 'number') {
      return 'Error: Invalid input'
    }

    const inputString = input.toString()
    let result = ''
    let counter = 0

    for (let i = inputString.length - 1; i >= 0; i--) {
      counter++
      result = inputString[i] + result

      if (counter === 3 && i !== 0) {
        result = '.' + result
        counter = 0
      }
    }

    return result
  }

  /**
   * Format to money
   *
   * @param input
   * @returns
   */
  static money(input: number): string {
    const numberFormat = new NumberFormatService()

    return `R$ ${numberFormat.humanReadableOutput(input)}`
  }
}
