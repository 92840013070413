import { Modal } from 'presentation/components/'
import { BlackFairContent } from './BlackFair/BlackFairContent'
import { MegaFairContent } from './MegaFair/MegaFairContent'
import { useToAppMobileModalController } from './ToAppMobileModalController'

const APP_URL = 'https://bnxk.adj.st/abaixofipe?adj_t=15ktiko1'

export function ToAppMobileModal() {
  const {
    isMobile,
    acceptedConsent,
    isBlackFairActive,
    isMegaFairActive,
    user,
    isOpen,
    classes,
    handleModalClose,
    handleHomeDataLayerClose,
    handleHomeDataLayerCTA
  } = useToAppMobileModalController()

  const handleClose = () => {
    handleHomeDataLayerClose()
    handleModalClose()
  }

  const handleCTAClick = () => {
    handleHomeDataLayerCTA()
    handleModalClose()
  }

  if (
    (!isBlackFairActive && !isMegaFairActive) ||
    !isMobile ||
    !acceptedConsent ||
    user
  ) {
    return null
  }

  return (
    <Modal
      data-testid="ToAppMobileModal"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={`${classes.root}`}
    >
      <>
        {isBlackFairActive && (
          <BlackFairContent
            appUrl={APP_URL}
            handleClose={handleClose}
            handleCTAClick={handleCTAClick}
          />
        )}
        {isMegaFairActive && (
          <MegaFairContent
            appUrl={APP_URL}
            handleClose={handleClose}
            handleCTAClick={handleCTAClick}
          />
        )}
      </>
    </Modal>
  )
}
