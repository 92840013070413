import styled from 'styled-components'

export const Container = styled.div`
  padding: 0px 30px;
  padding-top: 12px;
  max-width: 1020px;
  margin: 0px auto;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0px;
    padding-top: 12px;
  }
`

export const Title = styled.h3`
  line-height: 19px;
  font-weight: 700;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-bottom: 16px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-left: 24px;
  }
`

export const CardRow = styled.div`
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  display: grid;
  gap: 16px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0px 0px 24px 24px;
    overflow-x: scroll;
    display: flex;
  }
`

export const Card = styled.a`
  box-shadow: 0px 8px 16px rgba(46, 45, 55, 0.12);
  display: inline-block;
  border-radius: 8px;
  background: white;
  overflow: hidden;
  height: 124px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    min-width: 180px;
  }
`

export const CardHeader = styled.div`
  text-transform: uppercase;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: #696977;
  line-height: 18px;
  font-size: 16px;
  display: flex;
  color: white;
  height: 62px;
  border: solid #696977;

  b {
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0px 10px;
    overflow: hidden;
    max-width: 180px;
  }
`

export const CardBody = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 62px;
  width: 100%;
`

export const Label = styled.div`
  border: 1px solid #696977;
  text-overflow: ellipsis;
  border-radius: 55px;
  white-space: nowrap;
  padding: 1px 15px;
  line-height: 18px;
  overflow: hidden;
  font-weight: 500;
  font-size: 12px;
  max-width: 80%;
`
