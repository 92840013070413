import { createTheme } from '@mui/material/styles'
import baseTheme from '../baseTheme'

const blackFairTheme = createTheme(baseTheme, {
  palette: {
    background: {
      default: '#1A1818'
    },
    primary: {
      main: '#e11138'
    },
    secondary: {
      main: '#2e2d37'
    },
    text: {
      secondary: '#ffffff'
    }
  }
})

export default blackFairTheme
