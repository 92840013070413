import { Config } from 'application'
import { DataParser } from 'domain/DataParser'
import { LocalGetItem } from 'domain/LocalClient'
import { User } from '../Model/User'

export class UserService {
  /**
   * Constructor method
   *
   * @param localClient
   * @param stringParser
   */
  constructor(
    private localClient: LocalGetItem,
    private userParser: DataParser<string, User>
  ) {}

  /**
   * Get current logged in user
   *
   * @returns
   */
  getCurrentUser(): User | null {
    try {
      const userEncryptedString = this.localClient.getItem<string>(
        Config.authCookieName
      )
      if (!userEncryptedString) {
        return null
      }

      return this.userParser.parse(userEncryptedString)
    } catch (e) {
      return null
    }
  }
}
