import { dataFormService } from 'application'
import { Config } from 'application/Config'
import { loggerClient } from 'application/Services'
import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import { BaseSaveRepository } from 'domain/BaseRepository'
import { CookieConfig } from 'domain/Cookie/Models/CookieConfig'
import { User } from 'domain/User/Model/User'
import window from 'global/window'
import { InsiderObject, InsiderUser } from '../Model'

const logger = loggerClient.create('InsiderTag')

export type InsiderTagParams = {
  properties?: Record<string, any>
  user: User | null
  cookieConfig: CookieConfig
}

export type InsiderEventParams = {
  event: string
  properties: Record<string, any>
}

/**
 * InsiderTagService class
 * Provides methods for creating and dispatching Insider tags and events.
 */
export class InsiderTagService {
  constructor(private repository: BaseSaveRepository<InsiderObject>) {}

  /**
   * Create Insider object
   * Creates and saves a new Insider object using the given properties and user information.
   *
   * @param {InsiderTagParams} params - The params containing the properties and user information.
   */
  createInsiderObject(params: InsiderTagParams): void {
    const { user, properties, cookieConfig } = params

    const dataFormCookie = dataFormService.getDataFormCookie()
    const { Principal: { newsletter = false } = {} } =
      JSON.parse(
        AES.decrypt(dataFormCookie, Config.cookieEncryptionSecret).toString(
          Utf8
        ) || '{}'
      ) || {}

    const { showCookieNotice, cookies } = cookieConfig
    const gdprOptin: boolean | null = !showCookieNotice
      ? Object.values(cookies).some((value) => value === true)
      : null

    const insiderObject = new InsiderObject({
      ...properties,
      user: new InsiderUser({
        ...(user && { ...user }),
        gdprOptin,
        custom: { newsletter }
      })
    })

    logger.info('[Create] insider object: ', insiderObject)
    this.repository.save(insiderObject)
  }

  /**
   * Dispatch Insider event
   * Dispatches an Insider event with the given event name and properties.
   *
   * @param {InsiderEventParams} params - The params containing the event name and properties.
   */
  dispatchInsiderEvent(params: InsiderEventParams): void {
    if (!window.Insider) {
      logger.info('[SDK]: ', 'Not found')
      return
    }

    const { event, properties } = params
    window.Insider.track(event, properties)
    logger.info(`[Dispatch] insider event "${event}": `, properties)
  }
}
