import { Config } from 'application'
import { BaseRepository, QueryParams } from 'domain/BaseRepository'
import { HttpGetClient } from 'domain/HttpClient'
import { State } from '../../Model/State'
import { IStateParser, StateDto } from '../Contracts/IStateParser'

export class StateRepository implements BaseRepository<State> {
  constructor(
    private httpGetClient: HttpGetClient,
    private stateDtoParser: IStateParser
  ) {}

  /**
   * Get all available locations with vehicles according to the path (car || bike)
   *
   * @returns
   */
  async getAll(queryParams: QueryParams): Promise<State[]> {
    const result = await this.httpGetClient.get<StateDto[]>(
      `/filter/${queryParams.path}/LocationSearch`,
      {
        queryParams: {
          url: Config.baseUrl,
          parentFilter: 'None',
          parentValue: ''
        }
      }
    )

    return this.stateDtoParser.parse(result)
  }
}
