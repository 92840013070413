import styled from 'styled-components'

export const Carousel = styled.div`
  position: relative;
  width: 100%;
  max-width: 1920px;
  height: 320px;
  margin: 0 auto;
  overflow: hidden;

  ${({ theme }) => theme.breakpoints.up(1920)} {
    max-width: 100%;
    height: 17vw;
  }

  ${({ theme }) => theme.breakpoints.down(1300)} {
    max-width: 1300px;
    height: 215px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 100%;
    height: 50vw;
  }
`

export const CarouselWrapper = styled.div`
  backface-visibility: hidden;
`

export const CarouselItem = styled.div`
  position: absolute;
  width: 100%;
  height: 320px;
  transition: transform ease 1s;
  transform: translateX(100%);
  z-index: 2;
  will-change: transform;
  backface-visibility: hidden;

  ${({ theme }) => theme.breakpoints.down(1300)} {
    height: 215px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 50vw;

    .cliqueCarrossel {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &:first-child {
    transform: translateX(0%);
    z-index: 3;
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    overflow: hidden;

    iframe {
      flex-shrink: 0;
      width: 1920px;
      height: 320px;

      ${({ theme }) => theme.breakpoints.up(1920)} {
        width: 100%;
        height: 17vw;
      }

      ${({ theme }) => theme.breakpoints.down(1300)} {
        width: 1300px;
        height: 215px;
      }

      ${({ theme }) => theme.breakpoints.down('md')} {
        width: 100%;
        height: 50vw;
      }
    }
  }
`

const Button = styled.button`
  position: absolute;
  top: 50%;
  z-index: 10;
  display: block;
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  padding: 0;
  border: none;
  border-radius: 20px;
  background-color: #fff;
  color: transparent;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 50%);
  outline: none;
  font-size: 0;
  line-height: 0;
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.2s;
  transform: translate(0, -50%);

  &:after {
    content: '';
    position: relative;
    width: 17px;
    height: 17px;
    display: flex;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
    background-image: url("data:image/svg+xml;utf8,<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'> <path d='M20.9987,11 L5.4127,11 L10.7057,5.707 C11.0967,5.316 11.0967,4.684 10.7057,4.293 C10.3147,3.902 9.6827,3.902 9.2917,4.293 L2.2917,11.292 C2.1997,11.385 2.1267,11.496 2.0757,11.618 C1.9747,11.862 1.9747,12.138 2.0757,12.382 C2.1267,12.504 2.1997,12.615 2.2917,12.708 L9.2917,19.707 C9.4867,19.902 9.7427,20 9.9987,20 C10.2547,20 10.5107,19.902 10.7057,19.707 C11.0967,19.316 11.0967,18.684 10.7057,18.293 L5.4127,13 L20.9987,13 C21.5507,13 21.9987,12.552 21.9987,12 C21.9987,11.448 21.5507,11 20.9987,11'></path></svg>");
    background-position: center;
    background-size: 17px;
  }

  &:hover:enabled {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.5;
  }
`

export const PrevButton = styled(Button)`
  left: 20px;
`

export const NextButton = styled(Button)`
  right: 20px;
  &:after {
    transform: rotate(180deg);
  }
`
