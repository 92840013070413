import { SearchItem } from 'domain/Search/Models/SearchItem'
import { createUrl } from 'presentation/utils'

export function useLastSearchController() {
  /**
   * Mount link for model searching
   *
   * @param brand
   * @returns
   */
  function mountLinkModel(model: SearchItem, index: number): string {
    const lkid = 1038 + index

    return createUrl(`${model.url}&lkid=${lkid}`)
  }

  return {
    mountLinkModel
  }
}
