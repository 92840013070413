import { IconsMap } from './Icon.types'

export const ICONS: IconsMap = {
  FAVORITES: {
    path: [
      'M8.028 6.00475C7.865 6.00475 7.705 6.01775 7.549 6.04175C6.317 6.23575 5 7.21575 5 9.38475C5 13.4097 10.272 17.1217 12 18.2297C13.728 17.1217 19 13.4097 19 9.38475C19 7.21575 17.682 6.23575 16.45 6.04175C15.174 5.84175 13.533 6.44475 12.951 8.24575C12.818 8.65875 12.434 8.93875 12 8.93875C11.566 8.93875 11.181 8.65875 11.048 8.24575C10.537 6.66375 9.209 6.00475 8.028 6.00475ZM12.194 20.2887H11.805C11.621 20.2887 11.44 20.2367 11.282 20.1407C9.339 18.9467 3 14.6447 3 9.38475C3 6.17175 5.129 4.39875 7.239 4.06675C9.109 3.77275 10.874 4.47975 12 5.86375C13.126 4.48075 14.889 3.76975 16.76 4.06675C18.871 4.39875 21 6.17175 21 9.38475C21 14.6447 14.66 18.9467 12.717 20.1407C12.56 20.2367 12.379 20.2887 12.194 20.2887Z'
    ]
  },
  FAVORITES_SOLID: {
    path: [
      'M11.9675 0.388356C10.41 0.143356 8.9375 0.733356 8 1.88669C7.0625 0.733356 5.59083 0.141689 4.0325 0.388356C2.27417 0.665023 0.5 2.14252 0.5 4.82086C0.5 9.20419 5.7825 12.7892 7.40167 13.7842C7.53333 13.8642 7.68417 13.9075 7.83833 13.9075H8.16167C8.31583 13.9075 8.46667 13.8642 8.59833 13.7842C10.2175 12.7892 15.5 9.20419 15.5 4.82086C15.5 2.14252 13.7258 0.665023 11.9675 0.388356Z'
    ]
  },

  SLIM_CLOSE: {
    path: [
      'M13.5808 11.95L20.2618 5.26901C20.7128 4.81807 20.7128 4.08919 20.2618 3.63825C19.8109 3.18731 19.082 3.18731 18.6311 3.63825L11.95 10.3193L5.26901 3.63825C4.81807 3.18731 4.08919 3.18731 3.63825 3.63825C3.18731 4.08919 3.18731 4.81807 3.63825 5.26901L10.3193 11.95L3.63825 18.6311C3.18731 19.082 3.18731 19.8109 3.63825 20.2618C3.86315 20.4867 4.15839 20.5998 4.45363 20.5998C4.74888 20.5998 5.04412 20.4867 5.26901 20.2618L11.95 13.5808L18.6311 20.2618C18.856 20.4867 19.1512 20.5998 19.4465 20.5998C19.7417 20.5998 20.037 20.4867 20.2618 20.2618C20.7128 19.8109 20.7128 19.082 20.2618 18.6311L13.5808 11.95Z'
    ]
  },
  CHECK: {
    path: [
      'M8.96674 18.0332C8.71074 18.0332 8.45474 17.9352 8.25974 17.7402L4.29274 13.7733C3.90274 13.3833 3.90274 12.7502 4.29274 12.3593C4.68374 11.9693 5.31674 11.9693 5.70674 12.3593L8.96674 15.6193L18.2927 6.29325C18.6837 5.90225 19.3167 5.90225 19.7067 6.29325C20.0977 6.68325 20.0977 7.31625 19.7067 7.70725L9.67374 17.7402C9.47874 17.9352 9.22274 18.0332 8.96674 18.0332Z'
    ]
  }
}
