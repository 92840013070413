import { window } from 'global'
import { useEffect, useState } from 'react'

export type Props = {
  [x: string]: any
}

export function Delayed({ fallback = null, children }: Props) {
  const [show, setShow] = useState(false)

  /**
   * Add event to load content only when page is fuly loaded
   *
   */
  useEffect(() => {
    function eventListener() {
      setShow(true)
    }

    window.addEventListener('load', eventListener)

    return () => window.removeEventListener('load', eventListener)
  }, [])

  if (!show) {
    return fallback
  }

  return <>{children}</>
}
