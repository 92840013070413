const desktopSize: googletag.SingleSize = [1920, 320]
const mobileSize: googletag.SingleSize = [560, 280]
const dimensions: googletag.MultiSize = [desktopSize, mobileSize]

const defaultConfig = {
  networkCode: '9764',
  key: 'carrossel',
  idHtml: 'banner-home',
  totalSlots: 5,
  dimensions,
  desktopSize: [desktopSize],
  mobileSize: [mobileSize],
  breakpoints: [
    [900, 0],
    [320, 0]
  ]
}
const homeConfig = {
  unitCode: 'HP/HP',
  unitCodeHK: 'Home_Teste/HP'
}
const hotDealConfig = {
  unitCode: 'HP/Feirao',
  unitCodeHK: 'Home_Teste/Feirao'
}

export const googleTagSettings = (isHotDeal = false) => {
  return {
    ...defaultConfig,
    ...(isHotDeal ? hotDealConfig : homeConfig)
  }
}
