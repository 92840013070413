import { ImgHTMLAttributes } from 'react'

export interface PictureProps extends ImgHTMLAttributes<HTMLImageElement> {
  data: PictureData
}

export interface PictureData {
  mainSrcSet: string
  sources: SourceProps[]
}

export interface SourceProps {
  srcSet: string
  type: string
}

export function Picture(props: PictureProps) {
  const { loading, data, height, width, alt, className } = props
  const { mainSrcSet, ...restProps } = data
  return (
    <picture className={className}>
      {restProps.sources.map((item, index) => {
        const id = `Picture_Source_${index}`
        return (
          <source
            key={id}
            data-testid={id}
            srcSet={item.srcSet}
            type={item.type}
          ></source>
        )
      })}
      <img
        data-testid="Picture_Img"
        height={height}
        width={width}
        src={mainSrcSet}
        alt={alt}
        loading={loading}
        draggable="false"
      ></img>
    </picture>
  )
}
