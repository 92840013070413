import { LocalGetItem, LocalSaveItem } from 'domain/LocalClient'
import window from 'global/window'

export class GlobalClient implements LocalGetItem, LocalSaveItem {
  /**
   * Get item from global client
   *
   * @param key
   * @returns
   */
  getItem<T>(key: string): T | null {
    return window[key] || null
  }

  saveItem<T>(key: string, value: T): T {
    window[key] = value

    return value
  }
}
