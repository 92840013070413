import { DataParser } from 'domain/DataParser'
import { InvalidUserString } from '../Errors/InvalidUserString'
import { User } from '../Model/User'

export class UserFromJwtService implements DataParser<string, User> {
  /**
   * Decode string from binary input
   *
   * @param encodedString
   * @returns
   */
  atob(encodedString: string) {
    return decodeURIComponent(
      atob(encodedString)
        .toString()
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
  }
  /**
   * Get user from JWT token
   *
   * @param encodedString
   * @returns
   */
  parse(encodedString: string): User {
    try {
      const jsonContent = JSON.parse(this.atob(encodedString.split('.')[1]))
      return new User({
        id: jsonContent['cognito:username'],
        name: jsonContent['custom:name_naturalperson'],
        email: jsonContent.email,
        phoneNumber: jsonContent.phone_number,
        authenticationToken: encodedString
      })
    } catch (e) {
      throw new InvalidUserString('Não foi possível obter o usuário')
    }
  }
}
