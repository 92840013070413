import { Config } from 'application'
import { loggerClient } from 'application/Services'
import { CookieService } from 'domain/Cookie'
import { LocalClient } from 'domain/LocalClient'
import { ISearchItemRepository } from '../Contracts/ISearchItemRepository'
import { SearchItem, SearchItemSegment } from '../Models/SearchItem'

const logger = loggerClient?.create('SearchService')

export class SearchService {
  /**
   * Constructor method
   *
   * @param localClient
   */
  constructor(
    private cookieService: CookieService,
    private repository: ISearchItemRepository,
    private localClient: LocalClient
  ) {}

  /**
   * Set actived search segment
   *
   * @param segment
   * @returns
   */
  setSearchSegment(segment: SearchItemSegment) {
    document.dispatchEvent(
      new CustomEvent('handleVehicleTypeChange', {
        detail: segment
      })
    )

    return this.localClient.saveItem(Config.cookieSearchSegmentKey, segment)
  }

  /**
   * Get actived search segment
   *
   * @returns
   */
  getSearchSegment(): SearchItemSegment {
    return (
      this.localClient.getItem<SearchItemSegment>(
        Config.cookieSearchSegmentKey
      ) || SearchItemSegment.CAR
    )
  }

  /**
   * Get last searched items
   *
   * @returns
   */
  async getLastSearches(): Promise<SearchItem[]> {
    const isSearchEnabled = await this.cookieService.isSearchEnabled()
    if (!isSearchEnabled) {
      logger.info('[getLastSearches] search is disabled')

      return []
    }

    const lastSearches = await this.repository.getAll({ path: 'LAST_SEARCH' })
    const results = lastSearches.slice(0, 5)

    logger.info('[getLastSearches] result: ', results)

    return results
  }

  /**
   * Get user last search
   *
   * @returns
   */
  async getLastSearch(): Promise<SearchItem | null> {
    const [lastSearch] = await this.getLastSearches()

    return lastSearch || null
  }
}
