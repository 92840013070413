import { Faker } from 'faker'

import { FakableClass, FakableField, fakeClass } from 'fake-class'
import random from 'lodash/random'
import slugify from 'slugify'
import { City } from './City'

function generateRandomCities() {
  const quantity = random(1, 20)
  const result: Array<City> = []
  for (let i = 0; i < quantity; i++) {
    result.push(fakeClass(City))
  }
  return result
}

@FakableClass()
export class State {
  /**
   * State name
   *
   */
  @FakableField(() => Faker.stateName())
  name!: string

  /**
   * State shortName
   *
   */
  @FakableField(() => Faker.stateAbbr())
  abbr!: string

  /**
   * State Cities
   *
   */
  @FakableField(() => generateRandomCities())
  cities: City[] = []

  /**
   * State count
   *
   */
  @FakableField(() => Faker.number())
  count = 0

  /**
   * State constructor
   *
   * @param params
   */
  constructor(params: Partial<State>) {
    Object.assign(this, params)
  }

  /**
   * Parse state abbr as slug
   *
   * @returns
   */
  abbrToSlug(): string {
    return slugify(this.abbr, {
      lower: true
    })
  }

  /**
   * Parse state name as slug
   *
   * @returns
   */
  toSlug(): string {
    return slugify(this.name, {
      lower: true
    })
  }
}
