import {
  Delayed,
  ErrorBoundary,
  MasterTemplate,
  ViewPortControl
} from 'presentation/components'
import {
  Advertisement,
  AdvertisementType,
  BestSellers,
  Categories,
  HelpBar,
  LastSearch,
  MediaBanner,
  NewsGroup,
  OfficialStores,
  ProductsWeb,
  Recommended,
  SearchSection,
  SearchSectionMobile
} from '../../components'
import { useHomeController } from './HomeController'

export function Home() {
  useHomeController()

  return (
    <MasterTemplate>
      <ErrorBoundary>
        <MediaBanner />
      </ErrorBoundary>

      <ErrorBoundary>
        <SearchSection />
      </ErrorBoundary>

      <ErrorBoundary>
        <SearchSectionMobile />
      </ErrorBoundary>

      <ErrorBoundary>
        <LastSearch />
      </ErrorBoundary>

      <ErrorBoundary>
        <OfficialStores position="top" />
      </ErrorBoundary>

      <ErrorBoundary>
        <Categories />
      </ErrorBoundary>

      <ErrorBoundary>
        <ViewPortControl minHeight="260px">
          <BestSellers />
        </ViewPortControl>
      </ErrorBoundary>

      <ErrorBoundary>
        <Delayed>
          <ViewPortControl>
            <Advertisement type={AdvertisementType.LOJAS} />
          </ViewPortControl>
        </Delayed>
      </ErrorBoundary>

      <ErrorBoundary>
        <Delayed>
          <ViewPortControl>
            <Advertisement type={AdvertisementType.CARROS0KM} />
          </ViewPortControl>
        </Delayed>
      </ErrorBoundary>

      <ErrorBoundary>
        <ViewPortControl>
          <Recommended />
        </ViewPortControl>
      </ErrorBoundary>

      <ErrorBoundary>
        <ViewPortControl>
          <NewsGroup />
        </ViewPortControl>
      </ErrorBoundary>

      <ErrorBoundary>
        <ProductsWeb />
      </ErrorBoundary>

      <ErrorBoundary>
        <HelpBar />
      </ErrorBoundary>
    </MasterTemplate>
  )
}
