import { loggerClient } from 'application/Services'
import { addMinutes, isPast } from 'date-fns'
import { CacheClient, CacheRecord } from 'domain/CacheClient'
import { LocalClient } from 'domain/LocalClient'

const logger = loggerClient.create('LocalCacheClient')

export class LocalCacheClient implements CacheClient {
  constructor(private localClient: LocalClient) {}

  /**
   * Insert cache value into cache
   *
   * @param key
   * @param duration
   * @param value
   * @returns
   */
  put<T>(key, minutes: number, value: T): CacheRecord<T> {
    logger.info(`[put] caching for key ${key}`, value)

    const expirationDate: Date = addMinutes(new Date(), minutes)
    logger.info(
      `[put] expiration date to ${key} set to ${expirationDate.toISOString()}`
    )

    const cacheItem = this.localClient.saveItem<CacheRecord<T>>(key, {
      expiresAt: expirationDate.toISOString(),
      value
    })

    logger.info(`[put] item saved on ${key}: `, cacheItem)

    return cacheItem
  }

  /**
   * Get cached value
   *
   * @param key
   * @returns
   */
  get<T>(key): T | null {
    const cacheData = this.localClient.getItem<CacheRecord<T>>(key)
    if (!cacheData) {
      logger.info(`[get] could not find cache data for ${key}`)
      return null
    }

    // If cache is expired, save cache and return value
    if (isPast(new Date(cacheData.expiresAt))) {
      logger.info(`[get] cache expired at ${cacheData.expiresAt}`)
      return null
    }

    logger.info(`[get] cache value for ${key}: `, cacheData.value)
    return cacheData.value
  }

  /**
   * Remember a value for a specific time
   *
   * @param key
   * @param duration
   * @param factory
   * @returns
   */
  async remember<T>(
    key: string,
    duration: number,
    factory: () => Promise<T>
  ): Promise<T> {
    const cacheData = this.localClient.getItem<CacheRecord<T>>(key)

    // If no cache was found, save cache and return value
    if (!cacheData) {
      const value = await factory()
      return this.put(key, duration, value).value
    }

    // If cache is expired, save cache and return value
    if (isPast(new Date(cacheData.expiresAt))) {
      const value = await factory()
      return this.put(key, duration, value).value
    }

    // Return cached value
    return cacheData.value
  }
}
