import { Config } from 'application'
import {
  BaseFindOneRepository,
  BaseSaveRepository
} from 'domain/BaseRepository'
import { LocalGetItem, LocalSaveItem } from 'domain/LocalClient'
import { CookieConfig } from '../Models/CookieConfig'
import { CookieConsents } from '../Models/CookieConsents'

export class CookieRepository
  implements
    BaseFindOneRepository<CookieConfig>,
    BaseSaveRepository<CookieConfig>
{
  /**
   * CookieRepository constructor method
   *
   * @param localClient
   */
  constructor(private localClient: LocalGetItem & LocalSaveItem) {}

  /**
   * Get current saved cookie
   *
   * @returns
   */
  async findOne(): Promise<CookieConfig> {
    const item = this.localClient.getItem<CookieConfig>(Config.cookieNoticeName)

    if (item) {
      return item
    }

    return this.save(
      new CookieConfig({
        showCookieNotice: true,
        cookies: new CookieConsents({
          proposals: true,
          search: true,
          marketing: true,
          experience: true
        })
      })
    )
  }

  /**
   * Save cookie on local
   *
   * @param item
   * @returns
   */
  async save(item: CookieConfig): Promise<CookieConfig> {
    return this.localClient.saveItem(Config.cookieNoticeName, item)
  }
}
