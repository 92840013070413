import { Config } from 'application'
import { Faker } from 'faker'

import { FakableClass, FakableField } from 'fake-class'

export enum SitePlatform {
  DESKTOP = 'desktop',
  MOBILE = 'mobile'
}

export enum BrandOrigin {
  WEBMOTORS = 'webmotors',
  BUSCACARRO = 'buscacarro',
  MEUCARANGO = 'meucarango',
  COMPREAUTO = 'compreauto'
}
@FakableClass()
export class Site {
  /**
   * Site domain
   * Armazena o domínio do website.
   */
  @FakableField(() => Faker.string(10))
  domain = Config.baseUrl

  /**
   * Site country
   * Armazena o país do website.
   */
  @FakableField(() => Faker.string(10))
  country = 'brasil'

  /**
   * Site server
   * Armazena o servidor do website.
   */
  @FakableField(() => Faker.string(10))
  server = 'web'

  /**
   * Site environment
   * Armazena o ambiente do website.
   */
  @FakableField(() => Faker.string(10))
  environment = 'comprar'

  /**
   * Site subEnvironment
   * Armazena o subambiente do website.
   */
  @FakableField(() => Faker.string(10))
  subEnvironment = 'comprar'

  /**
   * Site tmsVersion
   * Armazena o número da versão do tms.
   */
  @FakableField(() => Faker.string(10))
  tmsVersion!: string

  /**
   * Site clientType
   * Armazena o tipo de pessoa para qual o website foi construído.
   */
  @FakableField(() => Faker.string(10))
  clientType = 'pf'

  /**
   * Site platform
   * Armazena o tipo de dispositivo do website.
   */
  @FakableField(() => Faker.string(10))
  platform!: string

  /**
   * Site brandOrigin
   * Armazena por qual site o usuário iniciou a jornada. (QueryString = &origem)
   */
  @FakableField(() => Faker.string(10))
  brandOrigin: BrandOrigin = BrandOrigin.WEBMOTORS

  constructor(params: Partial<Site>) {
    Object.assign(this, params)
  }
}
