import { OfferRepository } from 'domain/Search/Repositories/OfferRepository'
import { OfferService } from 'domain/Search/Services/OfferService'
import { SearchResultToOfferTransformer } from 'domain/Search/Transformers'
import { axiosClient } from '../Adapters/AxiosClient'
import { favoriteService } from './FavoriteService'
import { searchService } from './SearchService'

export const searchResultToOfferTransformer =
  new SearchResultToOfferTransformer()

export const offerRepository = new OfferRepository(
  axiosClient,
  searchResultToOfferTransformer
)

export const offerService = new OfferService(
  searchService,
  offerRepository,
  favoriteService
)
