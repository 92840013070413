import { favoriteService } from 'application'
import { useAuth } from 'presentation/hooks'
import { useCallback, useEffect, useState } from 'react'

export function useFavoritesCount(): number {
  const [favoritesCount, setFavoritesCount] = useState<number>(0)
  const { user } = useAuth()

  const fetchFavoritesCount = useCallback(async () => {
    if (!user) return

    try {
      const { count } = await favoriteService.get()
      if (count > 0) {
        setFavoritesCount(count)
      } else {
        resetFavoritesCount()
      }
    } catch (err) {
      console.log('error:', err)
    }
  }, [])

  const resetFavoritesCount = useCallback(() => {
    setFavoritesCount(0)
  }, [])

  useEffect(() => {
    if (!user) resetFavoritesCount()
  }, [user])

  useEffect(() => {
    fetchFavoritesCount()

    /**
     * Listen for the "favoritesChanged" and "logout" event and trigger a re-fetch of the favorites count
     *
     */

    window.addEventListener('logout', resetFavoritesCount)
    window.addEventListener('favoritesChanged', fetchFavoritesCount)

    /**
     * Clean up the events listener when the component unmounts
     *
     */

    return () => {
      window.removeEventListener('logout', resetFavoritesCount)
      window.removeEventListener('favoritesChanged', fetchFavoritesCount)
    }
  }, [fetchFavoritesCount, resetFavoritesCount])

  return favoritesCount
}
