import { Faker } from 'faker'

import { FakableClass, FakableField } from 'fake-class'
import { v4 as uuid } from 'uuid'

export enum SearchItemSegment {
  CAR = 'car',
  BIKE = 'bike'
}

@FakableClass()
export class SearchItem {
  /**
   * SearchItem id
   *
   */
  id: string

  /**
   * SearchItem brand
   *
   */
  @FakableField(() => Faker.string(18))
  brand: string

  /**
   * SearchItem model
   *
   */
  @FakableField(() => Faker.string(18))
  model: string | null = null

  /**
   * SearchItem segment
   *
   */
  segment: SearchItemSegment = SearchItemSegment.CAR

  /**
   * SearchItem name
   *
   */
  @FakableField(() => Faker.string(18))
  name: string

  /**
   * SearchItem location
   *
   */
  @FakableField(() => 'Brasil')
  location: string

  /**
   * SearchItem url
   *
   */
  url: string

  /**
   * SearchItem items count
   *
   */
  count = 0

  /**
   * Item constructor
   *
   * @param params
   */
  constructor(params: Partial<SearchItem>) {
    this.id = uuid()
    Object.assign(this, params)

    const model = this.model ? ` ${this.model}` : ''

    this.name = params?.name ?? `${this.brand}${model}`
  }
}
