import { uriService } from 'application/Services/UriService'
import { DataParser } from 'domain/DataParser'
import {
  CityInputFromLocal,
  ICityParser
} from 'domain/Location/Repository/Contracts/ICityParser'
import { SearchItem, SearchItemSegment } from '../Models/SearchItem'

export type CookieSearchDto = {
  route: string
  query: string
}

export type QueryResult = {
  marca1: string
  modelo1: string
  estadocidade: string
}

export class CookieSearchParser
  implements DataParser<CookieSearchDto, SearchItem>
{
  constructor(private cityParser: ICityParser<CityInputFromLocal>) {}

  /**
   * Parse search item from cookie
   *
   * @param input
   * @returns
   */
  parse(input: CookieSearchDto): SearchItem {
    const params = uriService.queryStringToObject<QueryResult>(input.query)
    const { estadocidade } = params

    const city =
      !estadocidade || estadocidade === 'estoque'
        ? null
        : this.cityParser.parse({ estadocidade })

    const location = city ? city.toString() : 'Brasil'
    const url = `/${input.route}${input.query}`
    const segment = input.route.includes('motos')
      ? SearchItemSegment.BIKE
      : SearchItemSegment.CAR

    return new SearchItem({
      url,
      location,
      segment,
      brand: params.marca1,
      model: params.modelo1
    })
  }
}
