import { Config } from 'application'
import window from 'global/window'

export type CreateUrlConfig = {
  params?: {
    [x: string]: any
  }
  baseUrl?: string
}

export class UriService {
  /**
   * Parses a query string into js object
   *
   * @param queryString
   * @returns
   */
  queryStringToObject<T>(queryString: string): T {
    if (!queryString) {
      return {} as T
    }

    if (queryString.startsWith('?')) {
      queryString = queryString.slice(1)
    }

    let result: T
    try {
      result = Object.fromEntries(
        new URLSearchParams(queryString)
      ) as unknown as T
    } catch {
      result = {} as unknown as T
    }

    return result
  }

  /**
   * Parses an object to query string
   *
   * @param obj
   * @returns
   */
  objectToQueryString(obj) {
    const str: string[] = []
    for (const p in obj) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`)
    }

    return str.join('&')
  }

  /**
   * Create an url
   *
   * @param path
   * @param config
   * @returns
   */
  createUrl(path: string, config?: CreateUrlConfig): string {
    const baseUrl = config?.baseUrl || Config.baseUrl

    let searchParams = ''
    if (config?.params) {
      searchParams = `?${this.objectToQueryString(config.params)}`
    }

    return `${baseUrl}${path}${searchParams}`
  }

  /**
   * Remove query param from url
   *
   * @param param
   */
  removeQueryParam(param: string) {
    const params = new URLSearchParams(window.location.search)

    params.delete(param)

    window.history.replaceState(
      {},
      document.title,
      `${window.location.pathname}?${params.toString()}`
    )
  }
}
