import range from 'lodash/range'
import { Box, ScrollItem, ScrollView } from 'presentation/components'
import { NewsCard } from '../../Cards'
import * as S from './NewsGroup.styles'
import { useNewsGroupController } from './NewsGroupController'

export function NewsGroup() {
  const { data, loading, perPage } = useNewsGroupController()

  if (!data) {
    return null
  }

  return (
    <S.Container>
      <Box mb={4}>
        <S.Typography variant="h2" color="textSecondary" mb={2}>
          Notícias
        </S.Typography>
        {data?.length > 0 && !loading && (
          <ScrollView perPage={perPage}>
            {data.map((news, index) => (
              <ScrollItem key={`News-${index + 1}`} size="290px">
                <NewsCard dataQA={`news_card_${index + 1}`} news={news} />
              </ScrollItem>
            ))}
          </ScrollView>
        )}
        {loading && (
          <ScrollView perPage={perPage}>
            {range(0, 6).map((index) => (
              <ScrollItem key={index} size="290px">
                <S.Skeleton
                  key={`newsGroup_skeleton_${index}`}
                  data-testid="News_Skeleton"
                  variant="rectangular"
                  height={290}
                />
              </ScrollItem>
            ))}
          </ScrollView>
        )}
      </Box>
    </S.Container>
  )
}
