import { isClientSide } from 'presentation/hooks/use-is-client-side'

const onClientSide = isClientSide()

/**
 * Checks if the timestamp stored in the local storage with the given key has expired.
 * The expiration time is calculated based on the provided hours, minutes, and seconds.
 * If you want to check the expiration by seconds only, for example, set hours and minutes to 0 and provide the desired value for seconds.
 * @param key - The key used to store the timestamp in the local storage.
 * @param hours - The number of hours for the expiration time (default is 24).
 * @param minutes - The number of minutes for the expiration time (default is 60).
 * @param seconds - The number of seconds for the expiration time (default is 60).
 * @returns True if the timestamp has expired, false otherwise.
 */
export function isLocalStorageTimestampExpired({
  key,
  hours = 24,
  minutes = 60,
  seconds = 60
}: {
  key: string
  hours?: number
  minutes?: number
  seconds?: number
}): boolean {
  const hoursInMilliseconds = hours * 60 * 60 * 1000
  const minutesInMilliseconds = minutes * 60 * 1000
  const secondsInMilliseconds = seconds * 1000
  const expirationMilliseconds =
    hoursInMilliseconds + minutesInMilliseconds + secondsInMilliseconds
  const storedTimestamp = onClientSide
    ? parseInt(window.localStorage.getItem(key) ?? '0', 10)
    : 0
  const currentTimestamp = Date.now()
  const isExpired = currentTimestamp - storedTimestamp >= expirationMilliseconds

  if (isExpired && onClientSide) {
    window.localStorage?.removeItem(key)
  }
  return isExpired
}
