import { CookieConsents } from './CookieConsents'

export class CookieConfig {
  /**
   * CookieConfig showCookieNotice
   *
   */
  showCookieNotice!: boolean

  /**
   * CookieConfig cookies
   *
   */
  cookies!: CookieConsents

  /**
   * Constructor method
   *
   * @param params
   */
  constructor(params: Partial<CookieConfig>) {
    Object.assign(this, params)
  }
}
