import { Environment } from '../Environment'
import { DefaultEnvironment } from './DefaultEnvironment'

export class ProductionEnvironment
  extends DefaultEnvironment
  implements Environment
{
  /**
   * BaseUrl of production requests
   *
   */
  baseUrl = 'https://www.webmotors.com.br'

  /**
   * PublicUrl of staging requests
   *
   */
  publicUrl = 'https://www.webmotors.com.br/novo-portal/static'

  /**
   * BaseUrl used on server side rendering
   *
   */
  baseServerUrl = 'https://yur5i9svf7.execute-api.us-east-1.amazonaws.com/prd'

  /**
   * Auth Cookie Name on production environment
   *
   */
  authCookieName = 'webmotors.jwt.token'

  /**
   * Cookie domain on blue environment
   *
   */
  cookieDomain = '.webmotors.com.br'

  /**
   * Login route
   *
   */
  loginRoute = 'https://webmotors.com.br/login'

  /**
   * Show variables from core web vitals in console
   */
  showCoreWebVitalsConsole = false

  /**
   * Search
   *
   */
  abTest = {
    searchHome: {
      key: 'prd_ab_test_search'
    }
  }
}
