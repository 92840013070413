import { Faker } from 'faker'

import { FakableClass, FakableField } from 'fake-class'

@FakableClass()
export class ProductWeb {
  /**
   * ProductWeb id
   *
   */
  @FakableField(() => Faker.string(10))
  id!: string

  /**
   * ProductWeb title
   *
   */
  @FakableField(() => Faker.string(10))
  title!: string

  /**
   * ProductWeb tagType
   *
   */
  @FakableField(() => 'h3')
  tagType!: string
  /**
   * ProductWeb data_qa
   *
   */
  @FakableField(() => Faker.string(10))
  data_qa!: string

  /**
   * ProductWeb url
   *
   */
  @FakableField(() => Faker.url())
  url!: string

  /**
   * ProductWeb attributes
   *
   */
  @FakableField(() => ({
    rel: 'noreferrer',
    target: '_blank'
  }))
  attributes!: {
    rel: 'noreferrer' | 'nofollow' | 'noopener'
    target: '_blank' | '_self'
    onClick?: () => void
  }

  /**
   * ProductWeb constructor
   *
   * @param params
   */
  constructor(params: Partial<ProductWeb>) {
    Object.assign(this, params)
  }
}
