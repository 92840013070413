import {
  CookieControlModal,
  CookieWarn,
  FavoriteModal,
  Footer,
  Header,
  LoginMobileModal,
  ToAppMobileModal
} from 'presentation/components'
import { Main } from './MasterTemplate.styles'

export function MasterTemplate({ children }) {
  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
      <CookieWarn />
      <CookieControlModal />
      <FavoriteModal />
      <LoginMobileModal />
      <ToAppMobileModal />
    </>
  )
}
