import { BaseRepository, QueryParams } from 'domain/BaseRepository'
import { DataParser } from 'domain/DataParser'
import { HttpGetClient } from 'domain/HttpClient'
import { Offer } from '../Models/Offer'
import { SearchItemSegment } from '../Models/SearchItem'

export type OfferDto = Offer & {
  stamps: {
    feiroes: {
      megaFeirao: boolean
    }
    superPreco: boolean
    carDelivery: boolean
    trocaComTroco: boolean
  }
}

export type SearchResult = {
  UniqueId: number
  Media: {
    Photos: {
      PhotoPath: string
      Order: number
    }[]
  }
  Specification: {
    CubicCentimeter: number
    Make: {
      Value: string
    }
    Model: {
      Value: string
    }
    YearModel: string | number
    YearFabrication: string | number
    Odometer: number
    Version?: {
      Id: string
      Value: string
    }
  }
  Prices: {
    Price: number
    SearchPrice: number
  }
  Seller: {
    City: string
    State: string
  }
}

export class OfferRepository implements BaseRepository<Offer> {
  /**
   * OfferRepository constructor
   *
   * @param httpClient
   */
  constructor(
    private httpClient: HttpGetClient,
    private parser: DataParser<SearchResult[], Offer[]>
  ) {}

  /**
   * Get all offers from url
   *
   * @param queryParams
   * @returns
   */
  async getAll(queryParams?: QueryParams | undefined): Promise<Offer[]> {
    const result = await this.httpClient.get<{
      SearchResults: SearchResult[]
    }>(`/search/${queryParams?.path}`, {
      queryParams: queryParams?.querystring || {}
    })

    return this.parser.parse(result?.SearchResults || []).map(
      (offer) =>
        new Offer({
          ...offer,
          segment: queryParams?.path as SearchItemSegment
        })
    )
  }
}
