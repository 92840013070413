import { loggerClient } from 'application/Services'
import { BaseRepository, QueryParams } from 'domain/BaseRepository'
import { HttpGetClient } from 'domain/HttpClient'
import { News } from '../Model/News'

const logger = loggerClient.create('NewsRepository')

export type NewsDto = {
  Autor: string
  AutorAvatar: string
  Categoria: string
  Titulo: string
  Url: string
  UrlPrincipalImage: string
}

export class NewsRepository implements BaseRepository<News> {
  constructor(private httpGetClient: HttpGetClient) {}

  /**
   * Get all news from dataSource
   *
   * @param queryParams
   * @returns
   */
  async getAll(queryParams: QueryParams): Promise<News[]> {
    const result = await this.httpGetClient.get<NewsDto[]>(
      `/wm1/news/${queryParams?.path ?? ''}`,
      {
        queryParams: queryParams?.querystring || {}
      }
    )

    const mappedResults = result.map(
      (item) =>
        new News({
          author: item.Autor,
          authorAvatar: item.AutorAvatar,
          category: item.Categoria,
          title: item.Titulo,
          urlImage: item.UrlPrincipalImage,
          url: item.Url
        })
    )

    logger.info('[getAll] mappedResults: ', mappedResults)

    return mappedResults
  }
}
