import styled, { css } from 'styled-components'

export const ButtonGroupContainer = styled.div`
  display: inline-flex;
  border: 1px solid #edecf2;
  border-radius: 8px;
  overflow: hidden;
  height: 40px;
  margin-bottom: 16px;
`

export const Button = styled.button<{ active: boolean }>`
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.palette.secondary.main};
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.palette.primary.main};
      color: #fff;
    `}
`
