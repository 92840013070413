import slugify from 'slugify'

export function applySlugify(input: string, replacement = '-'): string {
  if (!input) {
    return ''
  }
  return slugify(input, {
    lower: true,
    replacement
  })
}
