import * as S from './ProductWebCard.styles'
import { Props } from './ProductWebCard.types'

export function ProductWebCard({
  title,
  tag,
  url,
  id,
  data_qa,
  attributes
}: Props) {
  const CustonTag = tag as keyof JSX.IntrinsicElements
  return (
    <S.ProductWebCard
      draggable="false"
      href={url}
      id={id}
      data-qa={data_qa}
      data-testid="ProductWebCard"
      {...attributes}
    >
      <CustonTag>{title}</CustonTag>
    </S.ProductWebCard>
  )
}
