import styled from 'styled-components'

export const Control = styled.button`
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.25));
  position: absolute;
  transition: 0.5s;
  height: 100%;
  width: 40px;
  z-index: 11;
  opacity: 0;
  top: 0px;

  &.left-control {
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 0,
      rgba(0, 0, 0, 0.25)
    );
    left: 0px;
  }

  &.right-control {
    right: 0px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    opacity: 1;
  }
`

export const Indicators = styled.div`
  justify-content: center;
  align-items: center;
  position: absolute;
  display: flex;
  height: 16px;
  bottom: 0px;
  width: 100%;
`

export const Indicator = styled.button`
  border-radius: 50%;
  background: #ecedf2;
  height: 5px;
  margin: 3px;
  width: 5px;

  &.actived {
    background: ${({ theme }) => theme.palette.primary.main};
  }

  &:hover {
    cursor: pointer;
  }
`

export const Inner = styled.div`
  white-space: nowrap;
  position: absolute;
  overflow: hidden;
  padding: 0px;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
`

export const Container = styled.div<{ height: string }>`
  height: ${({ height }) => height};
  background: #aeaeba;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;

  &:hover ${Control} {
    opacity: 1;
  }
`
