import { Faker } from 'faker'

import { FakableClass, FakableField } from 'fake-class'

@FakableClass()
export class User {
  /**
   * User id
   *
   */
  @FakableField(() => Faker.uuid())
  id: string

  /**
   * User name
   *
   */
  @FakableField(() => Faker.firstName())
  name: string

  /**
   * User email
   *
   */
  @FakableField(() => Faker.email())
  email: string

  /**
   * User phoneNumber
   *
   */
  @FakableField(() => Faker.phoneNumber())
  phoneNumber: string

  /**
   * User authenticationToken
   *
   */
  @FakableField(() => Faker.string(255))
  authenticationToken: string

  /**
   * Constructor method
   *
   * @param params
   */
  constructor(params: Partial<User>) {
    Object.assign(this, params)
  }
}
