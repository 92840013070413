import { Theme } from '@mui/material'
import { fairsService, featureFlagService } from 'application'
import blackFairTheme from './blackFairTheme'
import defaultTheme from './defaultTheme'

export enum Themes {
  default = 'defaultTheme',
  blackFair = 'blackFairTheme'
}

export async function getTheme(): Promise<Theme> {
  const featureFlag = await featureFlagService.isFeatureFlagActive()
  const blackFairFromApi = await fairsService.canIUseBlackFairTheme()
  const currentTheme =
    blackFairFromApi || featureFlag ? Themes.blackFair : Themes.default
  return themeMap[currentTheme]
}

const themeMap: { [key: string]: Theme } = {
  defaultTheme,
  blackFairTheme
}
