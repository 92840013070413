import { BaseFindOneRepository } from 'domain/BaseRepository'
import { LocalGetItem } from 'domain/LocalClient'
import { v4 as uuid } from 'uuid'
import { OfficialStore, Store } from '../Model/OfficialStore'

export type LocalOfficialStoreDto = {
  position: string
  stores: {
    title: string
    background: string
    url: string
    showUntil: string
  }[]
}

export class LocalOfficialStoreRepository
  implements BaseFindOneRepository<OfficialStore>
{
  constructor(private localClient: LocalGetItem) {}

  /**
   * Get items from local repository
   *
   * @returns
   */
  async findOne(): Promise<OfficialStore> {
    const result = this.localClient.getItem<LocalOfficialStoreDto>(
      'wbFairOfficialStores'
    )

    if (!result) return { position: '', stores: [] }

    const stores =
      result.stores.map(
        (item) =>
          new Store({
            id: uuid(),
            title: item.title,
            url: item.url,
            mobileCard: item.background,
            desktopCard: item.background
          })
      ) ?? []

    return { position: result.position ?? '', stores }
  }
}
