import { Config } from 'application'
import { loggerClient } from 'application/Services'
import { BaseRepository } from 'domain/BaseRepository'
import { FavoriteService } from 'domain/Favorite'
import { Offer } from '../Models/Offer'
import { SearchService } from './SearchService'

const logger = loggerClient.create('OfferService')

export class OfferService {
  /**
   * OfferService constructor
   *
   * @param searchService
   * @param offerRepository
   */
  constructor(
    private searchService: SearchService,
    private offerRepository: BaseRepository<any>,
    private favoriteService: FavoriteService
  ) {}

  /**
   * Get all recommended offers
   *
   * @returns
   */
  async getAllRecommended(): Promise<Offer[]> {
    const lastSearch = await this.searchService.getLastSearch()
    if (!lastSearch) {
      logger.info(
        '[getAllRecommended] Skipping recommended because no last search was found'
      )
      return []
    }

    // Load all available favorites
    const favoritesResult = await this.favoriteService.get()
    logger.info('[getAllRecommended] favorites found: ', favoritesResult)

    // Load offers from url
    const url = Config.baseUrl
    const offers = await this.offerRepository.getAll({
      path: lastSearch.segment,
      querystring: {
        url: `${url}${lastSearch.url}&media=com fotos`,
        displayPerPage: 10,
        actualPage: 1,
        order: 1
      }
    })
    logger.info('[getAllRecommended] offers found: ', offers)

    // Check if this offer was favorited
    const newOffers = offers.map((offer: Offer) => {
      for (const favorite of favoritesResult.favorites) {
        if (favorite.advertiseId === offer.id) {
          offer.wasFavorited = true
          offer.favorite = favorite
        }
      }

      return offer
    })
    logger.info('[getAllRecommended] offers mapped: ', offers)

    return newOffers
  }
}
