import groupBy from 'lodash/groupBy'
import values from 'lodash/values'
import { City } from '../../Model/City'
import { State } from '../../Model/State'
import { CityDto, IStateParser, StateDto } from '../Contracts/IStateParser'

export enum states {
  ac = 'Acre',
  al = 'Alagoas',
  am = 'Amazonas',
  ap = 'Amapá',
  ba = 'Bahia',
  ce = 'Ceará',
  df = 'Distrito Federal',
  es = 'Espírito Santo',
  go = 'Goiás',
  ma = 'Maranhão',
  mg = 'Minas Gerais',
  ms = 'Mato Grosso do Sul',
  mt = 'Mato Grosso',
  pa = 'Pará',
  pb = 'Paraíba',
  pe = 'Pernambuco',
  pi = 'Piauí',
  pr = 'Paraná',
  rj = 'Rio de Janeiro',
  rn = 'Rio Grande do Norte',
  ro = 'Rondônia',
  rr = 'Roraima',
  rs = 'Rio Grande do Sul',
  sc = 'Santa Catarina',
  sp = 'São Paulo',
  se = 'Sergipe',
  to = 'Tocantins'
}

export class StateParser implements IStateParser {
  /**
   * Return stateAbbr complete name
   *
   * @param input:string
   * @returns string - stateName
   */
  stateAbbrToStateName(input: string): string {
    return states[input.toLowerCase()]
  }

  /**
   * Return state abbr by name
   *
   * @param input
   * @returns
   */
  stateNameToAbbr(input: string): string {
    for (const abbr in states) {
      const name = states[abbr]

      if (name.toLowerCase() === input.toLowerCase()) {
        return abbr.toUpperCase()
      }
    }

    return ''
  }

  /**
   * Parse StateDto into State model
   *
   * @param input: StateDto[]
   * @returns State[]
   */
  parse(input: StateDto[]): State[] {
    const groupedStates = groupBy(input, (stateDto) => {
      const cityDto: CityDto = JSON.parse(stateDto.Name)

      return cityDto.State.toLocaleLowerCase()
    })

    return values(groupedStates).map((stateDtos: StateDto[]) => {
      const state = new State({})

      for (const stateDto of stateDtos) {
        const cityDto: CityDto = JSON.parse(stateDto.Name)

        state.name = cityDto.State
        state.count = state.count + stateDto.Count
        state.abbr = cityDto.Abbr

        state.cities.push(
          new City({
            state,
            latitude: cityDto.Latitude,
            longitude: cityDto.Longitude,
            name: cityDto.City,
            count: stateDto.Count
          })
        )
      }

      return state
    })
  }
}
