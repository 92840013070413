import { LocalClient } from 'domain/LocalClient'
import { localStorage } from 'global/window'

export class LocalStorageClient implements LocalClient {
  /**
   * Get item from local storage
   *
   * @param key
   * @returns
   */
  getItem<T>(key: string): T | null {
    if (!localStorage) {
      return null
    }

    const rawValue: unknown = localStorage.getItem(key)
    if (!rawValue) {
      return null
    }

    try {
      return JSON.parse(rawValue as string)
    } catch (e) {
      return rawValue as T
    }
  }

  /**
   * Remove item from localstorage
   *
   * @param key
   * @returns
   */
  removeItem(key: string): boolean {
    if (!localStorage) {
      return false
    }

    localStorage.removeItem(key)
    return true
  }

  /**
   * Save item on local storage
   *
   * @param key
   * @param value
   * @returns
   */
  saveItem<T>(key: string, value: T): T {
    if (!localStorage) {
      return value
    }

    localStorage.setItem(key, JSON.stringify(value))
    return value
  }
}
