import { BaseSaveRepository } from 'domain/BaseRepository'
import { EventSend } from 'domain/EventClient'
import { LocalSaveItem } from 'domain/LocalClient'
import { HomeDataLayer } from '../Model/HomeDataLayer'

export class DataLayerHomeRepository
  implements BaseSaveRepository<HomeDataLayer>
{
  constructor(private eventClient: EventSend, private local: LocalSaveItem) {}

  async save(item: HomeDataLayer): Promise<HomeDataLayer> {
    const { eventType, ...rest } = item

    this.eventClient.send<Partial<HomeDataLayer>>(eventType, rest)
    this.local.saveItem('objDataLayer', rest)

    return item
  }
}
