import styled from 'styled-components'

export const BestsellerCard = styled.a`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.secondary.main};
  word-break: break-word;
  height: 224px;
  display: block;
  overflow: hidden;
  position: relative;

  & picture {
    position: absolute;
    right: 50%;
    transform: translate(50%, 0%);
  }
`

export const BestsellerTitle = styled.h3`
  text-transform: uppercase;
  padding: 19px 23px;
  font-size: ${({ theme }) => theme.typography.subtitle2.fontSize};
  font-weight: 600;
  line-height: 1.125rem;
  color: ${({ theme }) => theme.palette.grey[900]};
  & strong {
    font-size: 1.25rem;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.brand[600]};
    display: block;
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: ${({ theme }) => theme.typography.subtitle1.fontSize};
  }
`
