import { cookieService } from 'application'
import { CookieConsents } from 'domain/Cookie/Models/CookieConsents'
import { useEffect, useState } from 'react'

export function useCookieConsents() {
  const [consents, setConsents] = useState<CookieConsents | null>(null)

  async function fetchCookieConsents() {
    const cookieConfig = await cookieService.getCookieConsent()

    setConsents(cookieConfig.cookies)
  }

  useEffect(() => {
    fetchCookieConsents()

    document.addEventListener(
      '@CookieService:saveCookieConsent',
      fetchCookieConsents
    )

    return () =>
      document.removeEventListener(
        '@CookieService:saveCookieConsent',
        fetchCookieConsents
      )
  }, [])

  return consents
}
