import { Faker } from 'faker'

import { FakableClass, FakableField } from 'fake-class'

@FakableClass()
export class TrackingCode {
  /**
   * TrackingCode idcmp
   * Armazena o parâmetro dentro da query string "&idcmp". Ex: 's2:c2:sdadga87std812'
   */
  @FakableField(() => Faker.string(10))
  idcmp!: string

  /**
   * TrackingCode idcmpint
   * Armazena o parâmetro dentro da query string "&idcmpint". Ex: 's2:c4:iusdagiudhsaod'
   */
  @FakableField(() => Faker.string(10))
  idcmpint!: string

  /**
   * TrackingCode icid
   * Armazena o parâmetro dentro da query string "&icid".
   */
  @FakableField(() => Faker.string(10))
  icid!: string

  /**
   * TrackingCode lkid
   * Armazena o parâmetro dentro da query string "&lkid".
   */
  @FakableField(() => Faker.string(10))
  lkid!: string

  constructor(params: Partial<TrackingCode>) {
    Object.assign(this, params)
  }
}

@FakableClass()
export class Page {
  /**
   * Page flowType
   * Armazena o tipo de fluxo da página.
   */
  @FakableField(() => Faker.string(10))
  flowType = 'fluxo-comprar'

  /**
   * Page pageType
   * Armazena o tipo de página.
   */
  @FakableField(() => Faker.string(10))
  pageType = 'homepage'

  /**
   * Page pageName
   * Armazena o nome da página.
   */
  @FakableField(() => Faker.string(10))
  pageName = '/webmotors/comprar/homepage'

  /**
   * Page pageNameTier1
   * Armazena a primeira faixa do pageName.
   */
  @FakableField(() => Faker.string(10))
  pageNameTier1 = 'comprar'

  /**
   * Page pageNameTier2
   * Armazena a segunda faixa do pageName.
   */
  @FakableField(() => Faker.string(10))
  pageNameTier2 = 'homepage'

  /**
   * Page url
   * Armazena a URL completa da página.
   */
  @FakableField(() => Faker.string(10))
  url!: string

  /**
   * Page trackingCode
   * Armazena o parâmetro dentro da query string
   */
  trackingCode = new TrackingCode({})

  constructor(params: Partial<Page>) {
    Object.assign(this, params)
  }
}
