import { createTheme } from '@mui/material/styles'
import { COLORS } from 'constants/index'
import { buttonVariants } from './button.variants'
declare module '@mui/material/styles' {
  interface Palette {
    brand: {
      200: React.CSSProperties['color']
      400: React.CSSProperties['color']
      600: React.CSSProperties['color']
      800: React.CSSProperties['color']
      900: React.CSSProperties['color']
    }
    neutral: {
      light: React.CSSProperties['color']
    }
  }
  interface PaletteOptions {
    brand: {
      200: React.CSSProperties['color']
      400: React.CSSProperties['color']
      600: React.CSSProperties['color']
      800: React.CSSProperties['color']
      900: React.CSSProperties['color']
    }
  }
}

const baseTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          backgroundColor: '#e11138',
          color: '#ffffff',
          textTransform: 'none',
          borderRadius: '8px',
          padding: '16px 20px',
          fontSize: '1rem',
          lineHeight: 1.5,
          '&:hover': {
            backgroundColor: '#e11138',
            color: '#ffffff'
          }
        }
      },
      variants: buttonVariants
    },
    MuiCssBaseline: {
      styleOverrides: `
        * {
          margin: 0;
          padding: 0;
        }

        body{
          overflow-y: overlay;
        }

        a, button {
          text-decoration: none;
          cursor: pointer;
          color: inherit;
        }

        a:hover {
          color: inherit;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        button {
          background: none;
          border: none;
          font: inherit;

        }
      `
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true
      }
    }
  },
  shape: {
    borderRadius: 8
  },
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    h2: {
      fontSize: '1rem',
      fontWeight: 700
    },
    h3: {
      fontSize: '0.938rem',
      fontWeight: 600
    },
    body2: {
      fontSize: '0.75rem',
      fontWeight: 500,
      lineHeight: 1.5
    },
    caption: {
      fontSize: '0.625rem'
    }
  },
  palette: {
    background: {
      default: '#ecedf2'
    },
    text: {
      primary: COLORS.GRAY[900],
      secondary: COLORS.GRAY[800]
    },
    grey: {
      200: '#ecedf2',
      400: '#d6d6de',
      600: '#aeaeba',
      800: '#696977',
      900: '#2e2d37'
    },
    brand: {
      200: '#ff628c',
      400: '#ff3a64',
      600: '#e11138',
      800: '#bf0d2e',
      900: '#8c0a22'
    }
  },
  shadows: [
    'none',
    '0px 4px 8px 0px #2e2d3729',
    '0px 1px 2px 0px #2e2d371f',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)', //Default MUI
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)', //Default MUI
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)', //Default MUI
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)', //Default MUI
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)', //Default MUI
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)', //Default MUI
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)', //Default MUI
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)', //Default MUI
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)', //Default MUI
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)', //Default MUI
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)', //Default MUI
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)', //Default MUI
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)', //Default MUI
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)', //Default MUI
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)', //Default MUI
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)', //Default MUI
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)', //Default MUI
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)', //Default MUI
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)', //Default MUI
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)', //Default MUI
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)', //Default MUI
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)' //Default MUI
  ]
})

export default baseTheme
