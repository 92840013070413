import { City } from 'domain/Location/Model/City'
import { State } from 'domain/Location/Model/State'
import { CityInputFromRemote, ICityParser } from '../Contracts/ICityParser'
import { IStateParser } from '../Contracts/IStateParser'

export class CityParserFromRemote implements ICityParser<CityInputFromRemote> {
  constructor(private stateParser: IStateParser) {}

  /**
   * Parse XML String into Geolocation model
   *
   * @param input: string
   * @returns location: Geolocation
   */
  parse(input: CityInputFromRemote): City {
    const { data, coords } = input
    const abbr = data.response['edge-region']

    const location = new State({
      abbr: abbr.toUpperCase(),
      name: this.stateParser.stateAbbrToStateName(abbr)
    })

    const cityName = data.response['edge-city']
    const name = cityName.replace(/\w\S*/g, (w) =>
      w.replace(/^\w/, (c) => c.toUpperCase())
    )

    return new City({
      state: location,
      name,
      latitude: coords?.latitude,
      longitude: coords?.longitude
    })
  }
}
