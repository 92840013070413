import { useState } from 'react'
import * as S from './OfficialStoreCard.styles'

export function OfficialStoreCard({ title, urlImage, href, dataQA }) {
  const [imageLoaded, setImageLoaded] = useState(false)

  const handleImageLoad = () => {
    setImageLoaded(true)
  }

  return (
    <S.Card
      href={`${href}`}
      title={title}
      data-testid="Card_OfficialStore"
      data-qa={dataQA}
    >
      <S.ImageBox showShimmer={!imageLoaded}>
        <S.Image
          src={urlImage}
          data-testid="ImageOfficialStoreCard"
          alt={`${title} Official store card`}
          title={title}
          loading="lazy"
          onLoad={handleImageLoad}
        />
      </S.ImageBox>
    </S.Card>
  )
}
