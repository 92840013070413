import {
  Container as DefaultContainer,
  Typography as DSTypography
} from 'presentation/components'
import styled from 'styled-components'

export const Typography = styled(DSTypography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  ${(props) => props.theme.breakpoints.down('md')} {
    margin-left: ${(props) => props.theme.spacing(3)};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-left: ${(props) => props.theme.spacing(2)};
  }
`

export const Container = styled(DefaultContainer)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 0;
  }
`
