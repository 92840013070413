import { Config, locationService } from 'application'
import { City, State } from 'domain/Location'
import window from 'global/window'
import { isClientSide } from 'presentation/hooks/use-is-client-side'
import { removeAccents } from 'presentation/utils/strings/removeAccents'
import { useCallback, useEffect, useMemo, useState } from 'react'

export interface ResultState {
  name: string
  abbr: string
}

export interface ResultCity {
  name: string
  latitude: number
  longitude: number
  state: string
  abbr: string
}

enum FetchStatus {
  Idle = 'idle',
  Loading = 'loading',
  Loaded = 'loaded',
  Error = 'error'
}

export function useLocationsFilter(searchTerm: string, path: string) {
  const [allStates, setAllStates] = useState<State[]>([])
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(FetchStatus.Idle)
  const [error, setError] = useState<Error | null>(null)
  const resetError = useCallback(() => setError(null), [])
  const getInitialVariant = () => {
    return isClientSide()
      ? localStorage.getItem(Config.abTest.searchHome.key)
      : false
  }
  const [variant, setVariant] = useState(getInitialVariant())

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === Config.abTest.searchHome.key) {
        setVariant(e.newValue)
      }
    }

    if (isClientSide()) {
      window.addEventListener('storage', handleStorageChange)
      return () => window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    if (variant !== 'true') {
      return
    }

    let isCancelled = false

    const fetchAllStates = async () => {
      setFetchStatus(FetchStatus.Loading)
      setError(null)

      try {
        const states = await locationService.getAllStates({ path })

        if (!isCancelled) {
          setAllStates(states)
          setFetchStatus(FetchStatus.Loaded)
        }
      } catch (e) {
        if (!isCancelled) {
          setError(e as Error)
          setFetchStatus(FetchStatus.Error)
        }
      }
    }

    fetchAllStates()

    return () => {
      isCancelled = true
    }
  }, [path, resetError, variant])

  const filteredLocations = useMemo(() => {
    if (fetchStatus !== FetchStatus.Loaded) {
      return { statesMatchTerm: [], citiesMatchTerm: [] }
    }
    return createNewStructure(searchTerm, allStates)
  }, [searchTerm, allStates, fetchStatus])

  return { filteredLocations, fetchStatus, error, resetError }
}

function getAllCities(state: State, visitedStates = new Set<State>()): City[] {
  if (visitedStates.has(state)) return []
  visitedStates.add(state)

  const cities = state.cities || []
  return cities.reduce((allCities: City[], city: City) => {
    return allCities.concat(city, getAllCities(city.state, visitedStates))
  }, [])
}

function createNewStructure(
  stateName: string,
  data: State[]
): { statesMatchTerm: ResultState[]; citiesMatchTerm: City[] } {
  const normalizedStateName = removeAccents(stateName?.toLocaleLowerCase())

  const allCities = data.flatMap((state) => getAllCities(state))

  const relevantCities = allCities.filter((city) =>
    removeAccents(city.name.toLocaleLowerCase()).includes(normalizedStateName)
  )

  const uniqueCities = relevantCities.filter(
    (city, index, self) =>
      index ===
      self.findIndex(
        (c) => c.name === city.name && c.state.abbr === city.state.abbr
      )
  )

  const statesMatchTerm = data.filter((state) =>
    removeAccents(state.name.toLocaleLowerCase()).includes(normalizedStateName)
  )

  const citiesMatchTerm = uniqueCities

  return {
    statesMatchTerm: statesMatchTerm.map((state) => ({
      name: state.name,
      abbr: state.abbr
    })),
    citiesMatchTerm: citiesMatchTerm.map(
      (city) =>
        new City({
          name: city.name,
          latitude: city.latitude,
          longitude: city.longitude,
          range: city.range,
          state: new State({
            name:
              data.find((state) => state.abbr === city.state.abbr)?.name || '',
            abbr: city.state.abbr
          })
        })
    )
  }
}
