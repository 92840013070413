import styled from 'styled-components'
import * as S from '../ToAppMobileModal.styles'

export const Box = styled(S.Box)`
  path {
    fill: ${({ theme }) => theme.palette.grey[800]};
  }
`

export const Button = styled(S.Button)``

export const Typography = styled(S.Typography)``

export const Icon = styled(S.Icon)``

export const Container = styled(S.Container)`
  left: calc(50% - 320px / 2);
  width: 320px;
`

export const Content = styled(S.Content)`
  img:first-of-type {
    margin-top: 10px;
  }

  h4 {
    color: ${({ theme }) => theme.palette.grey[900]};
    font-size: 16px;
    font-weight: 600;
    margin: 16px 0;
  }

  p {
    max-width: 278px;
    font-size: 8px;
    margin-bottom: 20px;
  }
`
