import { City } from 'domain/Location/Model/City'
import { State } from 'domain/Location/Model/State'
import { CityInputFromLocal, ICityParser } from '../Contracts/ICityParser'
import { IStateParser } from '../Contracts/IStateParser'

export class CityParserFromLocal implements ICityParser<CityInputFromLocal> {
  constructor(private stateParser: IStateParser) {}

  /**
   * Parse XML String into Geolocation model
   *
   * @param input: string
   * @returns location: Geolocation
   */
  parse(input: CityInputFromLocal): City {
    const [stateName, ...cityPartials] = input.estadocidade.split('-')
    const cityName =
      cityPartials.length === 0 ? undefined : cityPartials.join('-')

    const state = new State({
      name: stateName?.trim(),
      abbr: this.stateParser.stateNameToAbbr(stateName.trim())
    })

    return new City({
      state,
      name: cityName?.trim(),
      latitude: input?.localizacao?.latitude,
      longitude: input?.localizacao?.longitude
    })
  }
}
