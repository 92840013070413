import * as Styles from './ScrollView.styles'
import { ScrollViewContext } from './ScrollViewContext'
import { useScrollViewController } from './ScrollViewController'

export function ScrollView({ perPage = 5, children }) {
  const scrollViewController = useScrollViewController(perPage)

  return (
    <ScrollViewContext.Provider value={scrollViewController.contextValues}>
      <Styles.Container>
        {scrollViewController.canGoBack && (
          <Styles.Control
            onClick={scrollViewController.handleBack}
            className="left-control"
          >
            <svg
              width="17px"
              height="17px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20.9987,11 L5.4127,11 L10.7057,5.707 C11.0967,5.316 11.0967,4.684 10.7057,4.293 C10.3147,3.902 9.6827,3.902 9.2917,4.293 L2.2917,11.292 C2.1997,11.385 2.1267,11.496 2.0757,11.618 C1.9747,11.862 1.9747,12.138 2.0757,12.382 C2.1267,12.504 2.1997,12.615 2.2917,12.708 L9.2917,19.707 C9.4867,19.902 9.7427,20 9.9987,20 C10.2547,20 10.5107,19.902 10.7057,19.707 C11.0967,19.316 11.0967,18.684 10.7057,18.293 L5.4127,13 L20.9987,13 C21.5507,13 21.9987,12.552 21.9987,12 C21.9987,11.448 21.5507,11 20.9987,11"></path>
            </svg>
          </Styles.Control>
        )}

        <Styles.Inner ref={scrollViewController.innerRef}>
          {children}
        </Styles.Inner>
        {scrollViewController.canGoForward && (
          <Styles.Control
            onClick={scrollViewController.handleForward}
            className="right-control"
          >
            <svg
              width="17px"
              height="17px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20.9987,11 L5.4127,11 L10.7057,5.707 C11.0967,5.316 11.0967,4.684 10.7057,4.293 C10.3147,3.902 9.6827,3.902 9.2917,4.293 L2.2917,11.292 C2.1997,11.385 2.1267,11.496 2.0757,11.618 C1.9747,11.862 1.9747,12.138 2.0757,12.382 C2.1267,12.504 2.1997,12.615 2.2917,12.708 L9.2917,19.707 C9.4867,19.902 9.7427,20 9.9987,20 C10.2547,20 10.5107,19.902 10.7057,19.707 C11.0967,19.316 11.0967,18.684 10.7057,18.293 L5.4127,13 L20.9987,13 C21.5507,13 21.9987,12.552 21.9987,12 C21.9987,11.448 21.5507,11 20.9987,11"></path>
            </svg>
          </Styles.Control>
        )}
      </Styles.Container>
    </ScrollViewContext.Provider>
  )
}
