import { Config } from 'application'
import { NumberFormatService } from 'application/Services/Format/NumberFormatService'
import { StringFormatService } from 'application/Services/Format/StringFormatService'
import { DataParser } from 'domain/DataParser'
import { Offer } from '../Models/Offer'
import { SearchResult } from '../Repositories/OfferRepository'

export class SearchResultToOfferTransformer
  implements DataParser<SearchResult[], Offer[]>
{
  parse(input: SearchResult[]): Offer[] {
    return input.map((offer) => {
      return new Offer({
        id: offer.UniqueId,
        make: offer.Specification.Make.Value,
        model: offer.Specification.Model.Value,
        version: offer.Specification.Version?.Value,
        price: NumberFormatService.money(offer.Prices.Price),
        year: `${offer.Specification.YearFabrication}/${offer.Specification.YearModel}`,
        travelledDistance: String(offer.Specification.Odometer),
        location: `${offer.Seller.City} - ${StringFormatService.getAbbr(
          offer.Seller.State
        )}`,
        photos: offer.Media.Photos.map(({ PhotoPath }) => {
          return `${Config.baseImageUrl}${PhotoPath.replace(/\\/g, '/').replace(
            /\/\//g,
            '/'
          )}`
        }),
        cubicCentimeter: offer.Specification.CubicCentimeter
      })
    })
  }
}
