import { Props } from 'presentation/components'
import styled from 'styled-components'

export const Container = styled.div<Props>`
  min-height: ${(props) => props.minHeight};

  &.hidden {
    display: none;
  }
`
