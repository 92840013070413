import { Config } from 'application/Config'

export class Logger {
  /**
   * Constructor method
   *
   * @param context
   */
  constructor(public context: string = 'GLOBAL') {}

  /**
   * Create a new logger context
   *
   * @param context
   * @returns
   */
  create(context: string) {
    return new Logger(context)
  }

  /**
   * Log a info message
   *
   * @param args
   * @returns
   */
  info(...args) {
    if (!Config.logging) {
      return
    }

    console.info(`[${this.context}]`, ...args)

    return this
  }

  /**
   * Log a error message
   *
   * @param args
   * @returns
   */
  error(...args) {
    if (!Config.logging) {
      return
    }

    const css =
      'font-size: 10px;color:#fafafa;background-color:#d00b2f;padding:3px 15px;border:2px solid #fafafa;'

    console.log(`%c[${this.context}]`, css, ...args)

    return this
  }
}
