import { StringFormatService } from 'application/Services/Format/StringFormatService'
import { NewsRepository, NewsService } from 'domain/News'
import { axiosClient } from '../Adapters/AxiosClient'
import { searchService } from './SearchService'

export const newsService: NewsService = new NewsService(
  searchService,
  new NewsRepository(axiosClient),
  new StringFormatService()
)
