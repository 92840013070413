import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from '@mui/material'
import { Config, fairsService } from 'application'
import { useAuth } from 'presentation/hooks'
import { isClientSide } from 'presentation/hooks/use-is-client-side'
import { isLocalStorageTimestampExpired } from 'presentation/utils'
import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

const useStyles = makeStyles({
  root: {
    zIndex: 1099
  }
})

export function useLoginMobileModalController() {
  const [isBlackFairActive, setIsBlackFairActive] = useState<
    boolean | undefined
  >(undefined)
  const [isMegaFairActive, setIsMegaFairActive] = useState<boolean | undefined>(
    undefined
  )
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [acceptedConsent, setAcceptedConsent] = useState<boolean>(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { user } = useAuth()
  const classes = useStyles()
  const onClientSide = isClientSide()

  useEffect(() => {
    const showModal = isLocalStorageTimestampExpired({
      key: Config.hideModalLoginStorageKey
    })

    if (showModal && onClientSide)
      window.localStorage.removeItem(Config.hideLoginNotificationStorageKey)

    setIsOpen(showModal && !isDrawerOpen)
  }, [])

  useEffect(() => {
    const handleDrawerEvent = (event: Event) => {
      const customEvent = event as CustomEvent

      setIsDrawerOpen(customEvent.detail.active)
    }

    document.addEventListener<any>('wb::drawer', handleDrawerEvent)

    return () =>
      document.removeEventListener<any>('wb::drawer', handleDrawerEvent)
  }, [])

  useEffect(() => {
    const handleConsent = (event: Event) => {
      const customEvent = event as CustomEvent

      setAcceptedConsent(customEvent.detail.value)
    }
    document.addEventListener('acceptedConsent', handleConsent)

    return () => {
      document.removeEventListener('acceptedConsent', handleConsent)
    }
  }, [])

  useEffect(() => {
    const checkBlackFairIsActive = async () => {
      try {
        const blackFairIsActive = Boolean(
          await fairsService.canIUseBlackFairTheme()
        )
        setIsBlackFairActive(blackFairIsActive)
      } catch (error) {
        setIsBlackFairActive(false)
      }
    }
    const checkMegaFairIsActive = async () => {
      try {
        const megaFairIsActive = Boolean(await fairsService.hasMegaFairActive())
        setIsMegaFairActive(megaFairIsActive)
      } catch (error) {
        setIsMegaFairActive(false)
      }
    }
    checkBlackFairIsActive()
    checkMegaFairIsActive()
  }, [])

  function handleModalClose() {
    if (onClientSide) {
      window.localStorage.setItem(
        Config.hideModalLoginStorageKey,
        Date.now().toString()
      )
    }
    setIsOpen(false)
  }

  return {
    isMobile,
    isBlackFairActive,
    isMegaFairActive,
    acceptedConsent,
    user,
    isOpen,
    classes,
    handleModalClose,
    isDrawerOpen
  }
}
