import window from 'global/window'
import max from 'lodash/max'
import { useEffect, useMemo, useRef, useState } from 'react'

export type Slide = {
  index: number
  height: number
  display: boolean
}

export function useScrollViewController(perPage) {
  const [activedIndex, setActivedIndex] = useState(0)
  const [slides, setSlides] = useState<Slide[]>([])
  const [innerWidth, setInnerWidth] = useState(0)
  const innerRef = useRef<HTMLDivElement>(null)

  /**
   * All visible slides
   *
   */
  const visibleSlides = useMemo(
    () => slides.filter((slide) => slide.display),
    [slides]
  )

  /**
   * Current max height
   *
   */
  const maxHeight = useMemo(
    () => max(visibleSlides.map((s) => s.height)),
    [visibleSlides]
  )

  /**
   * Current slides count
   *
   */
  const slidesCount = useMemo(() => visibleSlides.length, [visibleSlides])

  /**
   * Current scroll offset
   *
   */
  const scrollOffset = useMemo(() => {
    return Math.ceil((activedIndex * innerWidth) / perPage)
  }, [activedIndex, innerWidth, perPage])

  /**
   * Indicates if can go forward
   *
   */
  const canGoForward = useMemo(() => {
    const totalIndexes = visibleSlides.length - perPage

    return totalIndexes > activedIndex
  }, [activedIndex, visibleSlides])

  /**
   * Indicates if can go back
   *
   */
  const canGoBack = useMemo(() => {
    return activedIndex > 0
  }, [activedIndex])

  /**
   * Values passed to context
   *
   */
  const contextValues = useMemo(() => {
    return { maxHeight, slidesCount, perPage, slides, visibleSlides, setSlides }
  }, [maxHeight, slidesCount, perPage, slides, visibleSlides, setSlides])

  /**
   * Handle back click
   *
   */
  function handleBack() {
    setActivedIndex(activedIndex - 1)
  }

  /**
   * Handle forward click
   *
   */
  function handleForward() {
    setActivedIndex(activedIndex + 1)
  }

  /**
   * Handle scroll animation
   *
   */
  useEffect(() => {
    if (!innerRef.current || typeof innerRef.current?.scrollTo !== 'function') {
      return
    }

    innerRef.current?.scrollTo({
      top: 0,
      left: scrollOffset,
      behavior: 'smooth'
    })
  }, [scrollOffset])

  /**
   * Handle set inner width on resize
   *
   */
  useEffect(() => {
    function listener() {
      setInnerWidth(innerRef.current?.clientWidth || 0)
    }
    setInnerWidth(innerRef.current?.clientWidth || 0)

    window.addEventListener('resize', listener)

    return () => window.removeEventListener('resize', listener)
  }, [visibleSlides])

  return {
    setActivedIndex,
    contextValues,
    handleForward,
    canGoForward,
    slidesCount,
    handleBack,
    maxHeight,
    setSlides,
    canGoBack,
    innerRef
  }
}
