import { useMediaQuery } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { newsService } from 'application'
import { useMemo } from 'react'
import { useTheme } from 'styled-components'

export function useNewsGroupController() {
  const { data, isLoading } = useQuery({
    queryKey: ['news'],
    queryFn: () => {
      return newsService.getAll()
    }
  })
  const theme = useTheme()
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('lg'))

  const perPage = useMemo(() => (isExtraLarge ? 6 : 4), [isExtraLarge])

  return {
    loading: isLoading,
    perPage,
    data
  }
}
