import { BaseRepository } from 'domain/BaseRepository'
import { HttpGetClient } from 'domain/HttpClient'
import { Category } from '../Model/Category'

export type CategoryDto = {
  Nome: string
  Url: string
  BannerDesktop: string
  BannerMobile: string
  CardMobile: string
  CardDesktop: string
  Categoria: number
  CorPrimaria: string
  CorSecundary: string
  DataInclusao: string
  Descricao: string
  NomeCategoria: string
}

export class RemoteCategoryRepository implements BaseRepository<Category> {
  constructor(private httpGetClient: HttpGetClient) {}

  /**
   * Get all available categories
   *
   * @returns
   */
  async getAll(): Promise<Category[]> {
    const result = await this.httpGetClient.get<CategoryDto[]>('/category')

    return result.map(
      (item) =>
        new Category({
          id: item.Categoria,
          url: item.Url,
          desktopBanner: item.BannerDesktop,
          mobileBanner: item.BannerMobile,
          mobileCard: item.CardMobile,
          desktopCard: item.CardDesktop,
          primaryColor: item.CorPrimaria,
          secondaryColor: item.CorSecundary,
          createdAt: new Date(item.DataInclusao),
          description: item.Descricao,
          title: item.NomeCategoria
        })
    )
  }
}
