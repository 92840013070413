import { slotService } from 'application'
import { loggerClient } from 'application/Services'
import { useCallback, useEffect } from 'react'

export type GoogleTagProps = {
  id: string
  onSlotFailed?: (slot: googletag.Slot) => void | Promise<void>
  onSlotLoad?: (slot: googletag.Slot) => void | Promise<void>
  [x: string]: any
}

const logger = loggerClient.create('GoogleTagSlot')

export function GoogleTagSlot({ children, ...props }: GoogleTagProps) {
  const { id, onSlotFailed, onSlotLoad } = props

  /**
   * Handle slot render
   *
   */
  const slotHandler = useCallback((event) => {
    const slot = slotService.getSlotById(id)
    if (!slot) {
      return
    }

    logger.info(
      '[slotHandler] received slot info: ',
      event.slot.getSlotElementId()
    )

    if (event.slot === slot.target) {
      if (!event.slot.getResponseInformation()) {
        onSlotFailed?.(event.slot)

        return
      }

      onSlotLoad?.(event.slot)
    }
  }, [])

  /**
   * Add event listener to the slots
   *
   */
  useEffect(() => {
    const handler = function () {
      googletag.pubads().addEventListener('slotResponseReceived', slotHandler)
    }

    googletag.cmd.push(handler)
  }, [])

  return <>{children}</>
}
