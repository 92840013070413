import { useEffect, useState } from 'react'

import { cookieService } from 'application'
import { CookieConfig } from 'domain/Cookie/Models/CookieConfig'
import { CookieConsents } from 'domain/Cookie/Models/CookieConsents'

export function useCookieWarn() {
  const [cookieConsent, setCookieConsent] = useState<CookieConfig | null>(null)

  /**
   * Handle click cookie consent
   *
   */
  async function handleCookieConsentClick() {
    const currentCookie = await cookieService.getCookieConsent()
    const cookieConfig = new CookieConfig({
      showCookieNotice: false,
      cookies: new CookieConsents({
        ...currentCookie.cookies
      })
    })
    await cookieService.saveCookieConsent(cookieConfig)
    setCookieConsent(cookieConfig)
  }

  /**
   * Get current cookie consent
   *
   */
  useEffect(() => {
    cookieService
      .getCookieConsent()
      .then((cookieConfig) => setCookieConsent(cookieConfig))
  }, [setCookieConsent])

  return {
    handleCookieConsentClick,
    cookieConsent
  }
}
