import { slotService } from 'application'
import { loggerClient } from 'application/Services'
import debounce from 'lodash/debounce'
import range from 'lodash/range'
import uniq from 'lodash/uniq'
import { GoogleTagSlot, ScrollItem, ScrollView } from 'presentation/components'
import { useLocation } from 'presentation/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import * as Styles from './Advertisement.styles'

export enum AdvertisementType {
  CARROS0KM = 'carros-0km',
  LOJAS = 'lojas'
}

export const CONFIG = {
  [AdvertisementType.CARROS0KM]: {
    adUnit: '/9764/HP/HP',
    key: 'carros0km',
    title: 'Carros 0km',
    id: 'car-advertisement',
    slot: 6
  },
  [AdvertisementType.LOJAS]: {
    adUnit: '/9764/HP/HP',
    key: 'lojasdestaques',
    title: 'Lojas com estoque',
    id: 'store-advertisement',
    slot: 24
  }
}

export type AdvertisementProps = {
  type: AdvertisementType
}

const logger = loggerClient.create('Advertisement')

export function Advertisement({ type }: AdvertisementProps) {
  const config = CONFIG[type]
  const { city } = useLocation()
  const [loadedSlots, setLoadedSlots] = useState<string[]>([])
  const slots = range(1, config.slot).map((item) => ({
    id: `${config.id}-${item}`,
    key: item
  }))

  const refresh = useCallback(
    debounce((targets, filter) => {
      return slotService.refresh(targets, filter)
    }, 100),
    []
  )

  // Get advertisement location targets if exists
  const targets = useMemo(
    function () {
      const targets: [string, string][] = []

      if (city) {
        targets.push(['city', city.toSlug('_')])
      }

      if (city?.state) {
        targets.push(['uf', city.state.abbr])
      }

      return targets
    },
    [city]
  )

  // Get current title
  const title = useMemo(
    function () {
      if (!city) return 'no Brasil'
      return city.name
        ? `em ${city.name} - ${city.state.abbr}`
        : `em ${city.state.name}`
    },
    [city]
  )

  // Check if there is slots loaded
  const hasSlots = useMemo(() => loadedSlots.length >= 1, [loadedSlots])

  /**
   * Remove slots that failed to load
   *
   * @param s
   */
  function handleSlotFailed(slot) {
    logger.info(
      '[handleSlotFailed] failed to load slot: ',
      slot.getSlotElementId()
    )

    setLoadedSlots((slots) => {
      return slots.filter((_slot) => {
        return _slot !== slot.getSlotElementId()
      })
    })
  }

  /**
   * Remove slots that failed to load
   *
   * @param s
   */
  function handleSlotLoaded(slot) {
    logger.info(
      '[handleSlotLoaded] loaded slot with success: ',
      slot.getSlotElementId()
    )

    setLoadedSlots((slots) => {
      return uniq([...slots, slot.getSlotElementId()])
    })
  }

  // Refresh slots by target
  useEffect(() => {
    logger.info('[refresh] targets updated: ', targets)
    refresh(targets, config.id)
  }, [targets])

  // Handle use effect on mount
  useEffect(() => {
    for (const slot of slots) {
      slotService.createSlot({
        adUnit: config.adUnit,
        htmlId: slot.id,
        targets: [config.key, slot.key]
      })
    }
  }, [])

  return (
    <Styles.Container
      data-testid="advertisement-container"
      data-qa="advertisement_container"
      className={hasSlots ? 'has-slots' : 'is-empty'}
    >
      {hasSlots && (
        <Styles.Typography
          data-testid="advertisement-title"
          variant="h2"
          color="textSecondary"
          mb={2}
        >
          {config.title} {title}
        </Styles.Typography>
      )}

      <ScrollView perPage={5}>
        {slots.map((item) => (
          <ScrollItem
            data-qa={item.id.replace('-', '_')}
            display={loadedSlots.some((slot) => slot === item.id)}
            key={item.key}
            size="300px"
            dynamic={true}
          >
            <GoogleTagSlot
              id={item.id}
              onSlotFailed={handleSlotFailed}
              onSlotLoad={handleSlotLoaded}
            >
              <Styles.Item
                id={item.id}
                className={
                  loadedSlots.some((slot) => slot === item.id)
                    ? 'has-slots'
                    : 'is-empty'
                }
              />
            </GoogleTagSlot>
          </ScrollItem>
        ))}
      </ScrollView>
    </Styles.Container>
  )
}
