import { loggerClient } from 'application/Services'
import { Slot } from '../Models/Slot'

const logger = loggerClient.create('SlotService')

export class SlotService {
  /**
   * All registered slots
   *
   */
  slots: Map<string, Slot> = new Map()

  /**
   * Get all targets from slots
   *
   */
  get slotTargets() {
    return Array.from(this.slots, ([, value]) => value.target)
  }

  /**
   * Gets a slot by id
   *
   * @param id
   * @returns
   */
  getSlotById(id: string) {
    return this.slots.get(id)
  }

  /**
   * Create a new slot
   *
   * @param slot
   * @returns
   */
  createSlot(slot): Slot {
    logger.info(`[createSlot] creating slot: ${slot.htmlId}`)

    const slotInstanceMemory = this.slots.get(slot.htmlId)
    if (slotInstanceMemory) {
      logger.info(`[createSlot] found slot ${slot.htmlId} in memory`)
      return slotInstanceMemory
    }

    const slotInstance = new Slot(slot)

    this.slots.set(slot.htmlId, slotInstance)

    logger.info(`[createSlot] created slot ${slot.htmlId}`)

    return slotInstance
  }

  /**
   * Refresh all slots
   *
   * @param targets
   */
  refresh(targets: [string, string][] = [], filter = '') {
    logger.info('[refresh] refresing slots with filter: ', filter)

    const slotTargets = this.slotTargets.filter((slot) => {
      if (!slot) return
      return slot.getSlotElementId().includes(filter)
    })

    if (slotTargets.length === 0) {
      logger.info('[refresh] not slots found to refresh')
      return
    }

    logger.info(
      '[refresh] refreshing slots: ',
      slotTargets.map((slot) => slot.getSlotElementId())
    )

    googletag.cmd.push(() => {
      googletag.pubads().clear(slotTargets)
      googletag.pubads().clearTargeting()

      logger.info('[refresh] refresh contents with targets: ', targets)

      for (const target of targets) {
        googletag.pubads().setTargeting(...target)
      }
      googletag.pubads().refresh(slotTargets)
    })
  }
}

export const slotService = new SlotService()
