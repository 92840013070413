import { Environment } from './Environment'
import { BlueEnvironment } from './Environments/BlueEnvironment'
import { HmlEnvironment } from './Environments/HmlEnvironment'
import { LocalEnvironment } from './Environments/LocalEnvironment'
import { ProductionEnvironment } from './Environments/ProductionEnvironment'

export const ConfigFactory = (env) => {
  let environment: Environment = new LocalEnvironment()

  switch (env) {
    case 'production':
      environment = new ProductionEnvironment()

      console.info('[config] using environment: production')

      break
    case 'blue':
      environment = new BlueEnvironment()

      console.info('[config] using environment: blue')
      break
    case 'hml':
      environment = new HmlEnvironment()

      console.info('[config] using environment: hml')
      break
    default:
      console.info('[config] using environment: local')
      break
  }

  // Set if is running on server side
  environment.isServerSide = !!process.env.REACT_APP_IS_SERVER_SIDE
  console.log('[config] server side: ', environment.isServerSide)

  // Return current environment
  return environment
}

export const Config = ConfigFactory(process.env.REACT_APP_ENVIRONMENT)
