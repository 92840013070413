import {
  BaseDeleteRepository,
  BaseGetAllRepository,
  BaseSaveRepository
} from 'domain/BaseRepository'
import { Favorite, FavoriteRepository, FavoriteService } from 'domain/Favorite'
import { axiosClient } from '../Adapters/AxiosClient'
import { userService } from './UserService'

export const favoriteRepository = new FavoriteRepository(axiosClient)

export const favoriteService = new FavoriteService(
  favoriteRepository as BaseSaveRepository<Favorite> &
    BaseGetAllRepository<Favorite, { favorites: Favorite[]; count: number }> &
    BaseDeleteRepository<Favorite>,
  userService
)
