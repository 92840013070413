import { Environment } from '../Environment'
import { DefaultEnvironment } from './DefaultEnvironment'

export class HmlEnvironment extends DefaultEnvironment implements Environment {
  /**
   * BaseUrl of staging requests
   *
   */
  baseUrl = 'https://hportal.webmotors.com.br'

  /**
   * PublicUrl of staging requests
   *
   */
  publicUrl = 'https://hportal.webmotors.com.br/novo-portal/static'

  /**
   * BaseUrl used on server side rendering
   *
   */
  baseServerUrl = 'https://yur5i9svf7.execute-api.us-east-1.amazonaws.com/hml'

  /**
   * Auth Cookie Name on staging environment
   *
   */
  authCookieName = 'webmotors.jwt.token.hk'

  /**
   * Enable loggin in hml
   *
   */
  logging = true

  /**
   * Cookie domain on hml environment
   *
   */
  cookieDomain = [
    '.hportal.webmotors.com.br',
    'hportal.webmotors.com.br',
    'www.hportal.webmotors.com.br',
    '.www.hportal.webmotors.com.br',
    '.webmotors.com.br',
    'webmotors.com.br',
    'www.webmotors.com.br',
    '.www.webmotors.com.br'
  ]

  /**
   * Login route
   *
   */
  loginRoute = 'https://hportal.webmotors.com.br/login'

  /**
   * Search
   *
   */
  abTest = {
    searchHome: {
      key: 'hml_ab_test_search'
    }
  }
}
