import { useMediaQuery, useTheme } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { categoryService } from 'application/Bindings/Services/CategoryService'
import range from 'lodash/range'
import { Box, ScrollItem, ScrollView } from 'presentation/components'
import { useMobile } from 'presentation/hooks'
import { useMemo } from 'react'
import { CategoryCard } from '../../Cards'

import * as S from './Categories.styles'

export function Categories() {
  const { data, isLoading } = useQuery({
    queryKey: ['categories'],
    queryFn: () => {
      return categoryService.getAllFromRemote()
    }
  })
  const isMobile = useMobile()
  const theme = useTheme()
  const isExtraLarge = useMediaQuery(theme.breakpoints.up('lg'))
  const configData = categoryService.getAllFromConfig()

  const perPage = useMemo(() => {
    if (isExtraLarge) {
      return 5
    }
    return 4
  }, [isExtraLarge])

  const categories = useMemo(() => {
    if (!data) {
      return []
    }

    return [...configData, ...data]
  }, [data])

  if (!categories) {
    return null
  }

  return (
    <S.Container>
      <Box mt={4} mb={4}>
        <S.Typography variant="h2" color="textSecondary" mb={2}>
          Categorias
        </S.Typography>
        <ScrollView perPage={perPage}>
          {categories.map((category, index) => (
            <ScrollItem key={category.id} size="290px">
              <CategoryCard
                title={category.title}
                urlImage={`${
                  isMobile ? category.mobileCard : category.desktopCard
                }?s=fill&w=274&h=216&q=70`}
                href={category.url}
                dataQA={`category_card_${index + 1}`}
              />
            </ScrollItem>
          ))}
        </ScrollView>
        {isLoading && (
          <ScrollView perPage={perPage}>
            {range(0, 6).map((index) => (
              <ScrollItem key={index} data-testid="Card_Skeleton" size="290px">
                <S.Skeleton variant="rectangular" height={216} />
              </ScrollItem>
            ))}
          </ScrollView>
        )}
      </Box>
    </S.Container>
  )
}
