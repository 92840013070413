import styled, { css } from 'styled-components'

import { IconPropsToStyle } from './Icon.types'

export const Svg = styled.svg.attrs({
  className: 'icon-wm'
})<IconPropsToStyle>`
  &:hover path {
    ${({ colorHover }) =>
      colorHover &&
      css`
        fill: ${colorHover};
      `}
  }
`

export const Path = styled.path`
  fill-rule: evenodd;
  clip-rule: evenodd;
`
