import { loggerClient } from 'application/Services'
import { BaseFindOneRepository } from 'domain/BaseRepository'
import { LocalGetItem } from 'domain/LocalClient'

export type LocalFeatureFlag = {
  isActive: boolean
}
const logger = loggerClient.create('FeatureFlagRepository')

export enum FeatureFlag {
  QUERYSTRINGKEY = 'ff',
  QUERYSTRINGVALUE = 'FF_AutomaticFairs',
  LOCALSTORAGEKEY = 'FF',
  LOCALSTORAGEPROPERTY = 'FF_AutomaticFairs'
}

export class FeatureFlagRepository
  implements BaseFindOneRepository<LocalFeatureFlag>
{
  constructor(private localClient: LocalGetItem) {}

  /**
   * Get items from local repository
   *
   * @returns
   */
  async findOne(): Promise<LocalFeatureFlag> {
    const result = await this.localClient.getItem<LocalFeatureFlag>(
      FeatureFlag.LOCALSTORAGEKEY
    )
    logger.info('[findOne] return localstorage key FF content: ', result)
    return {
      isActive: result?.[FeatureFlag.LOCALSTORAGEPROPERTY]
    }
  }
}
