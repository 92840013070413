import { loggerClient } from 'application/Services'
import {
  BaseDeleteRepository,
  BaseGetAllRepository,
  BaseSaveRepository
} from 'domain/BaseRepository'
import { UserService } from 'domain/User'
import { Favorite } from '../Models/Favorites'

const logger = loggerClient.create('FavoriteService')

export class FavoriteService {
  /**
   * FavoriteService constructor
   *
   * @param repository
   */
  constructor(
    private repository: BaseSaveRepository<Favorite> &
      BaseGetAllRepository<Favorite, { favorites: Favorite[]; count: number }> &
      BaseDeleteRepository<Favorite>,
    private userService: UserService
  ) {}

  /**
   * Get all favorites from user
   *
   * @param user
   * @returns
   */
  async get(): Promise<{ favorites: Favorite[]; count: number }> {
    logger.info('[get] geeting user favorites')

    const user = this.userService.getCurrentUser()
    if (!user) {
      logger.info('[get] failed to get favorites: no logged user')
      return { favorites: [], count: 0 }
    }

    try {
      const carsFavorites = await this.repository.getAll({
        user,
        path: '1'
      })
      const bikeFavorites = await this.repository.getAll({
        user,
        path: '2'
      })

      const favorites = [...carsFavorites.favorites, ...bikeFavorites.favorites]

      const count = carsFavorites.count + bikeFavorites.count

      logger.info('[get] found favorites: ', favorites)

      return { favorites, count }
    } catch (e: any) {
      logger.info('[get] failed to get user favorites: ', e.message)
      logger.info('[get] returning empty array')

      return { favorites: [], count: 0 }
    }
  }

  /**
   * Remove item from favorite
   *
   * @param data
   * @returns
   */
  async remove(favoriteItem: Favorite): Promise<Favorite> {
    try {
      logger.info('[remove] removing favorite: ', favoriteItem.id)

      const favorite = await this.repository.delete(favoriteItem)

      logger.info('[remove] favorite removed with success: ', favoriteItem.id)

      return favorite
    } catch (e) {
      logger.info('[remove] failed to remove favorite: ', favoriteItem.id)

      throw e
    }
  }

  /**
   * Create Favorite model
   *
   * @param data
   * @returns
   */
  async create(favoriteItem: Favorite): Promise<Favorite> {
    try {
      logger.info('[save] saving favorite: ', favoriteItem.advertiseId)

      const favorite = await this.repository.save(favoriteItem)

      logger.info(
        '[save] favorite saved with success: ',
        favoriteItem.advertiseId
      )

      return favorite
    } catch (e) {
      logger.info('[save] failed to save favorite: ', favoriteItem.advertiseId)

      throw e
    }
  }

  /**
   * Create if favorite does not exists or remove if it does
   *
   * @param favoriteItem
   * @returns
   */
  async save(favoriteItem: Favorite): Promise<Favorite> {
    if (favoriteItem.id) {
      return this.remove(favoriteItem)
    }

    return this.create(favoriteItem)
  }
}
