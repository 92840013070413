import styled from 'styled-components'

export const Container = styled.div<{
  height: number
  width: any
  size: string
}>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}%;
  display: inline-block;
  padding: 8px;

  &.hidden {
    overflow: hidden;
    padding: 0px;
    height: 0px;
    width: 0px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: ${({ size }) => size};
  }
`
