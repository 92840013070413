import { favoriteService } from 'application'
import { uriService } from 'application/Services'
import { Favorite, VehicleType } from 'domain/Favorite'
import { Offer } from 'domain/Search/Models/Offer'
import { SearchItemSegment } from 'domain/Search/Models/SearchItem'
import { useAuth, useModal } from 'presentation/hooks'
import { useEffect, useState } from 'react'

export function useVehicleCardController(offerState: Offer, offerId?: string) {
  const [wasFavorited, setWasFavorited] = useState<boolean>(
    offerState.wasFavorited
  )
  const [offer, setOffer] = useState<Offer>(offerState)
  const { open } = useModal('FavoriteModal')
  const { user } = useAuth()

  function handleSaveFavorite(initialFavorite = false) {
    return async function (e?) {
      if (e) e.preventDefault()

      // If there is a favorite request, but no logged user, do nothing
      if (initialFavorite && !user) {
        return
      }

      if (!user && !initialFavorite) {
        return open({ offerId: offer.id })
      }

      const favorite = await favoriteService.save(
        new Favorite({
          user: user!,
          advertiseId: offer.id,
          vehicleType:
            offer.segment === SearchItemSegment.CAR
              ? VehicleType.CAR
              : VehicleType.BIKE,
          id: wasFavorited ? offer.favorite?.id : undefined
        })
      )

      const newOffer = new Offer({
        ...offer,
        favorite: new Favorite({ ...favorite })
      })

      setWasFavorited((oldValue) => !oldValue)
      setOffer(newOffer)

      if (initialFavorite && offerId) {
        uriService.removeQueryParam('offer_id')
      }

      window.dispatchEvent(new CustomEvent('favoritesChanged'))
    }
  }

  useEffect(() => {
    if (offerId && offer.id.toString() === offerId && !wasFavorited) {
      handleSaveFavorite(true)()
    }
  }, [offerId, wasFavorited, offer])

  return {
    handleSaveFavorite,
    wasFavorited,
    offer
  }
}
