import CloseIcon from '@mui/icons-material/Close'
import FavoriteIcon from '@mui/icons-material/Favorite'
import { Config } from 'application'
import { useModal } from 'presentation/hooks'
import { useMemo } from 'react'
import * as Styles from './FavoriteModel.styles'

export function FavoriteModal() {
  const { isOpen, close, data } = useModal('FavoriteModal')

  const returnUrl = useMemo(() => {
    if (!data?.offerId) {
      return ''
    }

    return encodeURI(
      `${Config.baseUrl}/novo-portal/index.html?offer_id=${data.offerId}`
    )
  }, [data])

  if (!isOpen) {
    return null
  }

  return (
    <Styles.Container data-testid="FavoriteModal">
      <Styles.Card>
        <Styles.CloseButton onClick={close}>
          <CloseIcon />
        </Styles.CloseButton>
        <Styles.Section>
          <Styles.Icon>
            <FavoriteIcon sx={{ fontSize: 56, color: '#e11138' }} />
          </Styles.Icon>
        </Styles.Section>
        <Styles.Section>
          <Styles.Title>
            Este é seu <br /> primeiro favorito!
          </Styles.Title>
        </Styles.Section>
        <Styles.Section>
          <Styles.Content>
            Entre em sua conta para acessar seus favoritos e acompanhar as
            negociações de onde estiver.
          </Styles.Content>
        </Styles.Section>
        <Styles.Section>
          <Styles.LoginButton
            href={`${Config.loginRoute}?lkid=1364&r=${returnUrl}`}
          >
            Fazer login
          </Styles.LoginButton>
        </Styles.Section>
      </Styles.Card>
    </Styles.Container>
  )
}
