import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { Config, fairsService } from 'application'
import { useAuth, useHomeDataLayer } from 'presentation/hooks'
import { isClientSide } from 'presentation/hooks/use-is-client-side'
import { isLocalStorageTimestampExpired } from 'presentation/utils'

const useStyles = makeStyles({
  root: {
    zIndex: 1099
  }
})

/**
 * Controller for the mobile app modal.
 *
 * This hook manages the state and logic of the modal in the mobile application,
 * checking specific events such as user's consent acceptance and if the black fair is active.
 *
 * @returns
 * - isMobile: indicates if the device is mobile.
 * - isBlackFairActive: indicates if the black fair is active.
 * - acceptedConsent: indicates if the user accepted the consent.
 * - user: authenticated user information.
 * - isOpen: indicates if the modal is open.
 * - classes: styles for the modal.
 * - handleModalClose: function to close the modal.
 * - handleHomeDataLayerClose: function to handle data layer on close.
 * - handleHomeDataLayerCTA: function to handle data layer on CTA click.
 */
export function useToAppMobileModalController() {
  const [isBlackFairActive, setIsBlackFairActive] = useState<
    boolean | undefined
  >(undefined)
  const [isMegaFairActive, setIsMegaFairActive] = useState<boolean | undefined>(
    undefined
  )
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [acceptedConsent, setAcceptedConsent] = useState<boolean>(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { user } = useAuth()
  const classes = useStyles()
  const onClientSide = isClientSide()

  const { handleHomeDataLayer: handleHomeDataLayerClose } = useHomeDataLayer(
    'fecharModalBlackFeirao'
  )
  const { handleHomeDataLayer: handleHomeDataLayerCTA } = useHomeDataLayer(
    'queroAproveitarModalBlack'
  )

  /**
   * Monitors the acceptance of consent event.
   */
  useEffect(() => {
    const handleConsent = (event: Event) => {
      const customEvent = event as CustomEvent
      setAcceptedConsent(customEvent.detail.value)
    }

    document.addEventListener('acceptedConsent', handleConsent)
    return () => {
      document.removeEventListener('acceptedConsent', handleConsent)
    }
  }, [])

  const checkBlackFairIsActive = async () => {
    try {
      const blackFairIsActive = Boolean(
        await fairsService.canIUseBlackFairTheme()
      )
      setIsBlackFairActive(blackFairIsActive)
    } catch (error) {
      setIsBlackFairActive(false)
    }
  }

  const checkMegaFairIsActive = async () => {
    try {
      const megaFairIsActive = Boolean(await fairsService.hasMegaFairActive())
      setIsMegaFairActive(megaFairIsActive)
    } catch (error) {
      setIsMegaFairActive(false)
    }
  }

  /**
   * Checks if the modal should be displayed and if the black fair is active.
   */
  useEffect(() => {
    const showModal = isLocalStorageTimestampExpired({
      key: Config.hideModalToAppStorageKey,
      hours: 1,
      minutes: 0,
      seconds: 0
    })

    if (showModal && onClientSide) {
      window.localStorage.removeItem(Config.hideLoginNotificationStorageKey)
      checkBlackFairIsActive()
      checkMegaFairIsActive()
    }

    setIsOpen(showModal)
  }, [onClientSide])

  /**
   * Closes the modal and updates the localStorage.
   */
  const handleModalClose = () => {
    if (onClientSide) {
      window.localStorage.setItem(
        Config.hideModalToAppStorageKey,
        Date.now().toString()
      )
    }
    setIsOpen(false)
  }

  return {
    isMobile,
    isBlackFairActive,
    isMegaFairActive,
    acceptedConsent,
    user,
    isOpen,
    classes,
    handleModalClose,
    handleHomeDataLayerClose,
    handleHomeDataLayerCTA
  }
}
