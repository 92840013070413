import { insiderTagService } from 'application'
import { loggerClient } from 'application/Services'
import window from 'global/window'
import { useAuth } from '../use-auth'

export function useInsiderTag() {
  const { user } = useAuth()
  const logger = loggerClient.create('InsiderTag')

  const insertInsiderTag = ({
    id = 'insiderTag',
    callback
  }: { id?: string; callback?: () => void } = {}) => {
    let script: HTMLScriptElement | null = document.getElementById(
      id
    ) as HTMLScriptElement

    if (!script) {
      script = document.createElement('script')
      script.async = true
      script.id = id
      script.src = '//webmotors.api.useinsider.com/ins.js?id=10005945'

      const node = document.getElementsByTagName('script')[0]
      if (node?.parentNode) node.parentNode.insertBefore(script, node)
    }

    const checkLoaded = () => {
      if (script && typeof window.Insider !== 'undefined') {
        clearInterval(checkInterval)
        logger.info('[Object] created: ', window.insider_object)
        logger.info('[Script] loaded: ', window.Insider)
        if (typeof callback === 'function') callback()
      }
    }

    const checkInterval = setInterval(checkLoaded, 100)

    script.onload = () => {
      checkLoaded()
    }

    return () => {
      if (script) document.head.removeChild(script)
    }
  }
  const handleInsiderObject = ({ properties, cookieConfig }) => {
    insiderTagService.createInsiderObject({
      properties,
      user,
      cookieConfig
    })
  }

  const handleInsiderEvent = ({ event = 'events', properties }) => {
    insiderTagService.dispatchInsiderEvent({ event, properties })
  }

  return { handleInsiderObject, handleInsiderEvent, insertInsiderTag }
}
