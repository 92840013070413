import { Config } from 'application/Config'
import { BaseGetConfigsRepository } from 'domain/BaseRepository'
import { isClientSide } from 'presentation/hooks/use-is-client-side'
import { v4 as uuid } from 'uuid'
import { Category } from '../Model/Category'
import { FairCategory } from '../Model/FairCategory'

export class ConfigCategoryRepository
  implements BaseGetConfigsRepository<Category>
{
  getAll(): Category[] {
    if (!isClientSide()) return []

    let result: FairCategory[] = []
    if (window?.FairsDateChecker?.checkBlackFair())
      result = Config.blackFairCategoriesLinks
    if (window?.FairsDateChecker?.checkMegaFair())
      result = Config.megaFairCategoriesLinks

    return (
      result?.map(
        (item) =>
          new Category({
            id: uuid(),
            title: item.title,
            url: item.url,
            mobileCard: `${Config.publicUrl}${item.backgroundMobile}`,
            desktopCard: `${Config.publicUrl}${item.backgroundDesktop}`
          })
      ) || []
    )
  }
}
