import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  z-index: 4;
  max-width: 1008px;
  padding: 0px 6px;
  margin: 0px auto;

  ${({ theme }) => theme.breakpoints.down('md')} {
    max-width: 405px;
  }
`

export const Card = styled.div`
  box-shadow: 0px 1px 2px rgba(46, 45, 55, 0.12);
  padding: 24px 32px;
  border-radius: 8px;
  background: white;

  @media (max-width: 340px) {
    padding: 24px 16px;
  }
`

export const CardHeader = styled.div`
  padding-bottom: 16px;
  display: flex;
  gap: 32px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    gap: 13px;
  }
`

export const CardBody = styled.div`
  display: flex;
  gap: 32px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    text-align: center;
    display: block;
  }
`

export const InputGroup = styled.div`
  border: 2px solid #ecedf2;
  position: relative;
  border-radius: 8px;
  display: flex;
  height: 56px;
  flex-grow: 1;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 40px;
  }
`

export const LocationInputGroup = styled(InputGroup)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-top: 10px;
  }
`
export const Input = styled.input`
  border-radius: 0px 8px 8px 0px;
  line-height: 21px;
  font-weight: 600;
  font-size: 14px;
  outline: none;
  width: 100%;

  ${({ theme }) => theme.breakpoints.down('md')} {
    line-height: 18px;
    font-weight: 500;
    font-size: 12px;
  }
`

export const Button = styled.a`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};

  text-transform: uppercase;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  line-height: 24px;
  transition: 0.25s;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  width: 280px;
  height: 56px;

  &:hover {
    background: #c80a2e;
    cursor: pointer;
    color: white;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 11px 16px;
    line-height: 18px;
    font-weight: 700;
    margin-top: 8px;
    font-size: 12px;
    height: 40px;
    width: auto;
  }
`

export const IconPrefix = styled.div`
  font-size: 17px;
  padding: 11px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 5px 11px;
  }
`

export const NavTab = styled.span`
  border-bottom: 3px solid transparent;
  text-decoration: none;
  line-height: 24px;
  padding: 6px 0px;
  transition: 0.25s;
  font-weight: 700;
  font-size: 16px;
  color: #aeaeba;

  &:hover {
    cursor: pointer;
    color: #2e2d37;
  }

  &.actived {
    color: #2e2d37;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  & h1,
  h2 {
    font: inherit;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    text-transform: uppercase;
    text-align: center;
    line-height: 14px;
    font-weight: 600;
    font-size: 12px;

    span {
      line-height: 17px;
      font-size: 15px;
    }
  }
`

export const NavLink = styled.a`
  border-bottom: 3px solid transparent;
  text-decoration: none;
  line-height: 24px;
  padding: 6px 0px;
  transition: 0.25s;
  font-weight: 700;
  font-size: 16px;
  color: #757481;

  &:hover {
    cursor: pointer;
    color: #2e2d37;
  }

  &.actived {
    color: #2e2d37;
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  & h1,
  h2 {
    font: inherit;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    text-transform: uppercase;
    text-align: center;
    line-height: 14px;
    font-weight: 600;
    font-size: 12px;

    span {
      line-height: 17px;
      font-size: 15px;
    }
  }
`

export const DropList = styled.div`
  box-shadow: 0px 8px 24px rgba(46, 45, 55, 0.24);
  border-radius: 8px;
  position: absolute;
  background: white;
  overflow: hidden;
  text-align: left;
  z-index: 99;
  width: 100%;
  top: 56px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    top: 40px;
  }
`

export const DropHeader = styled.div`
  background: #696977;
  line-height: 18px;
  padding: 8px 16px;
  font-weight: 700;
  font-size: 12px;
  color: white;
`

export const DropItem = styled.a`
  border-bottom: 1px solid #ecedf2;
  text-decoration: none;
  padding: 16px 24px;
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
  color: #696977;
  display: block;

  b {
    color: #2e2d37;
  }

  &:hover {
    cursor: pointer;
  }
`

export const Empty = styled.div`
  box-shadow: 0px 8px 24px rgba(46, 45, 55, 0.24);
  border-radius: 8px;
  position: absolute;
  background: white;
  overflow: hidden;
  font-weight: 400;
  text-align: left;
  font-size: 12px;
  color: #8b8c99;
  padding: 16px;
  z-index: 99;
  width: 100%;
  top: 56px;
`

export const IconClear = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 5px 5px;
  cursor: pointer;

  & svg {
    width: 24px;
    height: 24px;
  }
`
