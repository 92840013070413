import { dataLayerHomeService } from 'application'
import { SitePlatform } from 'domain/DataLayer/Model/Site'
import { LoginStatus } from 'domain/DataLayer/Model/User'
import { HomeDataLayerDto } from 'domain/DataLayer/Service/DataLayerHomeService'
import window from 'global/window'
import { useMemo } from 'react'
import { useAuth } from '../use-auth'
import { useMobile } from '../use-mobile'

export function useHomeDataLayer(eventType: string) {
  const mobile = useMobile()

  const platform = useMemo(
    () => (mobile ? SitePlatform.MOBILE : SitePlatform.DESKTOP),
    [mobile]
  )

  const loginStatus = useAuth().user
    ? LoginStatus.LOGGED
    : LoginStatus.LOGGEDOUT

  const handleHomeDataLayer = () => {
    const objDataLayer: HomeDataLayerDto = {
      eventType,
      site: {
        platform
      },
      page: {
        url: window.location.href
      },
      user: {
        loginStatus
      }
    }

    dataLayerHomeService.sendDataLayer(objDataLayer)
  }

  return {
    handleHomeDataLayer
  }
}
