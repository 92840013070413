import * as S from './HelpBar.styles'

import { ROUTES } from 'constants/index'
import { createLkidUrl } from 'utils'

export function HelpBar() {
  const helpLink = createLkidUrl(ROUTES.HELP.YOU, '1439')
  return (
    <S.Box
      data-testid="Link"
      variant="body2"
      underline="none"
      target="_blank"
      id="helpBox"
      href={helpLink}
      rel="noreferrer"
    >
      Nossos canais de atendimento são 100% digitais. Precisa de ajuda?
      <strong>Clique aqui</strong>
    </S.Box>
  )
}
