import { dataLayerHomeService } from 'application'
import { loggerClient } from 'application/Services'
import {
  BaseFindOneRepository,
  BaseRepository,
  QueryParams
} from 'domain/BaseRepository'
import { CacheClient } from 'domain/CacheClient'
import { CookieService } from 'domain/Cookie'
import { formatString } from 'presentation/utils/strings/formatString'
import { City } from '../Model/City'
import { State } from '../Model/State'

const logger = loggerClient.create('LocationService')

export type Coords = {
  latitude: number
  longitude: number
}

export class LocationService {
  constructor(
    private stateRepository: BaseRepository<State>,
    private cityRepository: BaseFindOneRepository<City | null>,
    private cookieService: CookieService,
    private cache: CacheClient
  ) {}

  /**
   * Get all states from remote
   *
   * @returns {Promise<State[]>}
   */
  getAllStates(queryParams?: QueryParams): Promise<State[]> {
    return this.stateRepository.getAll(queryParams)
  }

  /**
   * Get user current coordinates
   *
   * @returns {Promise<GeolocationPosition>}
   */
  getCurrentCoords(): Promise<GeolocationPosition | null> {
    const coords = this.cache.get<GeolocationPosition>('userCurrentLocation')
    if (coords) {
      return Promise.resolve(coords)
    }

    return new Promise((resolve) => {
      return navigator.geolocation.getCurrentPosition(
        (coords) => {
          this.cache.put<GeolocationPosition>('userCurrentLocation', 10, {
            coords: {
              accuracy: coords.coords.accuracy,
              altitude: coords.coords.altitude,
              altitudeAccuracy: coords.coords.altitudeAccuracy,
              heading: coords.coords.heading,
              latitude: coords.coords.latitude,
              longitude: coords.coords.longitude,
              speed: coords.coords.speed
            },
            timestamp: coords.timestamp
          })
          resolve(coords)
        },
        () => resolve(null)
      )
    })
  }

  /**
   * Find city by current user coords
   *
   * @param coords
   * @returns
   */
  async findCityByCurrentCoords(): Promise<City | null> {
    const coords = await this.getCurrentCoords()
    if (!coords) {
      logger.info('[findCityByCurrentCoords] unable to get user coords')
      return null
    }

    logger.info(
      '[findCityByCurrentCoords] looking for cities in coords: ',
      coords
    )

    const city = await this.cityRepository.findOne({
      querystring: coords
    })

    if (city) {
      dataLayerHomeService.updateLocationDataLayer({
        city: formatString(city?.name),
        state: formatString(city?.state?.name)
      })
    }

    if (!city) {
      logger.info(
        '[findCityByCurrentCoords] could not find city in coords: ',
        coords
      )
      return null
    }

    logger.info('[findCityByCurrentCoords] found city: ', city.name)
    return city
  }

  /**
   * Get user current city
   *
   * @returns
   */
  async getCurrentCity() {
    if (!(await this.cookieService.isSearchEnabled())) {
      logger.info('[getCurrentCity] search is disabled in preferences')
      return null
    }

    // Check if there is a city saved on local
    const city = await this.cityRepository.findOne()
    if (city) {
      logger.info(`[getCurrentCity] city found in cookies: ${city.name}`)
      return city
    }

    // Get current city by coords from browser geolocation
    const currentCity = await this.findCityByCurrentCoords()
    if (!currentCity) {
      logger.info('[getCurrentCity] could not found city by geolocation')
      return null
    }

    // Get all available locations with offers
    const locations = await this.getAllStates({ path: 'car' })
    const cities = locations.flatMap((state) => state.cities)

    // Check if given location has any offers
    const hasVehicle =
      cities.filter((city) => {
        const check = city.isEqual(currentCity)
        if (check) currentCity.name = city.name
        return check
      }).length > 0

    if (!hasVehicle) {
      logger.info(
        `[getCurrentCity] could not found vehicles in city: ${currentCity.name}`
      )
      return null
    }

    logger.info(
      `[getCurrentCity] return city by geolocation: ${currentCity.name}`
    )

    const userCoords = this.cache.get<GeolocationPosition>(
      'userCurrentLocation'
    )
    this.cache.put<GeolocationPosition | any>('userCurrentLocation', 10, {
      coords: {
        ...userCoords?.coords,
        city: currentCity?.name,
        state: currentCity?.state?.name
      },
      timestamp: Date.now()
    })

    return currentCity
  }
}
