import {
  Container as DefaultContainer,
  Skeleton as DefaultSkeleton,
  Typography as DefaultTypography
} from 'presentation/components'
import styled from 'styled-components'

type ButtonProps = {
  isDisabled: boolean
}

export const Skeleton = styled(DefaultSkeleton)`
  border-radius: 8px;
`

export const Container = styled(DefaultContainer)`
  max-width: 1020px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 12px 24px 0px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    & a,
    span {
      min-width: 60%;
    }
  }
`

export const Typography = styled(DefaultTypography)`
  color: ${({ theme }) => theme.palette.text.secondary};
`

const Button = styled.button`
  position: absolute;
  top: 50%;
  z-index: 10;
  display: block;
  box-sizing: border-box;
  width: 28px;
  height: 28px;
  padding: 0;
  border: none;
  border-radius: 20px;
  background-color: #fff;
  color: transparent;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 50%);
  outline: none;
  font-size: 0;
  line-height: 0;
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.2s;
  transform: translate(0, -50%);

  &:after {
    content: '';
    position: relative;
    width: 17px;
    height: 17px;
    display: flex;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto;
    background-image: url("data:image/svg+xml;utf8,<svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'> <path d='M20.9987,11 L5.4127,11 L10.7057,5.707 C11.0967,5.316 11.0967,4.684 10.7057,4.293 C10.3147,3.902 9.6827,3.902 9.2917,4.293 L2.2917,11.292 C2.1997,11.385 2.1267,11.496 2.0757,11.618 C1.9747,11.862 1.9747,12.138 2.0757,12.382 C2.1267,12.504 2.1997,12.615 2.2917,12.708 L9.2917,19.707 C9.4867,19.902 9.7427,20 9.9987,20 C10.2547,20 10.5107,19.902 10.7057,19.707 C11.0967,19.316 11.0967,18.684 10.7057,18.293 L5.4127,13 L20.9987,13 C21.5507,13 21.9987,12.552 21.9987,12 C21.9987,11.448 21.5507,11 20.9987,11'></path></svg>");
    background-position: center;
    background-size: 17px;
  }

  &:hover:enabled {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.5;
  }
`

export const PrevButton = styled(Button)<ButtonProps>`
  ${({ isDisabled }) => `${isDisabled && 'display: none !important'};`}
  left: -0.5rem;
  visibility: hidden;
`

export const NextButton = styled(Button)<ButtonProps>`
  ${({ isDisabled }) => `${isDisabled && 'display: none  !important'};`}
  right: -0.5rem;
  visibility: hidden;

  &:after {
    transform: rotate(180deg);
  }
`
type SliderWrapperProps = {
  canEnableInfinite: boolean
}

export const SliderWrapper = styled.div<SliderWrapperProps>`
  .slick-list,
  .slick-track {
    display: flex;
  }

  .slick-item {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .ft-slick__dots--custom {
    height: 4px;
    width: 4px;
    background-color: #aeaeba;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
  }

  .slick-dots {
    bottom: -50px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    li {
      width: 10px;
      margin: 0 2px;
    }

    .slick-active {
      width: 30px;

      .ft-slick__dots--custom {
        width: 24px;
        height: 4px;
        top: 0;
        background-color: #e11138;
      }
    }
  }

  .slick-arrow.slick-next,
  .slick-arrow.slick-prev {
    background-color: rgba(46, 45, 55, 0.18);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    opacity: 0.9;
    height: 40px;
    width: 40px;
    z-index: 999;
    width: 28px;
    height: 28px;
  }

  .slick-arrow.slick-next {
    position: absolute;
    right: 32px;
  }

  .slick-arrow.slick-prev {
    position: absolute;
    left: -32px;
  }

  .slick-arrow.slick-next:hover,
  .slick-arrow.slick-prev:hover {
    opacity: 1;
  }

  .slick-prev::before,
  .slick-next::before {
    display: none;
  }

  &:hover ${PrevButton}, &:hover ${NextButton} {
    visibility: visible;
  }

  @media (max-width: 570px) {
    max-width: 425px;

    .slick-item {
      justify-content: start;
    }

    .slick-list,
    .slick-track {
      gap: 0.5rem;
    }

    .slick-list {
      ${({ canEnableInfinite }) =>
        `${canEnableInfinite && 'padding-left: 1rem'};`}
    }

    .slick-track {
      ${({ canEnableInfinite }) =>
        `${!canEnableInfinite && 'padding-left: 28px'};`}
    }
  }

  @media (max-width: 320px) {
    max-width: 280px;
  }
`

export const WithoutSliderWrapper = styled.div`
  display: flex;
  justify-content: start;
  gap: 1rem;
`
