import { autoCompleteService, searchService } from 'application'
import { SearchItem, SearchItemSegment } from 'domain/Search/Models/SearchItem'
import {
  useAuth,
  useHomeDataLayer,
  useLocation,
  useMobile,
  useModal
} from 'presentation/hooks'
import { createUrl } from 'presentation/utils'
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { createGlobalState } from 'react-use'

export type SearchResult = {
  brands: SearchItem[]
  models: SearchItem[]
}

type SegmentProps = 'all' | 'new' | 'used'

type OffersLinkProps = {
  lkid: number
  tipoveiculo?: string
}

type MountLinkProps = {
  estadocidade: string
  marca1?: string
  modelo1?: string
  autocomplete: string
  autocompleteTerm: string
  lkid: number
  tipoveiculo?: string
}

export const defaultSearchResult: SearchResult = {
  brands: [],
  models: []
}

export const initialSearchItemState = new SearchItem({
  id: '',
  brand: '',
  model: '',
  segment: undefined,
  name: '',
  location: '',
  url: undefined,
  count: 0
})

function formatTypeByVehicleCategory(
  vehicleType: 'carros' | 'motos',
  segment: SegmentProps
) {
  switch (segment) {
    case 'used':
      if (vehicleType === 'carros') return 'carros-usados'
      return 'motos-usadas'
    case 'new':
      if (vehicleType === 'carros') return 'carros-novos'
      return 'motos-novas'
    default:
      return vehicleType
  }
}

const useInputeValue = createGlobalState<string>('')
const useSearchTerm = createGlobalState<string>('')
const useSearchItem = createGlobalState<SearchItem>(initialSearchItemState)
const useVehicleCategory = createGlobalState<SegmentProps>('all')

export function useSearchSectionController() {
  const tabChangeEvent = {
    [SearchItemSegment.CAR]: useHomeDataLayer('abaComprarCarros'),
    [SearchItemSegment.BIKE]: useHomeDataLayer('abaComprarMotos')
  }
  const [searchResult, setSearchResult] =
    useState<SearchResult>(defaultSearchResult)
  const [searchType, setSearchType] = useState<SearchItemSegment>(
    searchService.getSearchSegment()
  )
  const { open, isOpen: isModalOpen, close } = useModal('AutoCompleteModal')
  const [filteredOfferLink, setFilteredOfferLink] = useState<string>('')
  const [offersCount, setOffersCount] = useState<number>(0)
  const [vehicleCategory, setVehicleCategory] = useVehicleCategory()
  const [inputValue, setInputValue] = useInputeValue()
  const [searchItem, setSearchItem] = useSearchItem()
  const [searchTerm, setSearchTerm] = useSearchTerm()
  const [type, setType] = useState<string>('')
  const { city } = useLocation()
  const isMobile = useMobile()
  const { user } = useAuth()

  /**
   * Mount city name
   *
   */
  const cityName = useMemo(() => {
    return city ? city.toReadableString() : 'estoque'
  }, [city])

  function vehicleTypeParams() {
    const type =
      searchType === SearchItemSegment.CAR
        ? formatTypeByVehicleCategory('carros', vehicleCategory)
        : formatTypeByVehicleCategory('motos', vehicleCategory)

    return {
      tipoveiculo: type
    }
  }

  /**
   * Mount autocomplete links
   *
   */
  const findOffersLink = useMemo(() => {
    const { tipoveiculo } = vehicleTypeParams()

    const params: OffersLinkProps = {
      lkid: 1022
    }

    if (tipoveiculo) params.tipoveiculo = tipoveiculo
    const URL = tipoveiculo ? `/${tipoveiculo}/estoque` : '/estoque'

    return createUrl(URL, { params })
  }, [searchType, vehicleCategory])

  const sellLink = useMemo(() => {
    const path = searchType === SearchItemSegment.BIKE ? '/moto' : ''
    const params = {
      lkid: 1020
    }

    return createUrl(`/vender${path}`, { params })
  }, [searchType])

  const financingLink = useMemo(() => {
    const state = user ? 'logado' : 'deslogado'
    const params = {
      lkid: 1021,
      inst: `homesearchbox:webmotors:header-${state}::financiamento`
    }

    return createUrl('/financiamento', { params })
  }, [user])

  /**
   * Change search segment (CAR|BIKE)
   *
   */
  function changeSearchSegment(segment: SearchItemSegment) {
    return function (e: SyntheticEvent) {
      e.preventDefault()
      setVehicleCategory('all')
      searchService.setSearchSegment(segment)
      setSearchType(segment)
      tabChangeEvent[segment].handleHomeDataLayer()
    }
  }

  /**
   * Indicates what is being searched (CAR|BIKE)
   *
   */
  const pickingBike = useMemo(
    () => searchType === SearchItemSegment.BIKE,
    [searchType]
  )
  const pickingCar = useMemo(
    () => searchType === SearchItemSegment.CAR,
    [searchType]
  )

  /**
   * Gets input placeholder showing on search
   *
   */
  const inputPlaceholder = useMemo(() => {
    if (searchType === SearchItemSegment.CAR) {
      return 'Digite marca ou modelo do carro'
    }

    return 'Digite marca ou modelo da moto'
  }, [searchType])

  /**
   * Gets has results variable
   *
   */
  const hasResults = useMemo(
    () => searchResult.brands.length > 0 || searchResult.models.length > 0,
    [searchResult]
  )

  /**
   * Indicates if there is any search
   *
   */
  const hasSearch = useMemo(() => inputValue.length >= 2, [inputValue])

  /**
   * Indicates if should display empty states
   *
   */
  const displayEmptyState = useMemo(
    () => hasSearch && !hasResults,
    [hasSearch, hasResults]
  )

  /**
   * Handle input focus
   *
   * @returns
   */
  function handleFocus() {
    if (!isMobile) {
      return
    }

    open()
  }

  /**
   * Handle search
   *
   * @param query
   */
  async function handleSearch(query) {
    if (!query || query.length < 2) {
      return setSearchResult({ brands: [], models: [] })
    }

    const filterdItems =
      (await autoCompleteService.filterItems(query, () => {
        return autoCompleteService.getItems()
      })) || []

    const brands = filterdItems
      .filter((item) => item.model === null)
      .slice(0, 5)

    setSearchResult({
      brands,
      models: filterdItems
        .filter((item) => item.model !== null)
        .slice(0, 5 - brands.length)
    })
  }

  /**
   * Clear search
   *
   * @returns
   */
  function clearSearch() {
    autoCompleteService.clearFilterCache()
    setInputValue('')
    return setSearchResult({ brands: [], models: [] })
  }

  /**
   * Print search results with hightlights
   *
   * @param word
   * @returns
   */
  function printWithHighlights(word) {
    const regex = new RegExp(inputValue, 'gi')

    return word.replace(regex, (s) => `<b>${s}</b>`)
  }

  /**
   * Load offers count
   *
   */

  const handleLoadingCount = useCallback(async () => {
    const category =
      searchType === SearchItemSegment.CAR
        ? formatTypeByVehicleCategory('carros', vehicleCategory)
        : formatTypeByVehicleCategory('motos', vehicleCategory)

    const count = await autoCompleteService.getTotalOffers({
      path: searchType,
      vehicleCategory: category
    })

    setOffersCount(count)
  }, [searchType, vehicleCategory])

  function getPathNameFromLink(link: string, paramToRemove?: string): string {
    const url = new URL(link)
    const params = new URLSearchParams(url.search)

    if (paramToRemove) {
      params.delete(paramToRemove)
    }

    let searchParams = ''
    Array.from(params).forEach(([key, value]) => {
      if (searchParams !== '') searchParams += '&'
      searchParams += `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    })

    const pathName = url.pathname.replace(/\/undefined|\/\//g, '')

    return (
      pathName + (searchParams ? '?' + searchParams.replace(/\/\//g, '/') : '')
    ).replace(/^\/\//, '')
  }

  /**
   * Load offers count in a City
   *
   */

  async function handleCityCount(item: SearchItem, type: string) {
    const link = type === 'brand' ? mountLinkBrand(item) : mountLinkModel(item)
    const pathName = getPathNameFromLink(link, 'lkid')

    const clearLink = getPathNameFromLink(link)
    setFilteredOfferLink(clearLink)

    const count = await autoCompleteService.getTotalOffersByCity(
      {
        path: searchType
      },
      pathName
    )
    setOffersCount(count)
  }

  /**
   * Mount link for brand searching
   *
   * @param brand
   * @returns
   */
  function mountLinkBrand(brand: SearchItem): string {
    const lowerBrand = brand.brand?.toLocaleLowerCase()
    const { tipoveiculo } = vehicleTypeParams()

    const params: MountLinkProps = {
      estadocidade: cityName,
      marca1: brand.brand || '',
      autocomplete: inputValue,
      autocompleteTerm: `${brand.name}`,
      lkid: 1704
    }
    const location = city ? city.toUrlString() : 'estoque'

    const URL = tipoveiculo
      ? `/${tipoveiculo}/${location}/${lowerBrand}`
      : `/${location}/${lowerBrand}`

    return createUrl(URL, { params })
  }

  /**
   * Mount link for model searching
   *
   * @param brand
   * @returns
   */
  function mountLinkModel(model: SearchItem): string {
    const lowerBrand = model.brand?.toLocaleLowerCase()
    const lowerModel = model.model?.toLocaleLowerCase()
    const { tipoveiculo } = vehicleTypeParams()

    const params: MountLinkProps = {
      estadocidade: cityName,
      marca1: model.brand || '',
      modelo1: model.model || '',
      autocomplete: inputValue,
      autocompleteTerm: model.name,
      lkid: 1705,
      tipoveiculo
    }

    const location = city ? city.toUrlString() : 'estoque'

    return createUrl(
      `/${tipoveiculo}/${location}/${lowerBrand}/${lowerModel}`,
      {
        params
      }
    )
  }

  /**
   * Apply search update on every input change
   *
   */
  useEffect(() => {
    handleSearch(inputValue)
  }, [inputValue])

  /**
   * Load autocomplete items on page loading
   *
   */
  useEffect(() => {
    function handleChangeSearchSegment(e) {
      setSearchType(e.detail)
    }

    document.addEventListener(
      'handleVehicleTypeChange',
      handleChangeSearchSegment
    )

    autoCompleteService.getItems()

    return () =>
      document.removeEventListener(
        'handleVehicleTypeChange',
        handleChangeSearchSegment
      )
  }, [])

  return {
    setFilteredOfferLink,
    changeSearchSegment,
    printWithHighlights,
    handleLoadingCount,
    filteredOfferLink,
    displayEmptyState,
    inputPlaceholder,
    setOffersCount,
    findOffersLink,
    handleCityCount,
    vehicleCategory,
    setVehicleCategory,
    mountLinkBrand,
    mountLinkModel,
    setSearchItem,
    setSearchTerm,
    setInputValue,
    financingLink,
    searchResult,
    isModalOpen,
    handleFocus,
    pickingBike,
    clearSearch,
    offersCount,
    pickingCar,
    searchType,
    hasResults,
    inputValue,
    searchTerm,
    searchItem,
    hasSearch,
    sellLink,
    setType,
    close,
    type
  }
}
