import { Link } from 'presentation/components'
import styled from 'styled-components'

export const Box = styled(Link)`
  display: block;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  text-align: center;
  padding: 8px 16px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 15px 0;
  }

  & strong {
    text-transform: uppercase;
    margin-left: 5px;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
`
