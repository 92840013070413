import * as S from './Switches.styles'

import { CookieConfig } from 'domain/Cookie/Models/CookieConfig'
import { Icon } from 'presentation/components'
import { Text } from '../CookieControlModal.styles'
import switchesData from './Switches.data'

export function Switches({ cookieConfig, setCookieConfig }) {
  function handleChange(name) {
    return function (e) {
      const updatedConsent = new CookieConfig({
        ...cookieConfig,
        cookies: {
          ...cookieConfig.cookies,
          [name]: e.target.checked
        }
      })
      setCookieConfig(updatedConsent)
    }
  }

  return (
    <S.Switches>
      <S.SwitchBox>
        <Icon icon="CHECK" width={40} height={40} />
        <S.Info>
          <S.Title>Essenciais</S.Title>
          <Text>
            São cookies indispensáveis pra você acelerar por aqui e manter suas
            preferências de navegação. Sem eles, nada de Webmotors. Triste, né?
          </Text>
        </S.Info>
      </S.SwitchBox>
      {switchesData.map(({ title, text, name }) => (
        <S.SwitchBox key={name}>
          <S.Switcher
            size="medium"
            checked={cookieConfig.cookies?.[name]}
            onChange={handleChange(name)}
            data-qa={`switch_cookieControlModa_${title}`}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <S.Info>
            <S.Title>{title}</S.Title>
            <Text>{text}</Text>
          </S.Info>
        </S.SwitchBox>
      ))}
    </S.Switches>
  )
}
