import Options from './Contracts/IOptions'
import { applySlugify } from './applySlugify'
import { removeAccents } from './removeAccents'
import { removeSpecialChars } from './removeSpecialChars'
import { transformToTitleCase } from './transformToTitleCase'

export function cleanString(input: string, option: Options): string {
  input = input.toString().trim()

  option.slugify && (input = applySlugify(input))
  option.toLowerCase && (input = input.toLowerCase())
  option.toUpperCase && (input = input.toUpperCase())
  option.removeAccents && (input = removeAccents(input))
  option.removeSpecialChars && (input = removeSpecialChars(input))
  option.replaceSpace && (input = input.replace(' ', option.replaceSpace))
  option.transformToTitleCase && (input = transformToTitleCase(input))
  option.transformToTitleCase && (input = transformToTitleCase(input))
  return input
}
