import { Config } from 'application'
import { loggerClient } from 'application/Services'
import { QueryParams } from 'domain/BaseRepository'
import { DataParser } from 'domain/DataParser'
import { HttpGetClient } from 'domain/HttpClient'
import { LocalGetItem } from 'domain/LocalClient'
import flattenDeep from 'lodash/flattenDeep'
import { ISearchItemRepository } from '../Contracts/ISearchItemRepository'
import { SearchItem, SearchItemSegment } from '../Models/SearchItem'
import { CookieSearchDto } from './CookieSearchParser'

const logger = loggerClient.create('SearchItemRepository')

export type AutoCompleteDto = {
  name: string
  items: AutoCompleteDto[]
}

export type SearchCountDto = {
  Count: number
}

export type MakeDto = {
  Name: string
  Count: number
}

export class SearchItemRepository implements ISearchItemRepository {
  /**
   * ItemRepository constructor
   *
   */
  constructor(
    private httpGetClient: HttpGetClient,
    private localClient: LocalGetItem,
    private searchParser: DataParser<CookieSearchDto, SearchItem>
  ) {}

  /**
   * Get all items with count
   *
   * @param queryParams
   * @returns
   */
  async getAllWithCount(queryParams: QueryParams): Promise<number> {
    const result = await this.httpGetClient.get<SearchCountDto>(
      `/search/${queryParams.path}`,
      {
        queryParams: {
          url: `${Config.baseUrl}/${queryParams.vehicleCategory}/estoque`,
          showCount: true,
          showResult: false,
          showRegionalized: true,
          tipoveiculo: queryParams.vehicleCategory
        }
      }
    )

    return result.Count
  }

  /**
   * Get all items with count in a city
   *
   * @param queryParams
   * @returns
   */
  async getAllWithCityCount(
    queryParams?: QueryParams,
    customParam?: string
  ): Promise<SearchItem[]> {
    const result = await this.httpGetClient.get<MakeDto[]>(
      `/filter/${queryParams?.path}/Make`,
      {
        queryParams: {
          url: customParam ? `${Config.baseUrl}${customParam}` : Config.baseUrl,
          parentFilter: 'None',
          parentValue: 'None'
        }
      }
    )

    return result.map(
      (item) =>
        new SearchItem({
          brand: item.Name,
          count: item.Count
        })
    )
  }

  /**
   * Get all items from autocomplete api
   *
   * @param path
   * @returns
   */
  async getAllFromAutoComplete(path: string): Promise<SearchItem[]> {
    const result = await this.httpGetClient.get<AutoCompleteDto[]>(
      `/filter/${path}/autocomplete`
    )

    // Create new Item instances
    const items = result.map((item) => {
      const brand = new SearchItem({
        brand: item.name,
        segment: path as SearchItemSegment
      })

      // Create model instances
      const models = item.items.map(
        (model) =>
          new SearchItem({
            brand: brand.name,
            model: model.name,
            segment: path as SearchItemSegment
          })
      )

      return [brand, ...models]
    })

    return flattenDeep(items)
  }

  /**
   * Get all search items from last search cookie
   *
   * @returns
   */
  async getAllFromLastSearch(): Promise<SearchItem[]> {
    const searches =
      this.localClient.getItem<CookieSearchDto[]>('WebMotorsLastSearches') || []

    const mappedResult = searches.map((search) =>
      this.searchParser.parse(search)
    )

    logger.info('[getAllFromLastSearch] mappedResult: ', mappedResult)

    return mappedResult
  }

  /**
   * Get all autocomplete items
   *
   * @returns
   */
  async getAll(queryParams: QueryParams): Promise<SearchItem[]> {
    const { path = '' } = queryParams
    return queryParams.path === 'LAST_SEARCH'
      ? this.getAllFromLastSearch()
      : this.getAllFromAutoComplete(path)
  }
}
